import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useGetRestaurantsByLocation, useGetUserRewardsByRestaurant } from 'graphql/hooks';
import { Box, Grid, Typography, TextField } from '@material-ui/core';
import { map } from 'lodash';
import Card from 'components/Card';
import { useStore } from 'store';
import { grey } from '@material-ui/core/colors';
import { FirebaseContext } from 'fbase';
import moment from 'moment-timezone';
import { IPauseDetails } from 'generated/custom';
import { useObject } from 'react-firebase-hooks/database';
import RestaurantModel from 'models/Restaurant';
import Fuse from 'fuse.js';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from '../style';

interface IPauseData {
  [id: string]: IPauseDetails;
}

const RestaurantHomePage = () => {
  const classes = useStyles();

  const {
    state: { user },
    dispatch
  } = useStore();

  const {
    coordinate: { latitude, longitude }
  } = user;

  /*
   * Get restaurant api.
   */
  const { restaurantList, loading } = useGetRestaurantsByLocation({
    lat: latitude,
    lng: longitude,
    radiusInMeter: 500000
  });

  const { rewardPointsData } = useGetUserRewardsByRestaurant();

  useEffect(() => {
    dispatch({
      type: 'RESET_LASTACTIVERESTAURANT'
    });
    dispatch({
      type: 'CART_ACTION_RESET_ORDER_TYPE_INFO'
    });
    dispatch({
      type: 'GROCERY_CART_ACTION_RESET_ORDER_TYPE_INFO'
    });
  }, [dispatch]);

  const { firebase } = useContext(FirebaseContext);

  const [snapshot] = useObject(firebase?.getRealtimeDB().ref(`/orderPause/`));

  const pauseData: IPauseData | null | undefined = snapshot?.val();

  const [filteredRestaurants, setFilteredRestaurants] = useState<RestaurantModel[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const setDefaultRestaurantList = useCallback((restaurantListItems: RestaurantModel[]) => {
    // Place crypto promotion enabled restaurants on the top of the list
    const cryptoEnabledRestaurants = restaurantListItems.filter((restaurantItem: RestaurantModel) => restaurantItem.cryptoCouponEnabled && restaurantItem.cryptoCouponExists);
    const cryptoNotEnabledRestaurants = restaurantListItems.filter((restaurantItem: RestaurantModel) => !restaurantItem.cryptoCouponEnabled || !restaurantItem.cryptoCouponExists);
    setFilteredRestaurants([...cryptoEnabledRestaurants, ...cryptoNotEnabledRestaurants]);
  }, []);
  useEffect(() => {
    setSearchQuery('');
    if (restaurantList) {
      setDefaultRestaurantList(restaurantList);
    }
  }, [restaurantList, setDefaultRestaurantList]);

  const onSearchChange = (q: string) => {
    if (q.trim() === '') {
      setDefaultRestaurantList(restaurantList);
    } else {
      const fuse = new Fuse(restaurantList, {
        keys: ['getName', 'getSubName'],
        isCaseSensitive: false,
        shouldSort: true,
        minMatchCharLength: 3
      });
      setDefaultRestaurantList(fuse.search(q).map((res) => res.item));
      // setFilteredRestaurants(fuse.search(q).map((res) => res.item));
    }

    setSearchQuery(q);
  };

  const renderRestaurantList = () => {
    if (loading) {
      return null;
    }

    if (!loading && filteredRestaurants && filteredRestaurants.length) {
      return (
        <Grid className={classes.scrollingComponent} container={true} spacing={1}>
          {map(filteredRestaurants, (restaurant) => {
            const id = restaurant.getId;

            const name = restaurant.getName;

            const logo = restaurant.getLogo;

            const restaurantSubName = restaurant.getSubName;

            const image = restaurant.getDisplayImage;

            const takeOutOrder = restaurant.enableTakeoutOrder;

            const deliveryOrder = restaurant.enableDeliveryOrder;

            const dineInOrder = !!restaurant.enableDineInOrder || !!restaurant.enableDineOutOrder;

            const lat = restaurant.getLatLng?.lat;

            const lng = restaurant.getLatLng?.lng;

            const isCouponAvailable = !!restaurant?.coupons && !!restaurant?.coupons.length;

            const isRewardAvailable = restaurant?.issueRewardPoint;

            const rewardPoints = rewardPointsData && rewardPointsData[id] ? rewardPointsData[id].activePoints || 0 : 0;

            const timezone = restaurant.getTimezone;
            const cryptoCouponEnabled = restaurant.cryptoCouponEnabled;
            const cryptoCouponExists = restaurant.cryptoCouponExists;
            const now = moment.tz(timezone).format('YYYY-MM-DD');

            const diningPause = pauseData && pauseData[id] && pauseData[id].DINING ? pauseData[id].DINING?.status === 'pause' && now === pauseData[id].DINING?.pauseDate : false;

            const takeOutPause = pauseData && pauseData[id] && pauseData[id].TAKEOUT ? pauseData[id].TAKEOUT?.status === 'pause' && now === pauseData[id].TAKEOUT?.pauseDate : false;

            const deliveryPause = pauseData && pauseData[id] && pauseData[id].DELIVERY ? pauseData[id].DELIVERY?.status === 'pause' && now === pauseData[id].DELIVERY?.pauseDate : false;

            return (
              <Grid item={true} key={id} xs={12} sm={6} md={4}>
                <Card
                  title={name}
                  subName={restaurantSubName}
                  logo={logo}
                  imgSrc={image}
                  lat={lat}
                  lng={lng}
                  link={`restaurants/${id}`}
                  isCouponAvailable={isCouponAvailable}
                  isRewardAvailable={!!isRewardAvailable}
                  rewardPoints={rewardPoints}
                  takeOut={takeOutOrder}
                  takeOutPause={takeOutPause}
                  dining={dineInOrder}
                  diningPause={diningPause}
                  delivery={deliveryOrder}
                  deliveryPause={deliveryPause}
                  cryptoCouponEnabled={cryptoCouponEnabled}
                  cryptoCouponExists={cryptoCouponExists}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    }

    if (!loading && filteredRestaurants.length === 0 && restaurantList.length !== 0) {
      return (
        <Box paddingY={1} textAlign="center">
          <Typography>No matches for your search query.</Typography>
        </Box>
      );
    }

    if (!loading && restaurantList.length === 0) {
      return (
        <Box paddingY={1} textAlign="center">
          <Typography>No restaurant found in this region</Typography>
        </Box>
      );
    }
  };

  if (!loading) {
    return (
      <Box maxWidth={1200} margin="auto" bgcolor={grey[50]} paddingX={1.5} height="100%" display="flex" flexDirection="column" overflow="hidden">
        {restaurantList && restaurantList.length !== 0 && (
          <Box paddingY={0.5} paddingTop={1.5}>
            <TextField
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment:
                  searchQuery !== '' ? (
                    <ClearIcon
                      onClick={() => {
                        onSearchChange('');
                      }}
                    />
                  ) : null
              }}
              placeholder="Search restaurants..."
              fullWidth={true}
              size="small"
              variant="outlined"
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value)}
            />
          </Box>
        )}

        {renderRestaurantList()}
      </Box>
    );
  }
  return null;
};

export default RestaurantHomePage;
