import { useStore } from 'store';
import { CartItem, Restaurant } from 'models';
import { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { map } from 'lodash';
import { plainToClass } from 'class-transformer';
import { IDish } from 'pages/MyCart/CheckoutSummary/types';
import Big from 'big.js';
import { getRestaurant as getRestaurantQuery } from 'graphql/query';
import { useLazyQuery } from '@apollo/react-hooks';
import { GetRestaurantIdQuery, DishType, CryptoCoupon } from 'generated/types';
import { IAddress } from 'components/AddressModal/types';
import { DeliveryMode, DeliveryProvider, DropOffType, IDeliveryEstimate, IDoordashDeliveryEstimate } from 'generated/custom';

export interface IReturnObject {
  cartItems: IDish[];
  cartRewardItems: IDish[];
  isCartEmpty: boolean;
  totalItemsInCart: number;
  subtotal: string;
  subtotalWithoutPrediscount: string;
  subtotalToDiscount: string;
  subtotalAfterDiscount: string;
  restaurant: Restaurant | null;
  totalPriceAfterTax: string | undefined;
  tax: string | undefined;
  serviceFee: Big | null;
  setTipAmount: (payload: Big) => void;
  setServiceFee: (payload: Big) => void;
  handleClose: () => void;
  setDeliveryTime: (payload: number) => void;
  setOrderNote: (payload: string) => void;
  orderNote: string;
  orderType: 'TAKE_OUT' | 'DINE_OUT' | 'DELIVERY' | 'DINING' | '';
  deliveryProvider: DeliveryProvider;
  deliveryMode: DeliveryMode;
  setDeliveryCharge: (payload: Big) => void;
  setDeliveryMiles: (payload: Big) => void;
  setDeliveryAddress: (payload: IAddress | null) => void;
  setDeliveryEstimate: (payload: IDeliveryEstimate) => void;
  setDoordashDeliveryEstimate: (payload: IDoordashDeliveryEstimate) => void;
  setDeliveryNote: (payload: string) => void;
  setCouponData: (payload: { coupon: Partial<CryptoCoupon>; token: string } | null) => void;
  setOrderType: (payload: 'TAKE_OUT' | 'DINE_OUT' | 'DELIVERY') => void;
  setDropOffType: (payload: DropOffType) => void;
  togglePrediscount: (payload: boolean) => void;
  deliveryAddress: IAddress | null;
  deliveryMiles: Big;
  deliveryCharge: Big;
  doordashCharge: Big;
  isDiscountApplied: boolean;
  couponData: Partial<CryptoCoupon> | null;
  discount: string | null;
  numberOfPeople: number;
  tableNumber: string;
  deliveryNote: string;
  deliveryTime: number;
  isCartHavingPrediscountedItem: boolean;
  isPrediscountEnabled: boolean;
  dropOffType: DropOffType;
  apartmentNumber: string;
  setApartmentNumber: (payload: string) => void;
  boonsQuoteId?: string;
  doordashQuoteId: string | null;
}

/**
 * This hook is for Screens after Cart page.
 * It will redirect to homepage if cart is empty
 */
const useCart = (): IReturnObject => {
  const history = useHistory();

  const { state } = useLocation<{ from: string | undefined }>();

  const {
    state: {
      cartItems: storeCartItems,
      cartRewardItems: storeRewardCartItems,
      subtotal,
      subtotalWithoutPrediscount,
      subtotalToDiscount,
      totalPriceAfterTax,
      tip,
      tax,
      orderNote,
      orderType,
      cartRestaurant,
      deliveryProvider,
      deliveryMode,
      deliveryAddress,
      deliveryMiles,
      isDiscountApplied,
      couponData,
      discount,
      deliveryCharge,
      serviceFee,
      subtotalAfterDiscount,
      numberOfPeople,
      tableNumber,
      deliveryNote,
      deliveryTime,
      isPrediscountEnabled,
      dropOffType,
      apartmentNumber,
      boonsQuoteId,
      doordashQuoteId,
      bizPlan,
      doordashCharge
    },
    dispatch
  } = useStore();

  const [cartItems, setCartItems] = useState<any>([]);

  const [cartRewardItems, setCartRewardItems] = useState<any>([]);

  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);

  const [cartSubtotal, setCartSubtotal] = useState<Big | null>(null);

  const [cartSubtotalToDiscount, setCartSubtotalToDiscount] = useState<Big | null>(null);

  const [cartSubtotalWithoutPrediscount, setCartSubtotalWithoutPrediscount] = useState<Big | null>(null);

  const [getRestaurant, { loading, data: restaurantData }] = useLazyQuery<GetRestaurantIdQuery>(getRestaurantQuery);

  const [isCartHavingPrediscountedItem, setIsCartHavingPrediscountedItem] = useState(false);

  /**
   * Go back from cart screen if location is present in history stack go back else fallback to home page
   */
  const handleClose = useCallback(() => {
    if (history.length > 1) {
      if (state && state.from) {
        history.replace(state.from);
      } else {
        history.replace('/');
      }
    } else {
      history.push('/');
    }
  }, [history, state]);

  /**
   * Updating cart subtotal
   */
  useEffect(() => {
    /**
     * Fetch restaurant data.
     */
    if (!loading && restaurantData) {
      let total = Big(0);

      let totalToDiscount = Big(0);

      let totalWithoutPrediscount = Big(0);

      // Convert restaurant JSON into restaurant model object.
      const restaurantObj = plainToClass(Restaurant, restaurantData.getRestaurant);

      let isAnyItemPrediscounted = false;

      map(storeCartItems, (item) => {
        const cartItem = plainToClass(CartItem, item);

        if (cartItem.isPreDiscounted) {
          isAnyItemPrediscounted = true;
        }

        if (cartItem.type === DishType.Pizza && cartItem.dishTypePizzaTotalPrice) {
          total = total.add(Big(cartItem.type === DishType.Pizza ? cartItem.getDishTypePizzaTotalPrice : cartItem.subtotal));

          totalToDiscount = totalToDiscount.add(cartItem.getDishTypePizzaTotalPriceToDiscount);

          if (!cartItem.isPreDiscounted) {
            totalWithoutPrediscount = totalWithoutPrediscount.add(cartItem.getDishTypePizzaTotalPrice);
          } else {
            totalWithoutPrediscount = totalWithoutPrediscount.add(cartItem.getDishTypePizzaTotalPriceStriked);
          }
        } else {
          total = total.add(cartItem.subtotal);

          totalToDiscount = totalToDiscount.add(cartItem.subtotalToDiscount);

          if (!cartItem.isPreDiscounted) {
            totalWithoutPrediscount = totalWithoutPrediscount.add(cartItem.subtotal);
          } else {
            totalWithoutPrediscount = totalWithoutPrediscount.add(cartItem.subtotalStriked);
          }
        }
      });

      dispatch({
        type: 'CART_ACTION_EVALUATE_CHARGES',
        payload: {
          restaurant: restaurantObj,
          subtotal: total,
          subtotalToDiscount: totalToDiscount,
          subtotalWithoutPrediscount: totalWithoutPrediscount,
          bizPlan,
          calledBy: 'Updating cart subtotal useeffect'
        }
      });

      setRestaurant(restaurantObj);

      setCartSubtotal(total);

      setCartSubtotalToDiscount(totalToDiscount);

      setCartSubtotalWithoutPrediscount(totalWithoutPrediscount);

      setIsCartHavingPrediscountedItem(isAnyItemPrediscounted);
    }
  }, [dispatch, loading, restaurantData, storeCartItems, tip, bizPlan]);

  useEffect(() => {
    /**
     * Fetch restaurant data.
     */
    if (!loading && restaurantData && storeRewardCartItems && storeRewardCartItems.length) {
      // Convert restaurant JSON into restaurant model object.
      const restaurantObj = plainToClass(Restaurant, restaurantData.getRestaurant);

      dispatch({
        type: 'CART_ACTION_SET_RESTAURANT',
        payload: restaurantObj
      });

      setRestaurant(restaurantObj);
    }
  }, [dispatch, loading, restaurantData, storeRewardCartItems]);

  /**
   * Updating cartItems
   */
  useEffect(() => {
    if (storeCartItems.length === 0 && storeRewardCartItems.length === 0) {
      handleClose();
    } else {
      /**
       * Identify restaurant id from cart items.
       */
      const firstElement = storeCartItems[0] || storeRewardCartItems[0];

      const restaurantId = firstElement.restaurant?.id;

      getRestaurant({
        variables: {
          input: {
            id: restaurantId
          }
        }
      });

      const data = map(storeCartItems, (item) => {
        const cartItem = plainToClass(CartItem, item);

        const dishTitle = cartItem.getTitle;

        const extras = cartItem.getExtras;

        const dishTotalPrice = cartItem.type === DishType.Pizza ? cartItem.getDishTypePizzaTotalPrice : cartItem.subtotal;

        const priceToDiscount = cartItem.type === DishType.Pizza ? cartItem.getDishTypePizzaTotalPriceToDiscount : cartItem.subtotalToDiscount;

        const uuid = cartItem.uuid;

        const isPreDiscounted = cartItem.isPreDiscounted;

        const priceStriked = cartItem.type === DishType.Pizza ? cartItem.getDishTypePizzaTotalPriceStriked : cartItem.subtotalStriked;

        return {
          note: cartItem.note,
          uuid,
          extras,
          isPreDiscounted,
          size: cartItem.selectedSizeTitle,
          priceStriked,
          discountPercent: cartItem.discountPercent,
          price: dishTotalPrice,
          priceToDiscount,
          quantity: cartItem.quantity,
          title: dishTitle,
          type: cartItem.type,
          isAlcohol: cartItem.isAlcohol,
          pizzaBasePrice: cartItem.pizzaBasePrice,
          pizzaActiveSizeId: cartItem.pizzaActiveSizeId,
          pizzaActiveCrustId: cartItem.pizzaActiveCrustId,
          pizzaCheese: cartItem.pizzaCheese,
          pizzaSauce: cartItem.pizzaSauce,
          pizzaCrust: cartItem.pizzaCrust,
          pizzaToppingGroups: cartItem.pizzaToppingGroups,
          pizzaToppingGroupsOrderLineItems: cartItem.pizzaToppingGroupsOrderLineItems,
          pizzaSaucesOrderLineItems: cartItem.pizzaSaucesOrderLineItems,
          pizzaCheeseOrderLineItems: cartItem.pizzaCheeseOrderLineItems
        };
      });

      setCartItems(data);
    }
  }, [getRestaurant, handleClose, storeCartItems, storeRewardCartItems]);

  useEffect(() => {
    if (storeCartItems.length === 0 && storeRewardCartItems.length === 0) {
      handleClose();
    } else {
      /**
       * Identify restaurant id from cart items.
       */
      const firstElement = storeCartItems[0] || storeRewardCartItems[0];

      const restaurantId = firstElement.restaurant?.id;

      getRestaurant({
        variables: {
          input: {
            id: restaurantId
          }
        }
      });

      /**
       * Setting reward cart item
       */
      const rewardData = map(storeRewardCartItems, (item) => {
        const cartItem = plainToClass(CartItem, item);

        const dishTitle = cartItem.getTitle;

        const extras = cartItem.getExtras;

        const dishTotalRewards = cartItem.type === DishType.Pizza ? cartItem.getDishTypePizzaTotalPrice : cartItem.getPoints;

        const uuid = cartItem.uuid;

        return {
          note: cartItem.note,
          uuid,
          extras,
          size: cartItem.selectedSizeTitle,
          isAlcohol: cartItem.isAlcohol,
          rewardPoints: dishTotalRewards,
          quantity: cartItem.quantity,
          title: dishTitle,
          type: cartItem.type,
          pizzaBasePrice: cartItem.pizzaBasePrice,
          pizzaActiveSizeId: cartItem.pizzaActiveSizeId,
          pizzaActiveCrustId: cartItem.pizzaActiveCrustId,
          pizzaCheese: cartItem.pizzaCheese,
          pizzaSauce: cartItem.pizzaSauce,
          pizzaCrust: cartItem.pizzaCrust,
          pizzaToppingGroups: cartItem.pizzaToppingGroups,
          pizzaToppingGroupsOrderLineItems: cartItem.pizzaToppingGroupsOrderLineItems,
          pizzaSaucesOrderLineItems: cartItem.pizzaSaucesOrderLineItems,
          pizzaCheeseOrderLineItems: cartItem.pizzaCheeseOrderLineItems
        };
      });

      setCartRewardItems(rewardData);
    }
  }, [getRestaurant, handleClose, storeCartItems, storeRewardCartItems]);

  useEffect(() => {
    dispatch({
      type: 'CART_ACTION_TIP_AMOUNT',
      payload: Big(0)
    });

    dispatch({
      type: 'CART_ACTION_SET_SERVICE_FEE',
      payload: null
    });
    dispatch({
      type: 'CART_ACTION_SET_CART_SESSION_ID'
    });
  }, [dispatch]);

  const setTipAmount = useCallback(
    (payload: Big) => {
      dispatch({
        type: 'CART_ACTION_TIP_AMOUNT',
        payload
      });
    },
    [dispatch]
  );

  const setDeliveryTime = useCallback(
    (payload: number) => {
      dispatch({
        type: 'CART_ACTION_SET_DELIVERY_TIME',
        payload: payload
      });
    },
    [dispatch]
  );

  const setOrderNote = (payload: string) => {
    dispatch({
      type: 'CART_ACTION_SET_ORDER_NOTE',
      payload
    });
  };

  const setOrderType = useCallback(
    (payload: 'TAKE_OUT' | 'DINE_OUT' | 'DELIVERY') => {
      dispatch({
        type: 'CART_ACTION_SET_ORDER_TYPE',
        payload
      });
    },
    [dispatch]
  );

  const setDeliveryCharge = (payload: Big) => {
    dispatch({
      type: 'CART_ACTION_SET_DELIVERY_CHARGE',
      payload
    });
  };

  const setDeliveryMiles = (payload: Big) => {
    dispatch({
      type: 'CART_ACTION_SET_DELIVERY_MILES',
      payload
    });

    dispatch({
      type: 'CART_ACTION_EVALUATE_CHARGES',
      payload: {
        restaurant,
        subtotal: cartSubtotal,
        subtotalToDiscount: cartSubtotalToDiscount,
        subtotalWithoutPrediscount: cartSubtotalWithoutPrediscount,
        bizPlan,
        calledBy: 'setDeliveryMiles'
      }
    });
  };

  const setDropOffType = (payload: DropOffType) => {
    dispatch({
      type: 'CART_ACTION_SET_DROP_OFF_INSTRUCTION',
      payload
    });
  };

  const setApartmentNumber = (payload: string) => {
    dispatch({
      type: 'CART_ACTION_SET_APARTMENT_NUMBER',
      payload
    });
  };

  const setDeliveryAddress = (payload: IAddress | null) => {
    dispatch({
      type: 'CART_ACTION_SET_DELIVERY_ADDRESS',
      payload
    });
  };

  const setDeliveryEstimate = (payload: IDeliveryEstimate) => {
    dispatch({
      type: 'CART_ACTION_SET_BOONS_DELIVERY_ESTIMATE',
      payload
    });

    dispatch({
      type: 'CART_ACTION_EVALUATE_CHARGES',
      payload: {
        restaurant,
        subtotal: cartSubtotal,
        subtotalToDiscount: cartSubtotalToDiscount,
        subtotalWithoutPrediscount: cartSubtotalWithoutPrediscount,
        bizPlan
      }
    });
  };

  const setDoordashDeliveryEstimate = (payload: IDoordashDeliveryEstimate) => {
    dispatch({
      type: 'CART_ACTION_SET_DOORDASH_DELIVERY_ESTIMATE',
      payload
    });

    dispatch({
      type: 'CART_ACTION_EVALUATE_CHARGES',
      payload: {
        restaurant,
        subtotal: cartSubtotal,
        subtotalToDiscount: cartSubtotalToDiscount,
        subtotalWithoutPrediscount: cartSubtotalWithoutPrediscount,
        bizPlan
      }
    });
  };

  const setDeliveryNote = (payload: string) => {
    dispatch({
      type: 'CART_ACTION_SET_DELIVERY_NOTE',
      payload
    });
  };

  const setServiceFee = (payload: Big) => {
    dispatch({
      type: 'CART_ACTION_SET_SERVICE_FEE',
      payload
    });

    dispatch({
      type: 'CART_ACTION_EVALUATE_CHARGES',
      payload: {
        restaurant,
        subtotal: cartSubtotal,
        subtotalToDiscount: cartSubtotalToDiscount,
        subtotalWithoutPrediscount: cartSubtotalWithoutPrediscount,
        serviceFeePayload: payload,
        bizPlan,
        calledBy: 'setServiceFee'
      }
    });
  };

  const setCouponData = useCallback(
    (payload: { coupon: Partial<CryptoCoupon>; token: string } | null) => {
      if (payload) {
        const { coupon, token } = payload;

        // Set coupon data in store.
        dispatch({
          type: 'CART_ACTION_SET_COUPON_DATA',
          payload: coupon
        });

        // Set coupon token in store.
        dispatch({
          type: 'CART_ACTION_SET_COUPON_TOKEN',
          payload: token
        });
      } else {
        dispatch({
          type: 'CART_ACTION_REMOVE_DISCOUNT'
        });
      }

      if (restaurant) {
        dispatch({
          type: 'CART_ACTION_EVALUATE_CHARGES',
          payload: {
            restaurant,
            subtotal: cartSubtotal,
            subtotalToDiscount: cartSubtotalToDiscount,
            subtotalWithoutPrediscount: cartSubtotalWithoutPrediscount,
            bizPlan,
            calledBy: 'setCouponData'
          }
        });
      }
    },
    [cartSubtotal, cartSubtotalToDiscount, cartSubtotalWithoutPrediscount, dispatch, restaurant, bizPlan]
  );

  const togglePrediscount = useCallback(
    (payload: boolean) => {
      dispatch({
        type: 'CART_ACTION_DISABLE_PREDISCOUNT',
        payload
      });

      if (restaurant) {
        dispatch({
          type: 'CART_ACTION_EVALUATE_CHARGES',
          payload: {
            restaurant,
            subtotal: cartSubtotal,
            subtotalToDiscount: cartSubtotalToDiscount,
            subtotalWithoutPrediscount: cartSubtotalWithoutPrediscount,
            bizPlan,
            calledBy: 'togglePrediscount'
          }
        });
      }
    },
    [cartSubtotal, cartSubtotalToDiscount, cartSubtotalWithoutPrediscount, dispatch, restaurant, bizPlan]
  );

  return {
    cartItems,
    cartRewardItems,
    serviceFee,
    isCartEmpty: false,
    totalItemsInCart: cartItems.length,
    restaurant: cartRestaurant,
    subtotal: subtotal.toFixed(2),
    subtotalWithoutPrediscount: subtotalWithoutPrediscount.toFixed(2),
    subtotalToDiscount: subtotalToDiscount.toFixed(2),
    totalPriceAfterTax: totalPriceAfterTax?.toFixed(2),
    setTipAmount,
    tax: tax?.toFixed(2),
    handleClose,
    setDeliveryTime,
    orderNote,
    setOrderNote,
    orderType,
    deliveryProvider,
    deliveryMode,
    setDeliveryCharge,
    setDeliveryMiles,
    setDeliveryAddress,
    setDeliveryEstimate,
    setDeliveryNote,
    setServiceFee,
    deliveryAddress,
    deliveryMiles,
    deliveryCharge,
    isDiscountApplied,
    couponData,
    discount: discount ? discount.toFixed(2) : null,
    setCouponData,
    setOrderType,
    setDropOffType,
    togglePrediscount,
    subtotalAfterDiscount: subtotalAfterDiscount.toFixed(2),
    numberOfPeople,
    tableNumber,
    deliveryNote,
    deliveryTime,
    isCartHavingPrediscountedItem,
    isPrediscountEnabled,
    dropOffType,
    setApartmentNumber,
    apartmentNumber,
    boonsQuoteId,
    setDoordashDeliveryEstimate,
    doordashQuoteId,
    doordashCharge
  };
};

export default useCart;
