import React, { useState } from 'react';
import MainWalletPage from '../MainWalletPage';
import SellCoinPage from '../SellCoinPage';

const WalletPages = () => {
  const [step, setStep] = useState<number>(0);
  const togglePage = () => {
    setStep(step === 1 ? 0 : 1);
  };
  return (
    <>
      {step === 1 ? <SellCoinPage togglePage={togglePage} /> : <MainWalletPage />}

      {/* <Box position={'fixed'} bottom={0} zIndex={1} bgcolor="white" paddingY={3} maxWidth={500} width="100%" boxShadow={8} display="flex" justifyContent={'center'} alignItems="center">
        <Button variant="contained" color="primary" onClick={togglePage} size="medium">
          {step === 1 ? "I'll Keep Holding" : 'How do I Sell my ContributionCoin?'}
        </Button>
      </Box> */}
    </>
  );
};

export default WalletPages;
