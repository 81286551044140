import { Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../style';
import { FormikProps, useFormik } from 'formik';
import { Auth } from 'aws-amplify/';
// import securePassword from 'secure-random-password';
import { validationSchema } from './validation';
import { AuthContext } from 'fbase/authContext';
import { useSnackbar } from 'notistack';
import { useStore } from 'store';
import { adChainCognitoConfig } from 'util/configureAmplifyCognito';
import CloseIcon from '@material-ui/icons/Close';
import maxCashbackLogo from 'assets/img/wallet/maxCashback_ai_logo@3x.png';
import WalletGift from 'assets/img/wallet/gift_cc.svg';
import WalletLocked from 'assets/img/wallet/locked_wallet.svg';
import WalletPassword from 'assets/img/wallet/lock_password.svg';

interface IProps {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}
interface LoginForm {
  email: string;
  otp: string | null;
}

// Amplify.configure({
//   aws_project_region: process.env.REACT_APP_COGNITO_REGION,
//   aws_cognito_region: process.env.REACT_APP_COGNITO_REGION!,
//   aws_user_pools_id: process.env.REACT_APP_ADCHAIN_COGNITO_USER_POOL_ID!,
//   aws_user_pools_web_client_id: process.env.REACT_APP_ADCHAIN_CONSUMER_CLIENT_ID!
// });

const WalletLogin: React.FC<IProps> = ({ setIsLoggedIn }) => {
  const classes = useStyles();
  const history = useHistory();
  const snackbar = useSnackbar();
  const {
    dispatch,
    state: {
      ui: { isLokobeeLoginFromWallet }
    }
  } = useStore();
  const { currentUser } = useContext(AuthContext);
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>();

  const [initialValues, setInitialValues] = useState<LoginForm>({
    email: '',
    otp: ''
  });
  useEffect(() => {
    // pre fill user email if lokobee session is availble
    if (currentUser && currentUser.email) {
      setInitialValues({ email: currentUser.email, otp: '' });
    }
    if (currentUser && currentUser.isAnonymous) {
      setInitialValues({ email: '', otp: '' });
    }
  }, [currentUser]);
  const formik: FormikProps<LoginForm> = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (formValues) => handleFormSubmit(formValues)
  });
  const { values, handleChange, handleSubmit, handleBlur, setFieldError, touched, errors } = formik;

  const signIn = useCallback(
    async (emailId: string) => {
      setLoading(true);

      adChainCognitoConfig();

      Auth.configure({
        authenticationFlowType: 'CUSTOM_AUTH'
      });

      try {
        const email = emailId;
        let user = await Auth.signIn(email);
        user = await Auth.sendCustomChallengeAnswer(user, 'maxcashback.ai', {
          medium: 'email'
        });
        snackbar.enqueueSnackbar(`A Verification Code has been sent to ${email}`, {
          variant: 'success'
        });
        dispatch({
          type: 'UI_ACTION_LOKOBEE_WALLET_LOGIN',
          payload: false
        });
        setUserData(user);
        setIsOtpSent(true);
        setLoading(false);
      } catch (err) {
        if (err && (err as any).code === 'UserLambdaValidationException' && (err as any).message === 'CreateAuthChallenge failed with error UserNotFound.') {
          setFieldError(
            'email',
            `No Wallet found associated with this email address, either the email is incorrect or wallet isn’t created. Wallet is only created when you have used the CashBack Promo at least once.`
          );
        } else {
          setFieldError('email', 'Something went wrong');
          snackbar.enqueueSnackbar(`Something went wrong`, {
            variant: 'error'
          });
        }
        setLoading(false);
      }
    },
    [dispatch, setFieldError, snackbar]
  );

  const submitOTP = async () => {
    try {
      setLoading(true);
      const otpUse = values.otp?.toString();

      adChainCognitoConfig();

      let user = await Auth.sendCustomChallengeAnswer(userData, otpUse || '');
      if (user.challengeName) {
        setFieldError('otp', 'Invalid Verification Code, try again');
        setLoading(false);
      } else {
        const currentSession = await Auth.currentSession();
        if (currentSession && currentSession.getIdToken().getJwtToken()) {
          setUserData(user);
          setLoading(false);
          setIsLoggedIn(true);
        } else {
          setFieldError('otp', 'Something went wrong');
          setLoading(false);
        }
      }
    } catch (err) {
      setFieldError('otp', 'Something went wrong');
      setLoading(false);
    }
  };

  const handleFormSubmit = (formValues: LoginForm) => {
    if (currentUser) {
      if (isOtpSent) {
        submitOTP();
      } else {
        signIn(values.email);
      }
    } else {
      dispatch({
        type: 'UI_ACTION_LOGIN_POPUP',
        payload: true
      });
    }
  };

  useEffect(() => {
    if (isLokobeeLoginFromWallet && currentUser && values.email) {
      signIn(values.email);
    }
  }, [currentUser, isLokobeeLoginFromWallet, signIn, values.email]);
  return (
    <form onSubmit={handleSubmit} style={{ height: 'inherit', display: 'grid', gridTemplateRows: '25% 40% 35%' }}>
      <Box className={classes.headerContainer}>
        <Box className={classes.container}>
          <Typography className={classes.title} variant="h6">
            Wallet
          </Typography>
          <CloseIcon className={classes.button} onClick={() => history.goBack()} color="inherit" />
        </Box>
        <Box
          style={{
            position: 'absolute',
            bottom: 125,
            right: 0,
            zIndex: 10,
            color: 'white'
          }}>
          <Box style={{ alignItems: 'center' }} display="flex" flexDirection={'column'} padding={2}>
            <Box className={classes.walletLoginHeadCustom}>
              <img alt="maxCashback logo" src={maxCashbackLogo} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box paddingTop={2} style={{ overflow: 'scroll' }}>
        <Box display="flex" flexDirection={'column'} paddingX={4} paddingBottom={2} style={{ gap: '32px' }}>
          <Box display="flex" style={{ gap: '20px' }}>
            <img alt="maxCashback wallet gift" src={WalletGift} />
            <Typography variant="body2">You can access Wallet, only if you've placed atleast one order with CashBack Promo</Typography>
          </Box>
          <Box display="flex" style={{ gap: '20px' }}>
            <img alt="maxCashback wallet gift" src={WalletLocked} />
            <Typography variant="body2">Wallet can be only accessed or viewed by the registered email address used for prior order</Typography>
          </Box>
          <Box display="flex" style={{ gap: '20px' }}>
            <img alt="maxCashback wallet gift" src={WalletPassword} />
            <Typography variant="body2">If you checked out as Guest, then please Log-in with the same email address to view the Wallet</Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent={'flex-end'} flexDirection="column" width="inherit">
        <Box className={classes.walletInfo}>
          <Box paddingX={2}>
            <Box marginBottom={2}>
              <TextField
                name="email"
                value={values.email}
                type="email"
                inputProps={{
                  readOnly: isOtpSent || loading || (currentUser && !currentUser.isAnonymous) || !currentUser
                }}
                onChange={handleChange}
                label="Email address (required)"
                fullWidth
                placeholder="Email address"
                variant="outlined"
                onBlur={handleBlur}
                error={!!touched.email && !!errors.email}
                helperText={!!touched.email && errors.email}
              />
            </Box>

            {isOtpSent && (
              <>
                <Box marginBottom={1}>
                  <TextField
                    name="otp"
                    type="text"
                    value={values.otp}
                    label="Verification Code"
                    onChange={handleChange}
                    fullWidth
                    placeholder="Enter Verification Code"
                    required
                    variant="outlined"
                    onBlur={handleBlur}
                    error={!!touched.otp && !!errors.otp}
                    helperText={!!touched.otp && errors.otp}
                  />
                </Box>
                <Box marginBottom={2}>
                  <Typography variant="body1" color="secondary">
                    A Verification Code has been sent in your given email
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Button variant="contained" disabled={loading} color="primary" type="submit" size="medium" className={classes.submitButton}>
          {isOtpSent ? (
            <> Sign in To Wallet {loading && <CircularProgress size={18} style={{ marginLeft: 5 }} />}</>
          ) : (
            <> Send Verification Code {loading && <CircularProgress size={18} style={{ marginLeft: 5 }} />}</>
          )}
        </Button>
      </Box>
    </form>
  );
};

export default WalletLogin;
