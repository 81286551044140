import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Divider } from '@material-ui/core';
import Moment from 'moment';
import { useStyles } from './style';
import { useGetCryptoCouponByCouponId, useGetOrderQuery } from 'graphql/hooks';
import OrderItems from './OrderItems';
import { parseOrderItems } from './utils';
import { convertPrice, convertToCCTokens } from 'util/number';
import { orderType, paymentTypeDict } from './constants';
import { useAlert } from 'hooks';
import { useHistory } from 'react-router-dom';

interface IProps {
  orderId: string | null;
  buyerId: string;
}

const Content: React.FC<IProps> = ({ orderId, buyerId }) => {
  const classes = useStyles();
  const history = useHistory();
  const statusColor = {
    CREATED: classes.blue,
    PLACED: classes.blue,
    REJECTED: classes.red,
    CANCELLED: classes.red,
    ACCEPTED: classes.yellow,
    READY: classes.green,
    CLOSED: classes.grey
  };

  const [totalItemCount, setTotalItemCount] = useState(0);

  const { order, error } = useGetOrderQuery(orderId, buyerId);
  const couponId = order && order.coupon && order.coupon.id ? order.coupon.id : null;
  const { data: couponItemData } = useGetCryptoCouponByCouponId(couponId);

  const { lokoAlert } = useAlert();

  /**
   * Setting item count
   */
  useEffect(() => {
    if (order && order.items) {
      let totalCount = 0;

      order.items.forEach((item: any) => {
        totalCount += item.count;
      });

      setTotalItemCount(totalCount);
    }
  }, [order]);

  useEffect(() => {
    if (error) {
      lokoAlert('Failed to fetch Order Details');
    }
  }, [error, lokoAlert]);

  const renderContent = () => {
    if (order) {
      const {
        orderNumber,
        subTotal,
        tip,
        tax,
        deliveryFee,
        couponDiscount,
        coupon,
        paymentType,
        type,
        createdAt,
        deliveryDistance,
        deliveryAddress,
        deliveryNote,
        note,
        dropOffInstruction,
        lokobeeFee,
        adjustAmount,
        totalAfterAdjust,
        adjustReason,
        isDiscounted,
        expectTime
      } = order;

      const logoUrl = order.getRestaurantLogo;

      const total = order.getTotal;

      const restaurantName = order.getRestaurantName;

      const { text: deliveryAddressText } = deliveryAddress || { text: '' };

      const paymentTypeString = paymentType && paymentTypeDict[paymentType];

      const lokobeeFeeNumber = lokobeeFee && lokobeeFee.intValue && lokobeeFee.shift ? parseFloat(convertPrice(lokobeeFee.intValue, lokobeeFee.shift)) : 0.0;

      const taxNumber = tax && tax.intValue && tax.shift ? parseFloat(convertPrice(tax.intValue, tax.shift)) : 0;

      const orderStatus = order?.status;

      const _items = parseOrderItems(order?.items, isDiscounted);

      const totalPointsRedeemed = order?.redeemPoints;

      const orderEmail = order.orderEmail || 'NA';

      const orderPhoneNumber = order.orderPhoneNumber || 'NA';

      const expectTimeMoment = Moment(expectTime);

      const couponCode = coupon ? coupon.code : '';

      const couponData = couponItemData && couponItemData.getCouponByCouponId;

      const consumerReward = couponData?.consumerReward;
      const cryptoUnit = couponData?.cryptoUnit;
      const earnedTokens = convertToCCTokens(consumerReward, cryptoUnit);

      return (
        <Box flex={1} boxShadow="none" width="100%">
          <Grid container={true} className={classes.container} alignItems="center">
            <Grid item={true} xs={2}>
              {!logoUrl || logoUrl.trim() === '' ? <p></p> : <img alt="" className={classes.img} src={logoUrl} width="100%" />}
            </Grid>
            <Grid item={true} xs={7}>
              <Box paddingX={1}>
                <Typography className={classes.boldText} display="block" variant="body1">
                  {restaurantName}&nbsp;
                </Typography>
                <Typography variant="caption">{Moment(createdAt).format('DD MMM YYYY, hh:mm A')}</Typography>
              </Box>
            </Grid>
            <Grid item={true} xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <Box>
                  <Box display="flex" justifyContent="flex-end" height="max-content" alignItems="flex-end">
                    {orderStatus && <Box className={statusColor[orderStatus]} padding="8px" margin={1} borderRadius="100%" />}
                    <Typography variant="overline">{orderStatus} </Typography>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" marginTop={-1}>
                    <Typography variant="overline">{orderType[type]} </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Box padding={2}>
            <Typography variant="body1">Contact details</Typography>

            <Box>
              <Typography variant="caption">Phone number:&nbsp;{orderPhoneNumber}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">Email:&nbsp;{orderEmail}</Typography>
            </Box>
          </Box>
          <Divider variant="middle" />
          <Box padding={2}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography variant="caption">Order #:&nbsp;</Typography>
                  <Typography className={classes.boldText} variant="caption">
                    {orderNumber}
                  </Typography>
                </Box>
                {(type === 'TAKEOUT' || type === 'DELIVERY') && (
                  <Box paddingTop={1}>
                    <Typography variant="caption">ETA:&nbsp;</Typography>
                    <Typography className={classes.boldText} variant="caption">
                      {`${expectTimeMoment.format('DD MMM').toUpperCase()}, ${expectTimeMoment.format('hh:mm A')}`}
                    </Typography>
                  </Box>
                )}
                {couponData && consumerReward && cryptoUnit && orderStatus === 'CLOSED' && (
                  <>
                    <Box paddingTop={1}>
                      <Typography variant="caption">
                        Congratulation, You earned <strong>{earnedTokens}</strong> on this order!{' '}
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        View your CBT in{' '}
                        <u>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              history.push(`/wallet`);
                            }}>
                            Wallet
                          </span>
                        </u>
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              <Box>
                {!!order.total?.intValue && (
                  <>
                    <Typography variant="caption">Payment:&nbsp;</Typography>
                    <Typography className={classes.boldText} variant="caption">
                      {paymentTypeString}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            {type === 'DELIVERY' && (
              <>
                <Box paddingTop={1}>
                  <Typography variant="caption">
                    Delivery Address&nbsp;(<span className={classes.boldTextNonLabel}>{deliveryDistance?.toFixed(2)} Miles</span>):
                  </Typography>
                  <Typography className={classes.boldText} display="block" variant="caption">
                    {deliveryAddressText}
                  </Typography>
                </Box>
                {deliveryNote && (
                  <Box paddingTop={1}>
                    <Typography variant="caption">Delivery Note:&nbsp;</Typography>
                    <Typography className={classes.boldText} variant="caption">
                      {deliveryNote}
                    </Typography>
                  </Box>
                )}
                {!!dropOffInstruction && (
                  <Box paddingTop={1}>
                    <Typography variant="caption">Drop-off Instruction:&nbsp;</Typography>
                    <Typography className={classes.boldText} variant="caption">
                      {dropOffInstruction}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Divider variant="middle" />
          <OrderItems items={_items} />
          {note && (
            <>
              <Divider variant="middle" />
              <Box paddingX={2} paddingY={1}>
                <Typography style={{ fontStyle: 'italic', textDecoration: 'underline dotted' }} variant="caption">
                  Note:&nbsp;
                </Typography>
                <Typography style={{ fontStyle: 'italic' }} variant="caption">
                  {note}
                </Typography>
              </Box>
            </>
          )}
          <Divider variant="middle" />
          <Box padding={1}>
            {adjustAmount && adjustReason && (
              <Box paddingX={1}>
                <Typography variant="caption">Ajust Reason:&nbsp;</Typography>
                <Typography variant="caption" className={classes.boldText}>
                  {adjustReason}
                </Typography>
              </Box>
            )}
            <Box padding={1} width="60%" marginLeft="auto">
              {!!subTotal && (
                <Box display="flex">
                  <Typography variant="caption">
                    Subtotal ({totalItemCount} {totalItemCount > 1 ? 'Items' : 'Item'}):
                  </Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${convertPrice(subTotal.intValue ? subTotal.intValue : 0, subTotal.shift ? subTotal.shift : -2)}
                    </Typography>
                  </Box>
                </Box>
              )}

              {couponDiscount && (
                <Box display="flex">
                  <Typography variant="caption" className={classes.greenTextColor}>
                    Discount: {`${couponCode !== '' ? `(${couponCode})` : ''}`}
                  </Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={`${classes.boldText} ${classes.greenTextColor}`}>
                      - ${convertPrice(couponDiscount.intValue ? couponDiscount.intValue : 0, couponDiscount.shift ? couponDiscount.shift : -2)}
                    </Typography>
                  </Box>
                </Box>
              )}

              {type === 'DELIVERY' && deliveryFee && (
                <Box display="flex">
                  <Typography variant="caption">Delivery Charge:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${convertPrice(deliveryFee.intValue ? deliveryFee.intValue : 0, deliveryFee.shift ? deliveryFee.shift : -2)}
                    </Typography>
                  </Box>
                </Box>
              )}

              {tax && lokobeeFeeNumber !== 0 && (
                <Box display="flex">
                  <Typography variant="caption">Tax & Fees:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${(taxNumber + lokobeeFeeNumber).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              )}

              {tax && lokobeeFeeNumber === 0 && (
                <Box display="flex">
                  <Typography variant="caption">Tax:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${taxNumber.toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              )}

              {!!tip && (
                <Box display="flex">
                  <Typography variant="caption">Tip:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${convertPrice(tip.intValue ? tip.intValue : 0, tip.shift ? tip.shift : -2)}
                    </Typography>
                  </Box>
                </Box>
              )}

              {!!total && (
                <Box display="flex">
                  <Typography variant="caption">Total Amount:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${total}
                    </Typography>
                  </Box>
                </Box>
              )}

              {adjustAmount && adjustReason && (
                <Box display="flex">
                  <Typography variant="caption">Adjust Amount:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${convertPrice(adjustAmount.intValue ? adjustAmount.intValue : 0, adjustAmount.shift ? adjustAmount.shift : -2)}
                    </Typography>
                  </Box>
                </Box>
              )}

              {totalAfterAdjust && (
                <Box display="flex">
                  <Typography variant="caption">Total After Adjust:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${convertPrice(totalAfterAdjust.intValue ? totalAfterAdjust.intValue : 0, totalAfterAdjust.shift ? totalAfterAdjust.shift : -2)}
                    </Typography>
                  </Box>
                </Box>
              )}

              {!!totalPointsRedeemed && (
                <Box display="flex">
                  <Typography variant="caption">PTS Redeemed:&nbsp;</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      {totalPointsRedeemed}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      );
    }

    return null;
  };

  return renderContent();
};

export default Content;
