import { Box, Typography } from '@material-ui/core';
import { useGetRestaurantById } from 'graphql/hooks';
import React, { useEffect, useState } from 'react';
import { useStyles } from './style';
import { ServiceType } from 'generated/types';
import { filter } from 'lodash';
import { useStore } from 'store';
import { getTimeData } from 'components/DateTimePicker/utils';
import { isRestaurantClosed } from 'pages/MyCart/util';
import TodayBizHour from './TodayBizHour';
import moment from 'moment';

interface IProps {
  restaurantId: string | number;
}

const BizHoursStatus = ({ restaurantId }: IProps) => {
  const classes = useStyles();

  const { restaurant } = useGetRestaurantById(restaurantId, 'ALL');

  const {
    state: { deliveryProvider }
  } = useStore();

  const bizHours = restaurant?.getBizHours;
  const cryptoCouponExists = restaurant?.cryptoCouponExists;
  const isTakeoutDisabled = !(restaurant?.takeoutPaymentSetting?.onlinePaymentTypes || restaurant?.takeoutPaymentSetting?.onsitePaymentTypes) || !restaurant.enableTakeoutOrder;

  const isDineouttDisabled = !(restaurant?.dineoutPaymentSetting?.onlinePaymentTypes || restaurant?.dineoutPaymentSetting?.onsitePaymentTypes) || !restaurant.enableDineOutOrder;

  const isDeliveryDisabled = !(restaurant?.deliveryPaymentSetting?.onlinePaymentTypes || restaurant?.deliveryPaymentSetting?.onsitePaymentTypes) || !restaurant.enableDeliveryOrder;

  const dineOutBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === ServiceType.RestaurantDineOut || serviceType === ServiceType.RestaurantDineIn) : [];

  const [dineInClosed, setDineInClosed] = useState(false);

  const [nextSlotStr, setNextSlotStr] = useState('');

  const noOrderTypesSupported = isTakeoutDisabled && isDineouttDisabled && isDeliveryDisabled;

  useEffect(() => {
    const isdineInClosed = isRestaurantClosed(dineOutBizHours, 'DINE_OUT', deliveryProvider);
    setDineInClosed(isdineInClosed);
  }, [deliveryProvider, dineOutBizHours]);

  useEffect(() => {
    if (bizHours) {
      let waitMins = 0;

      const curHours = filter(bizHours, (hour) => hour.serviceType === ServiceType.RestaurantDineOut || hour.serviceType === ServiceType.RestaurantDineIn);

      const availableSlots = getTimeData(curHours, waitMins);
      if (availableSlots.length) {
        const firstSlot = availableSlots[0].availableSlots[0];

        if (firstSlot) {
          const tomorrow = moment().add(1, 'day');
          const firstSlotMoment = moment(firstSlot);
          const nextSlot = firstSlot.format('LT');
          if (firstSlotMoment.isSame(tomorrow, 'day')) {
            setNextSlotStr(nextSlot + ' Tomorrow');
          } else if (firstSlotMoment.isSame(moment(), 'day')) {
            setNextSlotStr(nextSlot + ' Today');
          } else {
            setNextSlotStr(moment(firstSlot).format('Do MMM, LT'));
          }
        }
      }
    }
  }, [bizHours]);
  if (dineOutBizHours && dineOutBizHours.length > 0) {
    return (
      <Box className={`${classes.container} ${dineInClosed ? classes.errorBg : classes.successBg}`}>
        <Box padding={1}>
          {noOrderTypesSupported && <Typography variant="body1"> Orders not supported by the restaurant</Typography>}
          {dineInClosed ? (
            <Typography variant="body1">
              Opens: {nextSlotStr} {!isTakeoutDisabled ? '| Place advance takeout order ' : ''}
              {cryptoCouponExists ? '(All CashBack Promos Powered by maxcashback.ai)' : null}
            </Typography>
          ) : (
            <TodayBizHour bizHours={dineOutBizHours} cryptoCouponExists={cryptoCouponExists} />
          )}
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};

export default BizHoursStatus;
