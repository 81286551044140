import { makeStyles } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import yellowLines from 'assets/img/mobile-bg-yellow-line.svg';
import whiteLines from 'assets/img/mobile-bg-white-line.svg';
import WalletCoin from 'assets/img/wallet/wallet-01-coin.jpg';

export const useStyles = makeStyles((theme) => ({
  walletHead: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url('${whiteLines}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  walletLoginHeadCustom: {
    display: 'flex',
    flexDirection: 'column',
    width: '100px'
  },
  walletHeadCustom: {
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    borderTopLeftRadius: theme.spacing(4),
    borderTopRightRadius: theme.spacing(4)
  },
  walletContainer: {
    backgroundPosition: 'center',
    borderTopLeftRadius: theme.spacing(4),
    borderTopRightRadius: theme.spacing(4),
    overflowY: 'scroll'
  },
  walletInfo: {
    borderTopLeftRadius: theme.spacing(4),
    borderTopRightRadius: theme.spacing(4),
    marginTop: theme.spacing(-3),
    background: '#F4F4F4 !important'
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  swiper: {
    '& .swiper-container': {
      paddingBottom: theme.spacing(5)
    },
    '& .swiper-pagination-bullet-active': {
      background: theme.palette.secondary.main
    },
    '& .swiper-scrollbar': {
      height: '0.4rem',
      borderRadius: '0'
    },
    '& .swiper-scrollbar-drag': {
      background: '#000',
      borderRadius: '0'
    }
  },
  swiperBox: {
    paddingTop: theme.spacing(2)
  },
  greenText: {
    color: theme.palette.success.main
  },
  flexItem: {
    display: 'flex'
  },
  coinImg: {
    maxWidth: '100px'
  },
  askToSell: {
    backgroundColor: orange[50],
    padding: theme.spacing(2)
    // fontStyle: 'italic'
  },
  cardItem: {
    backgroundImage: `url('${yellowLines}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right center'
  },
  walletWrapper: {
    background: '#F4F4F4 !important'
  },
  //header
  headerContainer: {
    height: '35vh',
    maxHeight: '45vh',
    top: 0,
    zIndex: 5,
    position: 'relative',
    '&:after': {
      content: '',
      bottom: 0,
      right: 0,
      zIndex: 10
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '60%',
    maxHeight: '70%',
    borderRadius: '0 0 30px 30px',
    padding: theme.spacing(1),
    backgroundImage: `url(${WalletCoin})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    backgroundImage: 'linear-gradient(90deg, #00ABEA, #753CBE)'
  },
  mainHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    color: 'white'
  },
  button: {
    cursor: 'pointer',
    color: 'white'
  },
  submitButton: {
    margin: theme.spacing(1),
    backgroundImage: 'linear-gradient(90deg, #00ABEA, #753CBE)',
    color: 'white',
    display: 'block'
  }
}));
