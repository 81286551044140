export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};



export type ActivateGroceryStoreInput = {
  id: Scalars['ID'];
};

export type ActivateRestaurantInput = {
  id: Scalars['ID'];
};

export type AddBroadcastPhoneNumbersInput = {
  bizId: Scalars['ID'];
  phoneNumbers: Array<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  text?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type AddressInput = {
  text?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type AddToGroceryStoreCartInput = {
  storeId: Scalars['String'];
  items: Array<GroceryStoreCartItemInput>;
};

export type AddUserFeedBackInput = {
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
};

export type Analytic = {
  __typename?: 'Analytic';
  url: Scalars['String'];
};

export type ApplyCryptoCouponInput = {
  bizId: Scalars['ID'];
  code: Scalars['String'];
  subtotal: Scalars['Int'];
  verifyDay?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
  sessionId?: Maybe<Scalars['String']>;
};

export type ApplyCryptoCouponOutput = {
  __typename?: 'ApplyCryptoCouponOutput';
  success: Scalars['Boolean'];
  error?: Maybe<CouponError>;
  token?: Maybe<Scalars['String']>;
  coupon?: Maybe<CryptoCoupon>;
};

export type ApplyRestaurantInput = {
  id: Scalars['String'];
};

export type Articles = {
  __typename?: 'Articles';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  picture?: Maybe<Image>;
  headline1?: Maybe<Scalars['String']>;
  headline2?: Maybe<Scalars['String']>;
  headline1Image?: Maybe<Image>;
  headline2Image?: Maybe<Image>;
};

export type ArticlesInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  headline1?: Maybe<Scalars['String']>;
  headline2?: Maybe<Scalars['String']>;
  headline1Image?: Maybe<Scalars['String']>;
  headline2Image?: Maybe<Scalars['String']>;
};

export type BizAllPauseDate = {
  __typename?: 'BizAllPauseDate';
  takeoutPauseDate?: Maybe<Scalars['String']>;
  deliverPauseDate?: Maybe<Scalars['String']>;
  diningPauseDate?: Maybe<Scalars['String']>;
};

export type BizCard = {
  __typename?: 'BizCard';
  id: Scalars['String'];
  cardImg?: Maybe<Image>;
};

export type BizEmployee = {
  __typename?: 'BizEmployee';
  uid: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  userName: Scalars['String'];
  restaurant: Restaurant;
};

export type BizHour = {
  __typename?: 'BizHour';
  serviceType: ServiceType;
  serviceName: Scalars['String'];
  hours: Array<BizHourSlot>;
};

export type BizHourInput = {
  serviceType: ServiceType;
  serviceName: Scalars['String'];
  hours: Array<BizHourSlotInput>;
};

export type BizHourSlot = {
  __typename?: 'BizHourSlot';
  openDay: Scalars['Int'];
  openHour: Scalars['Int'];
  openMinute: Scalars['Int'];
  closeHour: Scalars['Int'];
  closeMinute: Scalars['Int'];
  closeDay: Scalars['Int'];
};

export type BizHourSlotInput = {
  openDay: Scalars['Int'];
  openHour: Scalars['Int'];
  openMinute: Scalars['Int'];
  closeHour: Scalars['Int'];
  closeMinute: Scalars['Int'];
  closeDay: Scalars['Int'];
};

export type BizOrderPauseDateInput = {
  id: Scalars['String'];
  pauseData?: Maybe<Array<PauseDataInput>>;
};

export type BizPlan = {
  __typename?: 'BizPlan';
  planId?: Maybe<Scalars['String']>;
  bizPlanDetails?: Maybe<BizPlanType>;
  planName?: Maybe<Scalars['String']>;
};

export type BizPlanModel = {
  __typename?: 'BizPlanModel';
  custPctVal?: Maybe<Scalars['Float']>;
  custPctValLower?: Maybe<Scalars['Int']>;
  custAbsVal?: Maybe<Scalars['Int']>;
  bizPctVal?: Maybe<Scalars['Float']>;
  bizPctValLower?: Maybe<Scalars['Int']>;
  bizAbsVal?: Maybe<Scalars['Int']>;
};

export type BizPlanType = {
  __typename?: 'BizPlanType';
  DINE_OUT?: Maybe<BizPlanModel>;
  TAKEOUT?: Maybe<BizPlanModel>;
  DELIVERY?: Maybe<DeliveryModel>;
};

export type BizRatingInput = {
  rateScore?: Maybe<Scalars['Float']>;
  rateText?: Maybe<Scalars['String']>;
};

export type BizReview = {
  __typename?: 'BizReview';
  seller: Restaurant;
  order: Order;
  buyer: LokobeeUser;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  rateScore?: Maybe<Scalars['Float']>;
  rateText?: Maybe<Scalars['String']>;
};

export type BizReviewConnection = {
  __typename?: 'BizReviewConnection';
  edges?: Maybe<Array<BizReviewConnectionEdge>>;
  pageInfo: PageInfo;
};

export type BizReviewConnectionEdge = {
  __typename?: 'BizReviewConnectionEdge';
  node: BizReview;
  cursor: Scalars['String'];
};

export enum BizUserRole {
  Owner = 'OWNER',
  Staff = 'STAFF'
}

export enum BoonsDotIoDeliveryMethod {
  Initiated = 'INITIATED',
  TripCreated = 'TRIP_CREATED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  AutoCancel = 'AUTO_CANCEL',
  Returned = 'RETURNED',
  Delayed = 'DELAYED'
}

export type BoonsDotIoGetDeliveryDetailsRequestInput = {
  bizId?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
};

export enum BroadcastStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Scheduled = 'SCHEDULED'
}

export enum BuyingDeliveryMethod {
  HandDelivery = 'HAND_DELIVERY',
  LeaveAtTheDoor = 'LEAVE_AT_THE_DOOR'
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CalcCartInput = {
  sellerBizId: Scalars['ID'];
  items: Array<OrderLineItemInput>;
  tipAmount?: Maybe<MoneyAmountInput>;
  tipPercent?: Maybe<Scalars['String']>;
};

export type CalcCartOutput = {
  __typename?: 'CalcCartOutput';
  subTotal?: Maybe<MoneyAmount>;
  tax?: Maybe<MoneyAmount>;
  tip?: Maybe<MoneyAmount>;
  total?: Maybe<MoneyAmount>;
};

export type CategoryDisplayOrder = {
  __typename?: 'CategoryDisplayOrder';
  bizId: Scalars['ID'];
  catIds: Array<Scalars['ID']>;
};

export type CcTokenOutput = {
  __typename?: 'CCTokenOutput';
  tokens: Scalars['Int'];
  cryptoUnit: CryptoUnit;
};

export type ChargeSource = {
  __typename?: 'ChargeSource';
  id: Scalars['ID'];
  brand: Scalars['String'];
  last4: Scalars['String'];
};

export type CheckBackSideImageExistsInput = {
  imgId: Scalars['String'];
};

export type CheckVerificationCodeInput = {
  phoneNumber: Scalars['String'];
  code: Scalars['String'];
};

export type CheckWebsiteUrlExitsInput = {
  url: Scalars['String'];
};

export type CloseFeeBackInput = {
  bizId: Scalars['String'];
  orderId: Scalars['String'];
};

export type CloseTableServiceOrderInput = {
  sellerBizId: Scalars['ID'];
  orderId: Scalars['ID'];
};

export type ConsumerDishReviewConnection = {
  __typename?: 'ConsumerDishReviewConnection';
  edges?: Maybe<Array<ConsumerDishReviewConnectionEdge>>;
  pageInfo: PageInfo;
};

export type ConsumerDishReviewConnectionEdge = {
  __typename?: 'ConsumerDishReviewConnectionEdge';
  node: DishReview;
  cursor: Scalars['String'];
};

export type ConsumerGroceryRewardPoint = {
  __typename?: 'ConsumerGroceryRewardPoint';
  id: Scalars['ID'];
  type: GroceryRewardPointType;
  issuer: Scalars['ID'];
  acceptators: Array<Scalars['ID']>;
  activePoints: Scalars['Int'];
  pendingPoints: Scalars['Int'];
};

export type ConsumerRewardPoint = {
  __typename?: 'ConsumerRewardPoint';
  id: Scalars['ID'];
  type: RewardPointType;
  issuer: Scalars['ID'];
  acceptators: Array<Scalars['ID']>;
  activePoints: Scalars['Int'];
  pendingPoints: Scalars['Int'];
};

export type Coupon = {
  __typename?: 'Coupon';
  bizId: Scalars['ID'];
  code: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  name: Scalars['String'];
  description: Scalars['String'];
  discountType: CouponDiscountType;
  discountValue: Scalars['String'];
  maxDiscount?: Maybe<Scalars['Int']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  subtotalThreshold: Scalars['Int'];
  taxMethod: CouponTaxMethod;
  maxUse?: Maybe<Scalars['Int']>;
  image?: Maybe<Image>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isSingleUse?: Maybe<Scalars['Boolean']>;
  orderType?: Maybe<Array<Maybe<Scalars['String']>>>;
  dayOfWeek?: Maybe<Array<Maybe<Scalars['Int']>>>;
  useCnt?: Maybe<Scalars['Int']>;
  budget?: Maybe<Scalars['Int']>;
  totalSales?: Maybe<Scalars['Int']>;
  totalBudget?: Maybe<Scalars['Int']>;
};

export enum CouponDiscountType {
  Percent = 'PERCENT',
  Dollar = 'DOLLAR'
}

export enum CouponError {
  InternalError = 'INTERNAL_ERROR',
  InvalidCouponCode = 'INVALID_COUPON_CODE',
  CouponExpired = 'COUPON_EXPIRED',
  ExceedMaxUse = 'EXCEED_MAX_USE',
  LowSubtotal = 'LOW_SUBTOTAL',
  DayOfWeek = 'DAY_OF_WEEK',
  CouponOrderType = 'COUPON_ORDER_TYPE',
  AlreadyUsed = 'ALREADY_USED',
  InvalidBizId = 'INVALID_BIZ_ID'
}

export enum CouponTaxMethod {
  PreDiscount = 'PRE_DISCOUNT',
  PostDiscount = 'POST_DISCOUNT'
}

export type CreateBackSideImageImageUploadUrlInput = {
  fileName: Scalars['String'];
};

export type CreateBizEmployeeInput = {
  bizId: Scalars['ID'];
  userName: Scalars['String'];
  password: Scalars['String'];
  displayName: Scalars['String'];
};

export type CreateCouponInput = {
  bizId: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  discountType: CouponDiscountType;
  discountValue: Scalars['String'];
  maxDiscount?: Maybe<Scalars['Int']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  subtotalThreshold: Scalars['Int'];
  taxMethod: CouponTaxMethod;
  maxUse?: Maybe<Scalars['Int']>;
  imgTemplateId?: Maybe<Scalars['ID']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isSingleUse?: Maybe<Scalars['Boolean']>;
  orderType?: Maybe<Array<Maybe<Scalars['String']>>>;
  dayOfWeek?: Maybe<Array<Maybe<Scalars['Int']>>>;
  budget?: Maybe<Scalars['Int']>;
};

export type CreateCryptoCouponInput = {
  bizId: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  discountType: CouponDiscountType;
  discountValue: Scalars['Int'];
  maxDiscount?: Maybe<Scalars['Int']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  minSpending: Scalars['Int'];
  maxUse: Scalars['Int'];
  imgTemplateId?: Maybe<Scalars['ID']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isSingleUse?: Maybe<Scalars['Boolean']>;
  orderType?: Maybe<Array<Maybe<Scalars['String']>>>;
  dayOfWeek?: Maybe<Array<Maybe<Scalars['Int']>>>;
  couponCost: Scalars['Int'];
  tags?: Maybe<Array<Scalars['String']>>;
};

export type CreateDishCategoryInput = {
  bizId: Scalars['ID'];
  title: Array<MultiLangStringInput>;
  description?: Maybe<Array<MultiLangStringInput>>;
  type?: Maybe<DishType>;
};

export type CreateDishInput = {
  bizId: Scalars['ID'];
  categoryId: Scalars['ID'];
  title?: Maybe<Array<MultiLangStringInput>>;
  description?: Maybe<Array<MultiLangStringInput>>;
  price?: Maybe<Array<DishPriceInput>>;
  imageIds?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<DishTag>>;
  status: DishStatus;
  extraGroups?: Maybe<Array<Maybe<DishExtraGroupInput>>>;
  type?: Maybe<DishType>;
  pizzaBasePrice?: Maybe<Array<PizzaBasePriceInput>>;
  pizzaCrustIds?: Maybe<Array<Scalars['String']>>;
  pizzaDefaultCrustId?: Maybe<Scalars['String']>;
  pizzaSauce?: Maybe<DishPizzaSauceInput>;
  pizzaCheese?: Maybe<DishPizzaCheeseInput>;
  pizzaToppings?: Maybe<Array<DishPizzaToppingInput>>;
  isReward?: Maybe<Scalars['Boolean']>;
  rewardPoints?: Maybe<Scalars['Int']>;
  limitPerDay?: Maybe<Scalars['Int']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
};

export type CreateGroceryBizEmployeeInput = {
  storeId: Scalars['ID'];
  userName: Scalars['String'];
  password: Scalars['String'];
  displayName: Scalars['String'];
};

export type CreateGroceryCouponInput = {
  storeId: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  discountType: CouponDiscountType;
  discountValue: Scalars['String'];
  maxDiscount?: Maybe<Scalars['Int']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  subtotalThreshold: Scalars['Int'];
  maxUse?: Maybe<Scalars['Int']>;
  imgTemplateId?: Maybe<Scalars['ID']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  orderType?: Maybe<Array<Maybe<Scalars['String']>>>;
  dayOfWeek?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type CreateGroceryOrderInput = {
  type: GroceryOrderType;
  sellerStoreId: Scalars['ID'];
  items: Array<GroceryOrderLineItemInput>;
  note?: Maybe<Scalars['String']>;
  expectTime: Scalars['Timestamp'];
  deliveryAddress?: Maybe<AddressInput>;
  paymentType: PaymentType;
  paymentMethod?: Maybe<Scalars['String']>;
  tip: Scalars['Int'];
  lokobeeFee?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  email: Scalars['String'];
  smsNotification: Scalars['Boolean'];
  deliveryNote?: Maybe<Scalars['String']>;
  couponToken?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
  redeemPoints?: Maybe<Scalars['Int']>;
  deliveryProvider?: Maybe<GroceryDeliveryProviders>;
  deliveryMode?: Maybe<GroceryDeliveryModes>;
  doordashQuoteId?: Maybe<Scalars['String']>;
  dropOffInstruction?: Maybe<Scalars['String']>;
  apartmentNumber?: Maybe<Scalars['String']>;
};

export type CreateGroceryOrderOutput = {
  __typename?: 'CreateGroceryOrderOutput';
  orderId: Scalars['String'];
  requestId: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type CreateGroceryStoreCategoryImageInput = {
  categoryName: Scalars['String'];
  fileName: Scalars['String'];
};

export type CreateGroceryStoreInput = {
  name?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<GroceryStoreCategoryInput>>;
  description?: Maybe<Scalars['String']>;
  logoId?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  timezone?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['String']>;
  bizHours?: Maybe<Array<GroceryBizHourInput>>;
  enableTakeoutOrder?: Maybe<Scalars['Boolean']>;
  enableDeliveryOrder?: Maybe<Scalars['Boolean']>;
  takeoutPaymentSetting?: Maybe<GroceryPaymentSettingInput>;
  deliveryPaymentSetting?: Maybe<GroceryPaymentSettingInput>;
  taxModes?: Maybe<Array<TaxModeInput>>;
  deliverySetting?: Maybe<GroceryDeliverySettingInput>;
  takeoutWaitMinutes?: Maybe<Scalars['Int']>;
  announcement?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  orderEmails?: Maybe<Array<Scalars['String']>>;
  reportEmails?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Scalars['String']>;
  googleReviewLink?: Maybe<Scalars['String']>;
  galleryImageIds?: Maybe<Array<Scalars['String']>>;
  issueRewardPoint?: Maybe<Scalars['Boolean']>;
  rewardSetting?: Maybe<GroceryRewardSettingInput>;
  deliveryProvider?: Maybe<GroceryDeliveryProviders>;
  deliveryMode?: Maybe<GroceryDeliveryModes>;
  packingWaitMins?: Maybe<Scalars['Int']>;
};

export type CreateGroceryStoreItemImagesInput = {
  upcCode: Scalars['String'];
  fileNames: Array<Scalars['String']>;
};

export type CreateImageUploadUrlInput = {
  usage: ImageUsage;
  fileName: Scalars['String'];
};

export type CreateMenuCardInput = {
  bizId: Scalars['ID'];
};

export type CreateOrderInput = {
  type: OrderType;
  sellerBizId: Scalars['ID'];
  items: Array<OrderLineItemInput>;
  note?: Maybe<Scalars['String']>;
  expectTime: Scalars['Timestamp'];
  deliveryAddress?: Maybe<AddressInput>;
  paymentType: PaymentType;
  paymentMethod?: Maybe<Scalars['String']>;
  tip: MoneyAmountInput;
  lokobeeFee?: Maybe<MoneyAmountInput>;
  total: MoneyAmountInput;
  email: Scalars['String'];
  smsNotification: Scalars['Boolean'];
  tableName?: Maybe<Scalars['String']>;
  partySize?: Maybe<Scalars['Int']>;
  deliveryNote?: Maybe<Scalars['String']>;
  couponToken?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
  redeemPoints?: Maybe<Scalars['Int']>;
  boonsQuoteId?: Maybe<Scalars['String']>;
  doordashClassicQuoteId?: Maybe<Scalars['String']>;
  deliveryFee?: Maybe<MoneyAmountInput>;
  deliveryProvider?: Maybe<DeliveryProviders>;
  deliveryMode?: Maybe<DeliveryModes>;
  dropOffInstruction?: Maybe<Scalars['String']>;
  apartmentNumber?: Maybe<Scalars['String']>;
  custDisplayName?: Maybe<Scalars['String']>;
  phoneOrder?: Maybe<Scalars['Boolean']>;
  sessionId?: Maybe<Scalars['String']>;
};

export type CreateOrderOutput = {
  __typename?: 'CreateOrderOutput';
  orderId: Scalars['String'];
  requestId: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type CreateRestaurantInput = {
  type?: Maybe<RestaurantType>;
  i18n?: Maybe<I18NSettingInput>;
  name?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  subName?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  description?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  email?: Maybe<Scalars['String']>;
  orderEmails?: Maybe<Array<Scalars['String']>>;
  reportEmails?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Scalars['String']>;
  virtualPhone?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  addressNote?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  announcement?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  bizHours?: Maybe<Array<BizHourInput>>;
  taxRate?: Maybe<Scalars['String']>;
  logoId?: Maybe<Scalars['String']>;
  defaultImage?: Maybe<Scalars['String']>;
  bizCardBackSideImg?: Maybe<Scalars['String']>;
  imageIds?: Maybe<Array<Scalars['String']>>;
  isOnVacation?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<CurrencyCode>;
  issueRewardPoint?: Maybe<Scalars['Boolean']>;
  rewardSetting?: Maybe<RewardSettingInput>;
  takeoutPaymentSetting?: Maybe<PaymentSettingInput>;
  dineinPaymentSetting?: Maybe<PaymentSettingInput>;
  dineoutPaymentSetting?: Maybe<PaymentSettingInput>;
  deliveryPaymentSetting?: Maybe<PaymentSettingInput>;
  enableTakeoutOrder?: Maybe<Scalars['Boolean']>;
  enableDineInOrder?: Maybe<Scalars['Boolean']>;
  enableDineOutOrder?: Maybe<Scalars['Boolean']>;
  enableDeliveryOrder?: Maybe<Scalars['Boolean']>;
  enableDishNote?: Maybe<Scalars['Boolean']>;
  deliverySetting?: Maybe<DeliverySettingInput>;
  takeoutWaitMinutes?: Maybe<Scalars['Int']>;
  cookingWaitMinutes?: Maybe<Scalars['Int']>;
  opsSetting?: Maybe<OpsSettingInput>;
  googleReviewLink?: Maybe<Scalars['String']>;
  autoOrderConfirm?: Maybe<Scalars['Boolean']>;
  callForNewOrder?: Maybe<Scalars['Boolean']>;
  webSite?: Maybe<Scalars['String']>;
  takeoutPauseDate?: Maybe<Scalars['String']>;
  deliverPauseDate?: Maybe<Scalars['String']>;
  diningPauseDate?: Maybe<Scalars['String']>;
  vacation?: Maybe<VacationInput>;
  bizCardBackSideMainTitle?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  bizCardBackSideMainSubTitle?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  avgTimeForDriver?: Maybe<Scalars['Int']>;
  bizWebSiteUrl?: Maybe<Scalars['String']>;
};

export type CreateReviewInput = {
  bizId: Scalars['String'];
  orderId: Scalars['String'];
  userUid: Scalars['String'];
  dishRatings?: Maybe<Array<Maybe<DishRatingInput>>>;
  bizRating?: Maybe<BizRatingInput>;
};

export type CreateStoreCardInput = {
  bizId: Scalars['ID'];
};

export type CreateStripeSetupIntentInput = {
  email: Scalars['String'];
};

export type CreateStripeSetupIntentOutput = {
  __typename?: 'CreateStripeSetupIntentOutput';
  clientSecret: Scalars['String'];
};

export type CreateTableInput = {
  bizId: Scalars['ID'];
  tableName: Scalars['String'];
  seats: Scalars['Int'];
};

export type CreateTableServiceOrderInput = {
  sellerBizId: Scalars['ID'];
  items: Array<OrderLineItemInput>;
  note?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  partySize?: Maybe<Scalars['Int']>;
};

export type CreateTextBroadcastInput = {
  bizId: Scalars['ID'];
  bizPhoneNumber: Scalars['String'];
  frequency: Frequency;
  dayOfWeek: Scalars['Int'];
  timeHour: Scalars['Int'];
  timeMin: Scalars['Int'];
  textBody: Scalars['String'];
};

export type CreateWebsiteContentInput = {
  bizId: Scalars['ID'];
  url: Scalars['String'];
  displayImage?: Maybe<Scalars['String']>;
  headline1?: Maybe<Scalars['String']>;
  headline2?: Maybe<Scalars['String']>;
  about?: Maybe<Array<Maybe<WebsiteContentAboutInput>>>;
  menuPicture?: Maybe<Scalars['String']>;
  menuHeadline?: Maybe<Scalars['String']>;
  fbLink?: Maybe<Scalars['String']>;
  instaLink?: Maybe<Scalars['String']>;
  testimonials?: Maybe<Array<Maybe<WebsiteContentTestimonialsInput>>>;
  googleAnalyticsTrackingId?: Maybe<Scalars['String']>;
  blog?: Maybe<Array<Maybe<ArticlesInput>>>;
  keywords?: Maybe<Scalars['String']>;
};

export type CryptoCoupon = {
  __typename?: 'CryptoCoupon';
  bizId: Scalars['ID'];
  code: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  name: Scalars['String'];
  description: Scalars['String'];
  discountType: CouponDiscountType;
  discountValue: Scalars['Int'];
  maxDiscount?: Maybe<Scalars['Int']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  minSpending: Scalars['Int'];
  maxUse: Scalars['Int'];
  image?: Maybe<Image>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isSingleUse?: Maybe<Scalars['Boolean']>;
  orderType?: Maybe<Array<Maybe<Scalars['String']>>>;
  dayOfWeek?: Maybe<Array<Maybe<Scalars['Int']>>>;
  used?: Maybe<Scalars['Int']>;
  totalSales?: Maybe<Scalars['Int']>;
  couponCost: Scalars['Int'];
  cryptoEarned: Scalars['Int'];
  bizReward: Scalars['Int'];
  consumerReward: Scalars['Int'];
  cryptoUnit: CryptoUnit;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type CryptoCouponConnection = {
  __typename?: 'CryptoCouponConnection';
  coupons: Array<CryptoCoupon>;
  pageCursor?: Maybe<Scalars['String']>;
};

export type CryptoCouponInfo = {
  __typename?: 'CryptoCouponInfo';
  id: Scalars['ID'];
  code: Scalars['String'];
  ticketId: Scalars['String'];
};

export enum CryptoUnit {
  Cc = 'CC',
  Twei = 'TWEI',
  Gwei = 'GWEI',
  Wei = 'WEI'
}

export enum CurrencyCode {
  Usd = 'usd'
}

export type CustomerCreateFeeBackInput = {
  bizId: Scalars['String'];
  orderId: Scalars['String'];
  userUid: Scalars['String'];
  message: Scalars['String'];
  rateScore?: Maybe<Scalars['Float']>;
};

export type CustomerGetFeeBackByOrderIdInput = {
  orderId: Scalars['String'];
  bizId: Scalars['String'];
};

export type CustomerGetFeeBacksInput = {
  uid: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type DeleteBizEmployeeInput = {
  bizId: Scalars['ID'];
  uid: Scalars['ID'];
};

export type DeleteCouponInput = {
  bizId: Scalars['ID'];
  code: Scalars['String'];
};

export type DeleteDishCategoryInput = {
  id: Scalars['ID'];
  bizId: Scalars['ID'];
};

export type DeleteDishInput = {
  bizId: Scalars['ID'];
  id: Scalars['ID'];
};

export type DeleteDishReviewInput = {
  orderId: Scalars['String'];
  bizId: Scalars['String'];
  dishId: Scalars['String'];
};

export type DeleteGroceryBizEmployeeInput = {
  storeId: Scalars['ID'];
  uid: Scalars['ID'];
};

export type DeleteGroceryCouponInput = {
  storeId: Scalars['ID'];
  code: Scalars['String'];
};

export type DeleteRestaurantInput = {
  id: Scalars['ID'];
};

export type DeleteSingleWebsiteBlogDataForBizInput = {
  bizId: Scalars['ID'];
  blogId: Scalars['ID'];
};

export type DeleteStripeCardInput = {
  cardId: Scalars['String'];
};

export type DeleteTableInput = {
  bizId: Scalars['ID'];
  tableName: Scalars['String'];
};

export type DeleteTextBroadcastInput = {
  bizId: Scalars['ID'];
  id: Scalars['ID'];
};

export type DeliveryBoonsCostEstimatePayload = {
  __typename?: 'DeliveryBoonsCostEstimatePayload';
  last_modified?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  request_type?: Maybe<Scalars['String']>;
  pickup_address?: Maybe<Scalars['String']>;
  delivery_address?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  distance_unit?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  quote_id?: Maybe<Scalars['String']>;
};

export type DeliveryCostEstimateDdb = {
  __typename?: 'DeliveryCostEstimateDDB';
  quote_id?: Maybe<Scalars['String']>;
  buyerUid?: Maybe<Scalars['String']>;
  providerQuoteId?: Maybe<Scalars['String']>;
  sellerBizId?: Maybe<Scalars['String']>;
  pickupAddress?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  deliveryMode?: Maybe<DeliveryModes>;
  deliveryProvider?: Maybe<DeliveryProviders>;
  boonsPayload?: Maybe<DeliveryBoonsCostEstimatePayload>;
  doordashClassicPayload?: Maybe<DeliveryDoorDashCostEstimatePayload>;
  createdAt: Scalars['Int'];
};

export type DeliveryCostEstimateOutput = {
  __typename?: 'DeliveryCostEstimateOutput';
  last_modified?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['String']>;
  request_type: Scalars['String'];
  pickup_address: Scalars['String'];
  delivery_address: Scalars['String'];
  fee: Scalars['Float'];
  currency: Scalars['String'];
  distance_unit: Scalars['String'];
  distance: Scalars['String'];
  duration: Scalars['String'];
  _id: Scalars['ID'];
  isCancelled: Scalars['Boolean'];
  quote_id: Scalars['String'];
  doordashFee?: Maybe<Scalars['String']>;
  doordashCurrency?: Maybe<Scalars['String']>;
  doordashDeliveryTime?: Maybe<Scalars['String']>;
  doordashPickupTime?: Maybe<Scalars['String']>;
  doordashPickupWindowStartTime?: Maybe<Scalars['String']>;
  doordashPickupWindowEndTime?: Maybe<Scalars['String']>;
  doordashDropoffAddress?: Maybe<Scalars['String']>;
};

export type DeliveryCostEstimateRequestInput = {
  pickupAddress?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  deliveryMode?: Maybe<DeliveryModes>;
  deliveryProvider: DeliveryProviders;
  sellerBizId?: Maybe<Scalars['String']>;
  doordashPickupAddress?: Maybe<DoordashAddressInput>;
  doordashDropoffAddress?: Maybe<DoordashAddressInput>;
  doordashOrderValue?: Maybe<Scalars['Int']>;
  doordashPromotionId?: Maybe<Scalars['String']>;
};

export type DeliveryDasherDoordashClassic = {
  __typename?: 'DeliveryDasherDoordashClassic';
  id: Scalars['Int'];
  phone_number?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  profile_image_url?: Maybe<Scalars['String']>;
  dasher_phone_number_for_customer?: Maybe<Scalars['String']>;
  vehicle?: Maybe<DeliveryDasherVehicle>;
  location?: Maybe<DeliveryDasherLocation>;
};

export type DeliveryDasherLocation = {
  __typename?: 'DeliveryDasherLocation';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export enum DeliveryDasherStatusDoordashClassic {
  Unassigned = 'unassigned',
  Accepted = 'accepted',
  EnrouteToPickup = 'enroute_to_pickup',
  ArrivedAtStore = 'arrived_at_store',
  PickedUp = 'picked_up',
  EnrouteToDropoff = 'enroute_to_dropoff',
  ArrivedAtConsumer = 'arrived_at_consumer',
  DroppedOff = 'dropped_off',
  EnrouteToReturn = 'enroute_to_return',
  ArrivedAtReturn = 'arrived_at_return',
  PendingReturn = 'pending_return',
  DroppedOffReturn = 'dropped_off_return',
  CouldNotDeliver = 'could_not_deliver'
}

export type DeliveryDasherVehicle = {
  __typename?: 'DeliveryDasherVehicle';
  license_plate_number?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type DeliveryDoorDashCostEstimatePayload = {
  __typename?: 'DeliveryDoorDashCostEstimatePayload';
  id?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['Boolean']>;
  delivery_time?: Maybe<Scalars['String']>;
  pickup_time?: Maybe<Scalars['String']>;
  pickup_window_start_time?: Maybe<Scalars['String']>;
  pickup_window_end_time?: Maybe<Scalars['String']>;
  dropoff_address?: Maybe<DoordashAddress>;
};

export type DeliveryDoorDashPayload = {
  __typename?: 'DeliveryDoorDashPayload';
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  pickup_window_start_time?: Maybe<Scalars['String']>;
  pickup_window_end_time?: Maybe<Scalars['String']>;
  delivery_window_start_time?: Maybe<Scalars['String']>;
  delivery_window_end_time?: Maybe<Scalars['String']>;
  quoted_pickup_time?: Maybe<Scalars['String']>;
  estimated_pickup_time?: Maybe<Scalars['String']>;
  actual_pickup_time?: Maybe<Scalars['String']>;
  quoted_delivery_time?: Maybe<Scalars['String']>;
  estimated_delivery_time?: Maybe<Scalars['String']>;
  actual_delivery_time?: Maybe<Scalars['String']>;
  driver_reference_tag?: Maybe<Scalars['String']>;
  contains_alcohol?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  order_volume?: Maybe<Scalars['Int']>;
  dasher_status?: Maybe<DeliveryDasherStatusDoordashClassic>;
  pickup_address?: Maybe<DoordashAddress>;
  dropoff_address?: Maybe<DoordashAddress>;
  pickup_phone_number?: Maybe<Scalars['String']>;
  customer?: Maybe<DoorDashCustomer>;
  fee?: Maybe<Scalars['Int']>;
  tip?: Maybe<Scalars['Int']>;
  allow_unattended_delivery?: Maybe<Scalars['Boolean']>;
  team_lift_required?: Maybe<Scalars['Boolean']>;
  external_store_id?: Maybe<Scalars['String']>;
  pickup_instructions?: Maybe<Scalars['String']>;
  dasher?: Maybe<DoordashDasher>;
  status?: Maybe<DeliveryStatusDoordashClassic>;
  signature_required?: Maybe<Scalars['Boolean']>;
  barcode_scanning_required?: Maybe<Scalars['Boolean']>;
  delivery_tracking_url?: Maybe<Scalars['String']>;
  external_delivery_id?: Maybe<Scalars['String']>;
  order_value?: Maybe<Scalars['Int']>;
  dropoff_instructions?: Maybe<Scalars['String']>;
  signature_image_url?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  field_errors?: Maybe<Array<Maybe<DoordashFieldErrors>>>;
  delivery_id?: Maybe<Scalars['String']>;
};

export type DeliveryFee = {
  __typename?: 'DeliveryFee';
  mile: Scalars['Int'];
  fee: MoneyAmount;
};

export type DeliveryFeeInput = {
  mile: Scalars['Int'];
  fee: MoneyAmountInput;
};

export type DeliveryModel = {
  __typename?: 'DeliveryModel';
  SELF?: Maybe<BizPlanModel>;
  DOORDASH_CLASSIC?: Maybe<BizPlanModel>;
};

export enum DeliveryModes {
  Realtime = 'realtime',
  Scheduled = 'scheduled'
}

export type DeliveryOrderBoonsTimeLine = {
  __typename?: 'DeliveryOrderBoonsTimeLine';
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  deliveryTrackingId?: Maybe<Scalars['String']>;
  status?: Maybe<DeliveryStatusBoons>;
  title?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  cancelledReason?: Maybe<Scalars['String']>;
  cancelledReasonCode?: Maybe<TCancelledReasonCode>;
  delayReason?: Maybe<Scalars['String']>;
  delayedReasonCode?: Maybe<TDelayedReasonCode>;
  boonsEventId?: Maybe<Scalars['String']>;
};

export type DeliveryOrderDoordashClassicTimeLine = {
  __typename?: 'DeliveryOrderDoordashClassicTimeLine';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  status?: Maybe<DeliveryStatusDoordashClassic>;
  title?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  cancelledReason?: Maybe<Scalars['String']>;
  cancelledReasonCode?: Maybe<TCancelledReasonCode>;
  delayReason?: Maybe<Scalars['String']>;
  delayedReasonCode?: Maybe<TDelayedReasonCode>;
  boonsEventId?: Maybe<Scalars['String']>;
};

export enum DeliveryProviders {
  Self = 'SELF',
  Boons = 'BOONS',
  Buying = 'BUYING',
  DoordashClassic = 'DOORDASH_CLASSIC'
}

export type DeliveryRequest = {
  __typename?: 'DeliveryRequest';
  seller: Restaurant;
  createdAt: Scalars['Timestamp'];
  quote_id?: Maybe<Scalars['String']>;
  buyerUid: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  deliveryTrackingId?: Maybe<Scalars['String']>;
  deliveryMode?: Maybe<DeliveryModes>;
  deliveryProvider: DeliveryProviders;
  deliveryStatusDoordashClassic?: Maybe<DeliveryStatusDoordashClassic>;
  deliveryDasherStatusDoordashClassic?: Maybe<DeliveryDasherStatusDoordashClassic>;
  doordashClassicPayload?: Maybe<DeliveryDoorDashPayload>;
  deliveryTimelineDoordashClassic?: Maybe<Array<Maybe<DeliveryOrderDoordashClassicTimeLine>>>;
  costEstimate?: Maybe<DeliveryCostEstimateDdb>;
};

export type DeliverySetting = {
  __typename?: 'DeliverySetting';
  waitMinutes: Scalars['Int'];
  freeDeliveryAmount?: Maybe<MoneyAmount>;
  minDeliveryAmount?: Maybe<MoneyAmount>;
  fees: Array<DeliveryFee>;
  deliveryRadius: Scalars['Int'];
};

export type DeliverySettingInput = {
  waitMinutes: Scalars['Int'];
  freeDeliveryAmount?: Maybe<MoneyAmountInput>;
  minDeliveryAmount?: Maybe<MoneyAmountInput>;
  fees: Array<DeliveryFeeInput>;
};

export enum DeliveryStatusBoons {
  Initiated = 'INITIATED',
  TripCreated = 'TRIP_CREATED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  AutoClosed = 'AUTO_CLOSED',
  Returned = 'RETURNED',
  Delayed = 'DELAYED'
}

export enum DeliveryStatusDoordashClassic {
  Scheduled = 'scheduled',
  Assigned = 'assigned',
  EnrouteToPickup = 'enroute_to_pickup',
  ArrivedAtStore = 'arrived_at_store',
  PickedUp = 'picked_up',
  ArrivedAtConsumer = 'arrived_at_consumer',
  Delivered = 'delivered',
  Cancelled = 'cancelled',
  PendingReturn = 'pending_return',
  CouldNotDeliver = 'could_not_deliver'
}

export type Dish = {
  __typename?: 'Dish';
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  restaurant: Restaurant;
  category: DishCategory;
  title?: Maybe<Array<Maybe<MultiLangString>>>;
  description?: Maybe<Array<Maybe<MultiLangString>>>;
  images?: Maybe<Array<MultiSizeImage>>;
  tags?: Maybe<Array<Maybe<DishTag>>>;
  status?: Maybe<DishStatus>;
  type?: Maybe<DishType>;
  price?: Maybe<Array<DishPrice>>;
  extraGroups?: Maybe<Array<DishExtraGroup>>;
  pizzaBasePrice?: Maybe<Array<PizzaBasePrice>>;
  pizzaCrust?: Maybe<Array<PizzaCrust>>;
  pizzaSauce?: Maybe<PizzaToppingGroup>;
  pizzaCheese?: Maybe<PizzaToppingGroup>;
  pizzaToppingGroups?: Maybe<Array<PizzaToppingGroup>>;
  pizzaHalfToppingPriceFactor?: Maybe<Scalars['Int']>;
  isReward: Scalars['Boolean'];
  rewardPoints?: Maybe<Scalars['Int']>;
  limitPerDay?: Maybe<Scalars['Int']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
  rateScore?: Maybe<Scalars['Float']>;
  rateCount?: Maybe<Scalars['Int']>;
};


export type DishTitleArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type DishDescriptionArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};

export type DishCategory = {
  __typename?: 'DishCategory';
  id: Scalars['ID'];
  bizId: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  title?: Maybe<Array<Maybe<MultiLangString>>>;
  description?: Maybe<Array<Maybe<MultiLangString>>>;
  dishes?: Maybe<Array<Dish>>;
  dishCnt: Scalars['Int'];
  type?: Maybe<DishType>;
};


export type DishCategoryTitleArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type DishCategoryDescriptionArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type DishCategoryDishesArgs = {
  status?: Maybe<DishStatus>;
};

export type DishConnection = {
  __typename?: 'DishConnection';
  edges?: Maybe<Array<DishConnectionEdge>>;
  pageInfo: PageInfo;
};

export type DishConnectionEdge = {
  __typename?: 'DishConnectionEdge';
  node: Dish;
  cursor: Scalars['String'];
};

export type DishDisplayOrder = {
  __typename?: 'DishDisplayOrder';
  bizId: Scalars['ID'];
  catId: Scalars['ID'];
  dishIds: Array<Scalars['ID']>;
};

export type DishExtraGroup = {
  __typename?: 'DishExtraGroup';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Array<MultiLangString>>;
  minSelect: Scalars['Int'];
  maxSelect: Scalars['Int'];
  multiQtyPerItem: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  items: Array<DishExtraItem>;
};


export type DishExtraGroupTitleArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};

export type DishExtraGroupInput = {
  id?: Maybe<Scalars['ID']>;
  title: Array<MultiLangStringInput>;
  minSelect: Scalars['Int'];
  maxSelect: Scalars['Int'];
  multiQtyPerItem: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  items?: Maybe<Array<DishExtraItemInput>>;
};

export type DishExtraItem = {
  __typename?: 'DishExtraItem';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Array<MultiLangString>>;
  price: Price;
  disabled?: Maybe<Scalars['Boolean']>;
};


export type DishExtraItemTitleArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};

export type DishExtraItemInput = {
  id?: Maybe<Scalars['ID']>;
  title: Array<MultiLangStringInput>;
  price: PriceInput;
};

export type DishIngredient = {
  __typename?: 'DishIngredient';
  bizId: Scalars['ID'];
  key: Scalars['String'];
  title: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type DishIngredientInput = {
  title: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type DishPizzaCheeseInput = {
  groups: Array<DishPizzaToppingGroupInput>;
  maxSelect: Scalars['Int'];
};

export type DishPizzaSauceInput = {
  groups: Array<DishPizzaToppingGroupInput>;
  maxSelect: Scalars['Int'];
};

export type DishPizzaToppingGroupInput = {
  groupPartId: Scalars['ID'];
  items: Array<DishPizzaToppingItemInput>;
};

export type DishPizzaToppingInput = {
  group: DishPizzaToppingGroupInput;
  maxSelect: Scalars['Int'];
};

export type DishPizzaToppingItemInput = {
  partId: Scalars['ID'];
  preset: Scalars['Boolean'];
};

export type DishPrice = {
  __typename?: 'DishPrice';
  id: Scalars['ID'];
  size?: Maybe<Array<MultiLangString>>;
  price: Price;
  isDefault: Scalars['Boolean'];
  discountAmount?: Maybe<Scalars['Int']>;
};


export type DishPriceSizeArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};

export type DishPriceInput = {
  id?: Maybe<Scalars['ID']>;
  size: Array<MultiLangStringInput>;
  price: PriceInput;
  isDefault: Scalars['Boolean'];
  discountAmount?: Maybe<Scalars['Int']>;
};

export type DishRatingInput = {
  dishId: Scalars['String'];
  rateScore?: Maybe<Scalars['Float']>;
  rateText?: Maybe<Scalars['String']>;
};

export type DishReview = {
  __typename?: 'DishReview';
  seller: Restaurant;
  order: Order;
  buyer: LokobeeUser;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  rateScore?: Maybe<Scalars['Float']>;
};

export enum DishStatus {
  Active = 'ACTIVE',
  Hidden = 'HIDDEN',
  Deleted = 'DELETED'
}

export enum DishTag {
  Popular = 'POPULAR'
}

export enum DishType {
  Regular = 'REGULAR',
  TableService = 'TABLE_SERVICE',
  Pizza = 'PIZZA',
  ComboPizza = 'COMBO_PIZZA'
}

export type DoordashAddress = {
  __typename?: 'DoordashAddress';
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  full_address?: Maybe<Scalars['String']>;
};

export type DoordashAddressInput = {
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  full_address?: Maybe<Scalars['String']>;
};

export type DoorDashCustomer = {
  __typename?: 'DoorDashCustomer';
  phone_number?: Maybe<Scalars['String']>;
  business_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  should_send_notifications?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type DoordashDasher = {
  __typename?: 'DoordashDasher';
  id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  dasher_phone_number_for_customer?: Maybe<Scalars['String']>;
  location?: Maybe<DoordashLocation>;
};

export type DoordashFieldErrors = {
  __typename?: 'DoordashFieldErrors';
  field?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type DoordashLocation = {
  __typename?: 'DoordashLocation';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type DownloadMenuCard = {
  __typename?: 'DownloadMenuCard';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type DownloadMenuCardInput = {
  bizId: Scalars['ID'];
};

export type DownloadStoreCard = {
  __typename?: 'DownloadStoreCard';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type DownloadStoreCardInput = {
  bizId: Scalars['ID'];
};

export type DownloadTablesCard = {
  __typename?: 'DownloadTablesCard';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type DownloadTablesCardInput = {
  bizId: Scalars['ID'];
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type EmployeeUpdateDishStatusInput = {
  sellerBizId: Scalars['ID'];
  dishId?: Maybe<Array<Scalars['ID']>>;
  status: Scalars['String'];
};

export type Fee = {
  __typename?: 'Fee';
  amount: MoneyAmount;
  threshold?: Maybe<MoneyAmount>;
};

export type FeeInput = {
  amount: MoneyAmountInput;
  threshold?: Maybe<MoneyAmountInput>;
};

export enum Frequency {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY'
}

export type GetAllDishesInput = {
  bizId: Scalars['ID'];
  status?: Maybe<DishStatus>;
};

export type GetBizAllOrderPauseDateInput = {
  id: Scalars['String'];
};

export type GetBizCardInput = {
  bizId: Scalars['ID'];
};

export type GetBizEmployeeInput = {
  uid: Scalars['ID'];
  bizId: Scalars['ID'];
};

export type GetBizEmployeesInput = {
  bizId: Scalars['ID'];
};

export type GetBizOrderPauseDateInput = {
  id: Scalars['String'];
  type: OrderType;
  orderPauseDate: Scalars['String'];
};

export type GetBizReviewByIdInput = {
  bizId: Scalars['String'];
  orderId: Scalars['String'];
};

export type GetBizReviewsInput = {
  bizId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetBroadcastPhoneNumbersInput = {
  bizId: Scalars['ID'];
};

export type GetBuyerOngoingOrdersInput = {
  buyerUid: Scalars['String'];
  deliveryProvider?: Maybe<DeliveryProviders>;
  type?: Maybe<OrderType>;
  deliveryMode?: Maybe<DeliveryModes>;
};

export type GetBuyerOrdersInput = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetCategoriesByStoreIdInput = {
  storeId: Scalars['String'];
};

export type GetCategoriesWithItemsByStoreIdInput = {
  storeId: Scalars['String'];
};

export type GetCcTokenValue = {
  cents: Scalars['Int'];
  rewardType?: Maybe<RewardType>;
};

export type GetConsumerReviewsInput = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetCouponByCouponIdInput = {
  couponId: Scalars['ID'];
};

export type GetCouponsByBizIdInput = {
  bizId: Scalars['ID'];
  orderPhoneNumber?: Maybe<Scalars['String']>;
};

export type GetCouponsByGroceryStoreInput = {
  storeId: Scalars['ID'];
};

export type GetCouponsByRestaurantInput = {
  bizId: Scalars['ID'];
};

export type GetCryptoCouponHistoryInput = {
  bizId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
  pageCursor?: Maybe<Scalars['String']>;
  scanForward?: Maybe<Scalars['Boolean']>;
};

export type GetCryptoCouponsByBizIdInput = {
  bizId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
};

export type GetDishCategoriesOfRestaurantInput = {
  bizId: Scalars['ID'];
  noEmpty?: Maybe<Scalars['Boolean']>;
};

export type GetDishCategoryDisplayOrderInput = {
  bizId: Scalars['ID'];
};

export type GetDishCategoryInput = {
  bizId: Scalars['ID'];
  id: Scalars['ID'];
};

export type GetDishDisplayOrderInput = {
  bizId: Scalars['ID'];
  catIds?: Maybe<Array<Scalars['ID']>>;
};

export type GetDishesInput = {
  bizId: Scalars['ID'];
  status?: Maybe<DishStatus>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetDishInput = {
  bizId: Scalars['ID'];
  id: Scalars['ID'];
};

export type GetGoogleApiRestrictionInput = {
  placeId?: Maybe<Scalars['String']>;
};

export type GetGroceryAllOrderPauseDateInput = {
  id: Scalars['String'];
};

export type GetGroceryBizEmployeeInput = {
  uid: Scalars['ID'];
  storeId: Scalars['ID'];
};

export type GetGroceryBizEmployeesInput = {
  storeId: Scalars['ID'];
};

export type GetGroceryBuyerOrdersInput = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetGroceryDiscountedItemsInput = {
  storeId: Scalars['ID'];
};

export type GetGroceryItemsByCategoryIdInput = {
  storeId: Scalars['String'];
  categoryId: Scalars['String'];
};

export type GetGroceryItemsBySubCategoryIdInput = {
  storeId: Scalars['String'];
  categoryId: Scalars['String'];
  subCategoryId: Scalars['String'];
};

export type GetGroceryOrderByOrderIdInput = {
  buyerUid: Scalars['String'];
  orderId: Scalars['String'];
};

export type GetGroceryOrderByOrderNumberInput = {
  sellerStoreId: Scalars['String'];
  orderNumber: Scalars['String'];
};

export type GetGroceryOrderPauseDateInput = {
  id: Scalars['String'];
  type: GroceryOrderType;
  orderPauseDate: Scalars['String'];
};

export type GetGroceryRewardItemsInput = {
  storeId: Scalars['ID'];
};

export type GetGroceryRewardPointsForConsumerInput = {
  storeId?: Maybe<Scalars['String']>;
};

export type GetGrocerySellerOngoingOrdersInput = {
  sellerStoreId: Scalars['String'];
  status?: Maybe<GroceryOrderStatus>;
};

export type GetGrocerySellerOrdersInput = {
  sellerStoreId: Scalars['String'];
  status?: Maybe<GroceryOrderStatus>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetGroceryStoreByNanoidInput = {
  nanoid: Scalars['String'];
};

export type GetGroceryStoreCartInput = {
  storeId: Scalars['String'];
};

export type GetGroceryStoreInput = {
  storeId: Scalars['String'];
};

export type GetGroceryStoresNearbyInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  radiusInMeter: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetGroceryWaitMinutesInput = {
  id: Scalars['String'];
};

export type GetIngredientsInfoInput = {
  bizId: Scalars['ID'];
};

export type GetMenuCardInput = {
  bizId: Scalars['ID'];
};

export type GetMonthAnalyticCsvInput = {
  monthDate: Scalars['String'];
  id: Scalars['String'];
};

export type GetOrderByOrderIdInput = {
  buyerUid: Scalars['String'];
  orderId: Scalars['String'];
};

export type GetOrderByOrderNumberInput = {
  sellerBizId: Scalars['String'];
  orderNumber: Scalars['String'];
};

export type GetPizzaPartsInput = {
  bizId: Scalars['ID'];
};

export type GetRestaurantByNanoidInput = {
  nanoid: Scalars['String'];
};

export type GetRestaurantByPhoneNumberInput = {
  phoneNumber: Scalars['String'];
};

export type GetRestaurantInput = {
  id: Scalars['String'];
};

export type GetRestaurantsByOwnerInput = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetRestaurantsByStateCityInput = {
  state: Scalars['String'];
  city: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetRestaurantsByZipcodeInput = {
  zipcode: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetRestaurantsNearbyInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  radiusInMeter: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetRestaurantsNearbyOutput = {
  __typename?: 'GetRestaurantsNearbyOutput';
  id: Scalars['ID'];
  distanceInMeter: Scalars['Int'];
};

export type GetRestaurantTextBroadcastsInput = {
  bizId: Scalars['ID'];
};

export type GetRewardItemsInput = {
  bizId: Scalars['ID'];
  status?: Maybe<DishStatus>;
};

export type GetRewardPointsForConsumerInput = {
  bizId?: Maybe<Scalars['String']>;
};

export type GetSellerOngoingOrdersInput = {
  sellerBizId: Scalars['String'];
  status?: Maybe<OrderStatus>;
};

export type GetSellerOrdersByCouponIdInput = {
  sellerBizId: Scalars['ID'];
  couponId: Scalars['ID'];
};

export type GetSellerOrdersInput = {
  sellerBizId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type GetSingleWebsiteBlogDataForBiz = {
  bizId: Scalars['ID'];
  blogId: Scalars['ID'];
};

export type GetStoreCardInput = {
  bizId: Scalars['ID'];
};

export type GetStripeConnectUrlForGroceryStoreInput = {
  storeId: Scalars['ID'];
};

export type GetStripeConnectUrlInput = {
  bizId: Scalars['ID'];
};

export type GetSubCategoriesByCategoryIdInput = {
  categoryId: Scalars['String'];
};

export type GetTableInput = {
  bizId: Scalars['ID'];
  tableName: Scalars['String'];
};

export type GetTableServicesInput = {
  bizId: Scalars['ID'];
};

export type GetTablesInput = {
  bizId: Scalars['ID'];
};

export type GetTextBroadcastInput = {
  bizId: Scalars['ID'];
  id: Scalars['ID'];
};

export type GetTextHistoryInput = {
  bizId: Scalars['ID'];
};

export type GetWaitMinutesInput = {
  id: Scalars['String'];
};

export type GetWebAndBizDataInput = {
  url: Scalars['String'];
  bizId?: Maybe<Scalars['ID']>;
};

export type GetWebsiteDataForBizInput = {
  bizId: Scalars['ID'];
};

export type GetWebsiteDataInput = {
  url: Scalars['String'];
};

export type GroceryAllPauseDate = {
  __typename?: 'GroceryAllPauseDate';
  takeoutPauseDate?: Maybe<Scalars['String']>;
  deliveryPauseDate?: Maybe<Scalars['String']>;
};

export type GroceryBizEmployee = {
  __typename?: 'GroceryBizEmployee';
  uid: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  userName: Scalars['String'];
  groceryStore: GroceryStore;
};

export type GroceryBizHour = {
  __typename?: 'GroceryBizHour';
  serviceType: GroceryServiceType;
  serviceName: Scalars['String'];
  hours: Array<BizHourSlot>;
};

export type GroceryBizHourInput = {
  serviceType: GroceryServiceType;
  serviceName: Scalars['String'];
  hours: Array<BizHourSlotInput>;
};

export type GroceryBizPlan = {
  __typename?: 'GroceryBizPlan';
  planId: Scalars['String'];
  bizPlanDetails: GroceryBizPlanType;
  planName: Scalars['String'];
};

export type GroceryBizPlanModel = {
  __typename?: 'GroceryBizPlanModel';
  custPctVal: Scalars['Float'];
  custPctValLower: Scalars['Int'];
  custAbsVal: Scalars['Int'];
  bizPctVal: Scalars['Float'];
  bizPctValLower: Scalars['Int'];
  bizAbsVal: Scalars['Int'];
};

export type GroceryBizPlanType = {
  __typename?: 'GroceryBizPlanType';
  TAKEOUT?: Maybe<GroceryBizPlanModel>;
  DELIVERY?: Maybe<GroceryDeliveryPlanModel>;
};

export type GroceryCoupon = {
  __typename?: 'GroceryCoupon';
  storeId: Scalars['ID'];
  code: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  name: Scalars['String'];
  description: Scalars['String'];
  discountType: CouponDiscountType;
  discountValue: Scalars['String'];
  maxDiscount?: Maybe<Scalars['Int']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  subtotalThreshold: Scalars['Int'];
  maxUse?: Maybe<Scalars['Int']>;
  image?: Maybe<Image>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  orderType?: Maybe<Array<Maybe<Scalars['String']>>>;
  dayOfWeek?: Maybe<Array<Maybe<Scalars['Int']>>>;
  useCnt?: Maybe<Scalars['Int']>;
};

export enum GroceryDasherStatus {
  Unassigned = 'unassigned',
  Accepted = 'accepted',
  EnrouteToPickup = 'enroute_to_pickup',
  ArrivedAtStore = 'arrived_at_store',
  PickedUp = 'picked_up',
  EnrouteToDropoff = 'enroute_to_dropoff',
  ArrivedAtConsumer = 'arrived_at_consumer',
  DroppedOff = 'dropped_off',
  EnrouteToReturn = 'enroute_to_return',
  ArrivedAtReturn = 'arrived_at_return',
  PendingReturn = 'pending_return',
  DroppedOffReturn = 'dropped_off_return',
  CouldNotDeliver = 'could_not_deliver'
}

export type GroceryDeliveryEstimate = {
  __typename?: 'GroceryDeliveryEstimate';
  quote_id: Scalars['String'];
  buyerUid: Scalars['String'];
  providerQuoteId?: Maybe<Scalars['String']>;
  sellerStoreId: Scalars['String'];
  pickupAddress: Scalars['String'];
  deliveryAddress: Scalars['String'];
  deliveryMode: GroceryDeliveryModes;
  deliveryProvider: GroceryDeliveryProviders;
  doordashPayload?: Maybe<GroceryDoorDashCostEstimatePayload>;
  createdAt: Scalars['Int'];
};

export type GroceryDeliveryEstimateInput = {
  deliveryMode: GroceryDeliveryModes;
  deliveryProvider: GroceryDeliveryProviders;
  sellerStoreId: Scalars['String'];
  doordashPickupAddress: GroceryDoordashAddressInput;
  doordashDropoffAddress: GroceryDoordashAddressInput;
  doordashOrderValue: Scalars['Int'];
  doordashPromotionId?: Maybe<Scalars['String']>;
};

export type GroceryDeliveryEstimateOutput = {
  __typename?: 'GroceryDeliveryEstimateOutput';
  id: Scalars['ID'];
  isCancelled: Scalars['Boolean'];
  quote_id: Scalars['String'];
  doordashFee?: Maybe<Scalars['String']>;
  doordashCurrency?: Maybe<Scalars['String']>;
  doordashDeliveryTime?: Maybe<Scalars['String']>;
  doordashPickupTime?: Maybe<Scalars['String']>;
  doordashPickupWindowStartTime?: Maybe<Scalars['String']>;
  doordashPickupWindowEndTime?: Maybe<Scalars['String']>;
  doordashDropoffAddress?: Maybe<GroceryDoordashAddress>;
};

export type GroceryDeliveryFee = {
  __typename?: 'GroceryDeliveryFee';
  mile: Scalars['Int'];
  fee: Scalars['Int'];
};

export type GroceryDeliveryFeeInput = {
  mile: Scalars['Int'];
  fee: Scalars['Int'];
};

export enum GroceryDeliveryModes {
  Realtime = 'realtime',
  Scheduled = 'scheduled'
}

export type GroceryDeliveryPlanModel = {
  __typename?: 'GroceryDeliveryPlanModel';
  SELF?: Maybe<GroceryBizPlanModel>;
  DOORDASH_CLASSIC?: Maybe<GroceryBizPlanModel>;
};

export enum GroceryDeliveryProviders {
  Self = 'SELF',
  DoordashClassic = 'DOORDASH_CLASSIC'
}

export type GroceryDeliverySetting = {
  __typename?: 'GroceryDeliverySetting';
  waitMinutes: Scalars['Int'];
  freeDeliveryAmount: Scalars['Int'];
  minDeliveryAmount: Scalars['Int'];
  fees: Array<GroceryDeliveryFee>;
  deliveryRadius: Scalars['Int'];
};

export type GroceryDeliverySettingInput = {
  waitMinutes: Scalars['Int'];
  freeDeliveryAmount: Scalars['Int'];
  minDeliveryAmount: Scalars['Int'];
  fees: Array<GroceryDeliveryFeeInput>;
};

export type GroceryDoordashAddress = {
  __typename?: 'GroceryDoordashAddress';
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  full_address?: Maybe<Scalars['String']>;
};

export type GroceryDoordashAddressInput = {
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  full_address?: Maybe<Scalars['String']>;
};

export type GroceryDoorDashCostEstimatePayload = {
  __typename?: 'GroceryDoorDashCostEstimatePayload';
  id: Scalars['String'];
  fee: Scalars['Int'];
  currency?: Maybe<Scalars['Boolean']>;
  delivery_time?: Maybe<Scalars['String']>;
  pickup_time?: Maybe<Scalars['String']>;
  pickup_window_start_time?: Maybe<Scalars['String']>;
  pickup_window_end_time?: Maybe<Scalars['String']>;
  dropoff_address?: Maybe<GroceryDoordashAddress>;
};

export enum GroceryDoordashDeliveryStatus {
  Scheduled = 'scheduled',
  Assigned = 'assigned',
  EnrouteToPickup = 'enroute_to_pickup',
  ArrivedAtStore = 'arrived_at_store',
  PickedUp = 'picked_up',
  ArrivedAtConsumer = 'arrived_at_consumer',
  Delivered = 'delivered',
  Cancelled = 'cancelled',
  PendingReturn = 'pending_return',
  CouldNotDeliver = 'could_not_deliver'
}

export type GroceryDoordashDeliveryTimeline = {
  __typename?: 'GroceryDoordashDeliveryTimeline';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  title?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reasonComment?: Maybe<Scalars['String']>;
  status?: Maybe<GroceryDoordashDeliveryStatus>;
  eventCategory?: Maybe<GroceryDoordashEventCategory>;
  dasherStatus?: Maybe<GroceryDasherStatus>;
  eventId?: Maybe<Scalars['String']>;
};

export enum GroceryDoordashEventCategory {
  DeliveryCreated = 'delivery_created',
  DasherConfirmed = 'dasher_confirmed',
  DriverBatched = 'driver_batched',
  DasherConfirmedStoreArrival = 'dasher_confirmed_store_arrival',
  DasherPickedUp = 'dasher_picked_up',
  DasherConfirmedConsumerArrival = 'dasher_confirmed_consumer_arrival',
  DasherDroppedOff = 'dasher_dropped_off',
  CouldNotDeliver = 'could_not_deliver',
  DeliveryPendingReturn = 'delivery_pending_return',
  DasherConfirmedReturnArrival = 'dasher_confirmed_return_arrival',
  DasherDroppedOffReturn = 'dasher_dropped_off_return',
  DeliveryCancelled = 'delivery_cancelled',
  DeliveryAttempted = 'delivery_attempted',
  DasherSuppliedParkingDetails = 'dasher_supplied_parking_details',
  DeliveryPostTipReceived = 'delivery_post_tip_received',
  DasherEnrouteToPickup = 'dasher_enroute_to_pickup',
  DasherEnrouteToDropoff = 'dasher_enroute_to_dropoff',
  DasherEnrouteToReturn = 'dasher_enroute_to_return',
  DeliveryRefunded = 'delivery_refunded'
}

export type GroceryFee = {
  __typename?: 'GroceryFee';
  amount: Scalars['Int'];
  threshold?: Maybe<Scalars['Int']>;
};

export type GroceryFeeInput = {
  amount: Scalars['Int'];
  threshold?: Maybe<Scalars['Int']>;
};

export type GroceryItemImageCount = {
  __typename?: 'GroceryItemImageCount';
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  upcCode: Scalars['String'];
  count: Scalars['Int'];
};

export type GroceryOrder = {
  __typename?: 'GroceryOrder';
  id: Scalars['ID'];
  requestId: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  orderNumber: Scalars['String'];
  type: GroceryOrderType;
  status: GroceryOrderStatus;
  buyer: LokobeeUser;
  seller: GroceryStore;
  items?: Maybe<Array<GroceryOrderLineItem>>;
  note?: Maybe<Scalars['String']>;
  expectTime?: Maybe<Scalars['Timestamp']>;
  bizExpectTime?: Maybe<Scalars['Timestamp']>;
  deliveryAddress?: Maybe<Address>;
  deliveryDistance?: Maybe<Scalars['Float']>;
  deliveryNote?: Maybe<Scalars['String']>;
  coupon?: Maybe<GroceryCoupon>;
  paymentType?: Maybe<PaymentType>;
  stripePaymentType?: Maybe<Scalars['String']>;
  stripeCardWallet?: Maybe<Scalars['String']>;
  totalTax?: Maybe<Scalars['Int']>;
  subTotal?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  tip?: Maybe<Scalars['Int']>;
  lokobeeFee?: Maybe<Scalars['Int']>;
  onlinePaymentFee?: Maybe<Scalars['Int']>;
  deliveryFee?: Maybe<Scalars['Int']>;
  couponDiscount?: Maybe<Scalars['Int']>;
  subTotalAfterCoupon?: Maybe<Scalars['Int']>;
  adjustAmount?: Maybe<Scalars['Int']>;
  adjustReason?: Maybe<Scalars['String']>;
  totalAfterAdjust?: Maybe<Scalars['Int']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  orderEmail?: Maybe<Scalars['String']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
  isRated?: Maybe<Scalars['Boolean']>;
  timeLines?: Maybe<Array<Maybe<GroceryOrderTimeLine>>>;
  redeemPoints?: Maybe<Scalars['Int']>;
  deliveryProvider?: Maybe<GroceryDeliveryProviders>;
  deliveryMode?: Maybe<GroceryDeliveryModes>;
  doordashQuoteId?: Maybe<Scalars['String']>;
  packingWaitMins?: Maybe<Scalars['Int']>;
  doordashDeliveryStatus?: Maybe<GroceryDoordashDeliveryStatus>;
  doordashDasherStatus?: Maybe<GroceryDasherStatus>;
  deliveryTrackingId?: Maybe<Scalars['String']>;
  doordashDeliveryTimeline?: Maybe<Array<Maybe<GroceryDoordashDeliveryTimeline>>>;
};

export type GroceryOrderConnection = {
  __typename?: 'GroceryOrderConnection';
  edges?: Maybe<Array<GroceryOrderConnectionEdge>>;
  pageInfo: PageInfo;
};

export type GroceryOrderConnectionEdge = {
  __typename?: 'GroceryOrderConnectionEdge';
  node: GroceryOrder;
  cursor: Scalars['String'];
};

export type GroceryOrderLineItem = {
  __typename?: 'GroceryOrderLineItem';
  itemId: Scalars['ID'];
  title: Scalars['String'];
  upcCode?: Maybe<Scalars['String']>;
  size: Scalars['String'];
  categoryId: Scalars['String'];
  subCategoryId?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  price: Scalars['Int'];
  images?: Maybe<Array<MultiSizeImage>>;
  status?: Maybe<GroceryStoreItemStatus>;
  description?: Maybe<Scalars['String']>;
  taxMode?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  isReward?: Maybe<Scalars['Boolean']>;
  rewardPoints?: Maybe<Scalars['Int']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
};

export type GroceryOrderLineItemInput = {
  itemId: Scalars['ID'];
  categoryId: Scalars['String'];
  count: Scalars['Int'];
  isReward?: Maybe<Scalars['Boolean']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
};

export type GroceryOrderPartialRefundInput = {
  sellerStoreId: Scalars['ID'];
  orderNumber: Scalars['ID'];
  adjustAmount: Scalars['Int'];
  adjustReason?: Maybe<Scalars['String']>;
};

export type GroceryOrderPauseDateInput = {
  id: Scalars['String'];
  pauseData?: Maybe<Array<GroceryPauseInput>>;
};

export enum GroceryOrderStatus {
  Created = 'CREATED',
  Placed = 'PLACED',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Ready = 'READY',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type GroceryOrderTimeLine = {
  __typename?: 'GroceryOrderTimeLine';
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  status: GroceryOrderStatus;
  title: Scalars['String'];
  msg: Scalars['String'];
};

export enum GroceryOrderType {
  Takeout = 'TAKEOUT',
  Delivery = 'DELIVERY'
}

export type GroceryPauseInput = {
  type: GroceryOrderType;
  status: OrderPauseStatus;
  orderPauseDate?: Maybe<Scalars['String']>;
};

export type GroceryPaymentSetting = {
  __typename?: 'GroceryPaymentSetting';
  onlinePaymentTypes?: Maybe<Array<PaymentType>>;
  onsitePaymentTypes?: Maybe<Array<PaymentType>>;
  serviceFee?: Maybe<Array<GroceryFee>>;
  tipPercentOptions?: Maybe<Array<Scalars['Int']>>;
  tipDefaultPercent?: Maybe<Scalars['Int']>;
};

export type GroceryPaymentSettingInput = {
  onlinePaymentTypes?: Maybe<Array<PaymentType>>;
  onsitePaymentTypes?: Maybe<Array<PaymentType>>;
  serviceFee?: Maybe<Array<GroceryFeeInput>>;
  tipPercentOptions?: Maybe<Array<Scalars['Int']>>;
  tipDefaultPercent?: Maybe<Scalars['Int']>;
};

export enum GroceryRewardPointType {
  StoreReward = 'STORE_REWARD',
  LokobeeReward = 'LOKOBEE_REWARD'
}

export type GroceryRewardSetting = {
  __typename?: 'GroceryRewardSetting';
  minStartPoints: Scalars['Int'];
  maxRedeemPointsPerUsage: Scalars['Int'];
  minRedeemPurchase: Scalars['Int'];
  pricePointRatio: Scalars['Int'];
};

export type GroceryRewardSettingInput = {
  minStartPoints: Scalars['Int'];
  maxRedeemPointsPerUsage: Scalars['Int'];
  minRedeemPurchase: Scalars['Int'];
  pricePointRatio: Scalars['Int'];
};

export type GrocerySearchInput = {
  storeId: Scalars['String'];
  query: Scalars['String'];
  itemCount?: Maybe<Scalars['Int']>;
};

export enum GroceryServiceType {
  GroceryTakeout = 'GROCERY_TAKEOUT',
  GroceryDelivery = 'GROCERY_DELIVERY'
}

export type GroceryStore = {
  __typename?: 'GroceryStore';
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  owner?: Maybe<LokobeeUser>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<GroceryStoreCategory>>;
  logo?: Maybe<Image>;
  address?: Maybe<Address>;
  timezone?: Maybe<Scalars['String']>;
  taxModes?: Maybe<Array<TaxMode>>;
  status?: Maybe<GroceryStoreStatus>;
  bizHours?: Maybe<Array<GroceryBizHour>>;
  enableTakeoutOrder: Scalars['Boolean'];
  enableDeliveryOrder: Scalars['Boolean'];
  takeoutPaymentSetting?: Maybe<GroceryPaymentSetting>;
  deliveryPaymentSetting?: Maybe<GroceryPaymentSetting>;
  deliverySetting?: Maybe<GroceryDeliverySetting>;
  takeoutWaitMinutes?: Maybe<Scalars['Int']>;
  hasStripeAccount?: Maybe<Scalars['Boolean']>;
  stripeAccount?: Maybe<Scalars['String']>;
  lokobeeFee: Scalars['Boolean'];
  lokobeeFeePercent?: Maybe<Scalars['Float']>;
  lokobeeFeeLimit?: Maybe<Scalars['Int']>;
  coupons?: Maybe<Array<GroceryCoupon>>;
  staff?: Maybe<Array<GroceryBizEmployee>>;
  nanoid?: Maybe<Scalars['String']>;
  announcement?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  orderEmails?: Maybe<Array<Scalars['String']>>;
  reportEmails?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Scalars['String']>;
  googleReviewLink?: Maybe<Scalars['String']>;
  gallery?: Maybe<Array<MultiSizeImage>>;
  issueRewardPoint?: Maybe<Scalars['Boolean']>;
  rewardSetting?: Maybe<GroceryRewardSetting>;
  deliveryProvider?: Maybe<GroceryDeliveryProviders>;
  deliveryMode?: Maybe<GroceryDeliveryModes>;
  packingWaitMins?: Maybe<Scalars['Int']>;
  bizPlan?: Maybe<GroceryBizPlan>;
};

export type GroceryStoreCart = {
  __typename?: 'GroceryStoreCart';
  buyer: LokobeeUser;
  items?: Maybe<Array<GroceryStoreCartItem>>;
  rewardItems?: Maybe<Array<GroceryStoreCartItem>>;
  redeemedPoints?: Maybe<Scalars['Int']>;
};

export type GroceryStoreCartItem = {
  __typename?: 'GroceryStoreCartItem';
  item: GroceryStoreItem;
  quantity: Scalars['Int'];
  isReward?: Maybe<Scalars['Boolean']>;
};

export type GroceryStoreCartItemInput = {
  categoryId: Scalars['String'];
  itemId: Scalars['String'];
  quantity: Scalars['Int'];
  isReward?: Maybe<Scalars['Boolean']>;
  rewardPoints?: Maybe<Scalars['Int']>;
};

export type GroceryStoreCategory = {
  __typename?: 'GroceryStoreCategory';
  id: Scalars['ID'];
  storeId: Scalars['String'];
  title: Scalars['String'];
  image?: Maybe<MultiSizeImage>;
  subCategories?: Maybe<Array<GroceryStoreSubCategory>>;
  status?: Maybe<GroceryStoreCategoryStatus>;
};

export type GroceryStoreCategoryInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<GroceryStoreSubCategoryInput>>;
  items?: Maybe<Array<GroceryStoreItemInput>>;
  status?: Maybe<GroceryStoreCategoryStatus>;
};

export enum GroceryStoreCategoryStatus {
  Active = 'ACTIVE',
  Hidden = 'HIDDEN',
  Deleted = 'DELETED'
}

export type GroceryStoreCategoryWithItems = {
  __typename?: 'GroceryStoreCategoryWithItems';
  id: Scalars['ID'];
  storeId: Scalars['String'];
  title: Scalars['String'];
  image?: Maybe<MultiSizeImage>;
  subCategories?: Maybe<Array<GroceryStoreSubCategory>>;
  status?: Maybe<GroceryStoreCategoryStatus>;
  items?: Maybe<Array<GroceryStoreItem>>;
};

export type GroceryStoreConnection = {
  __typename?: 'GroceryStoreConnection';
  edges?: Maybe<Array<GroceryStoreConnectionEdge>>;
  pageInfo: PageInfo;
};

export type GroceryStoreConnectionEdge = {
  __typename?: 'GroceryStoreConnectionEdge';
  node: GroceryStore;
  cursor: Scalars['String'];
  distance?: Maybe<Scalars['Float']>;
};

export type GroceryStoreItem = {
  __typename?: 'GroceryStoreItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  upcCode?: Maybe<Scalars['String']>;
  size: Scalars['String'];
  categoryId: Scalars['String'];
  subCategoryId?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  price: Scalars['Int'];
  images?: Maybe<Array<MultiSizeImage>>;
  status?: Maybe<GroceryStoreItemStatus>;
  description?: Maybe<Scalars['String']>;
  taxMode?: Maybe<Scalars['String']>;
  isReward?: Maybe<Scalars['Boolean']>;
  rewardPoints?: Maybe<Scalars['Int']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
};

export type GroceryStoreItemConnection = {
  __typename?: 'GroceryStoreItemConnection';
  edges?: Maybe<Array<GroceryStoreItemConnectionEdge>>;
  pageInfo: PageInfo;
};

export type GroceryStoreItemConnectionEdge = {
  __typename?: 'GroceryStoreItemConnectionEdge';
  node: GroceryStoreItem;
  cursor: Scalars['String'];
};

export type GroceryStoreItemInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  upcCode?: Maybe<Scalars['String']>;
  size: Scalars['String'];
  brand: Scalars['String'];
  price: Scalars['Int'];
  status?: Maybe<GroceryStoreItemStatus>;
  description?: Maybe<Scalars['String']>;
  taxMode?: Maybe<Scalars['String']>;
  isReward?: Maybe<Scalars['Boolean']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
};

export enum GroceryStoreItemStatus {
  Active = 'ACTIVE',
  Hidden = 'HIDDEN',
  Deleted = 'DELETED'
}

export enum GroceryStoreStatus {
  LokobeeAdminDisabled = 'LOKOBEE_ADMIN_DISABLED',
  Deleted = 'DELETED',
  Hidden = 'HIDDEN',
  OnVacation = 'ON_VACATION',
  Active = 'ACTIVE',
  UnderMaintenance = 'UNDER_MAINTENANCE',
  UnderConstruction = 'UNDER_CONSTRUCTION'
}

export type GroceryStoreSubCategory = {
  __typename?: 'GroceryStoreSubCategory';
  id: Scalars['ID'];
  title: Scalars['String'];
  status?: Maybe<GroceryStoreCategoryStatus>;
};

export type GroceryStoreSubCategoryInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  items?: Maybe<Array<GroceryStoreItemInput>>;
  status?: Maybe<GroceryStoreCategoryStatus>;
};

export type GroceryWaitMinutes = {
  __typename?: 'GroceryWaitMinutes';
  takeoutWaitMinutes?: Maybe<Scalars['Int']>;
  deliveryWaitMinutes?: Maybe<Scalars['Int']>;
  packingWaitMins?: Maybe<Scalars['Int']>;
};

export type I18NSetting = {
  __typename?: 'I18NSetting';
  langs: Array<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
  timezone?: Maybe<Scalars['String']>;
};

export type I18NSettingInput = {
  langs: Array<LanguageCode>;
  defaultLang: LanguageCode;
  timezone: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  id?: Maybe<Scalars['ID']>;
  usage?: Maybe<ImageUsage>;
  url?: Maybe<Scalars['String']>;
};

export enum ImageUsage {
  UserAvatar = 'USER_AVATAR',
  BizLogo = 'BIZ_LOGO',
  RestaurantImg = 'RESTAURANT_IMG',
  DishImg = 'DISH_IMG',
  TableCard = 'TABLE_CARD',
  StoreCard = 'STORE_CARD',
  Receipt = 'RECEIPT',
  Website = 'WEBSITE',
  MenuCard = 'MENU_CARD',
  BizCard = 'BIZ_CARD',
  DefaultImage = 'DEFAULT_IMAGE',
  BizCardBackSide = 'BIZ_CARD_BACK_SIDE',
  GroceryStoreLogo = 'GROCERY_STORE_LOGO',
  GroceryStoreItemImg = 'GROCERY_STORE_ITEM_IMG',
  GroceryStoreCategoryImage = 'GROCERY_STORE_CATEGORY_IMAGE',
  GroceryStoreGallery = 'GROCERY_STORE_GALLERY'
}

export type IncreaseOrderExpectedTimeInput = {
  sellerBizId: Scalars['ID'];
  buyerUid: Scalars['ID'];
  orderId: Scalars['ID'];
  delayExpectedMinBy: Scalars['Int'];
};

export enum LanguageCode {
  En = 'en',
  ZhHans = 'zh_Hans',
  ZhHant = 'zh_Hant',
  Fr = 'fr',
  It = 'it',
  Ja = 'ja',
  Ko = 'ko',
  Hi = 'hi'
}

export type LoginBizEmployeeInput = {
  userName: Scalars['String'];
  password: Scalars['String'];
  bizId: Scalars['String'];
};

export type LoginBizEmployeeOutput = {
  __typename?: 'LoginBizEmployeeOutput';
  employee: BizEmployee;
  token: Scalars['String'];
};

export type LoginGroceryBizEmployeeInput = {
  userName: Scalars['String'];
  password: Scalars['String'];
  storeId: Scalars['String'];
};

export type LoginGroceryBizEmployeeOutput = {
  __typename?: 'LoginGroceryBizEmployeeOutput';
  employee: GroceryBizEmployee;
  token: Scalars['String'];
};

export type LokobeePartnerWebsite = {
  __typename?: 'LokobeePartnerWebsite';
  bizName?: Maybe<Array<Maybe<MultiLangString>>>;
  subName?: Maybe<Array<Maybe<MultiLangString>>>;
  logo?: Maybe<Image>;
  url?: Maybe<Scalars['String']>;
  bizId?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};


export type LokobeePartnerWebsiteBizNameArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type LokobeePartnerWebsiteSubNameArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};

export enum LokobeePlan {
  Basic = 'BASIC',
  Premium = 'PREMIUM'
}

export type LokobeeUser = {
  __typename?: 'LokobeeUser';
  uid: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  google?: Maybe<UserProfile>;
  facebook?: Maybe<UserProfile>;
  metadata?: Maybe<UserMetadata>;
  stripeChargeSources?: Maybe<Array<ChargeSource>>;
  address?: Maybe<Address>;
  emailForDeals?: Maybe<Scalars['Boolean']>;
  smsForDeals?: Maybe<Scalars['Boolean']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  orderEmail?: Maybe<Scalars['String']>;
};

export type MenuCard = {
  __typename?: 'MenuCard';
  id: Scalars['String'];
  cardImg?: Maybe<Image>;
};

export type MoneyAmount = {
  __typename?: 'MoneyAmount';
  currencyCode: CurrencyCode;
  intValue: Scalars['Int'];
  shift: Scalars['Int'];
};

export type MoneyAmountInput = {
  currencyCode: CurrencyCode;
  strValue: Scalars['String'];
};

export type MultiLangString = {
  __typename?: 'MultiLangString';
  lang: LanguageCode;
  text: Scalars['String'];
};

export type MultiLangStringInput = {
  lang: LanguageCode;
  text: Scalars['String'];
};

export type MultiSizeImage = {
  __typename?: 'MultiSizeImage';
  id: Scalars['ID'];
  preview: Image;
  md: Image;
  lg: Image;
  xl: Image;
};

export type Mutation = {
  __typename?: 'Mutation';
  createStripeSetupIntent: CreateStripeSetupIntentOutput;
  deleteStripeCard?: Maybe<Scalars['Boolean']>;
  updateUserAddress?: Maybe<Address>;
  updateUserOrderPhoneAndOrderEmail?: Maybe<LokobeeUser>;
  sendVerificationCode?: Maybe<Scalars['Boolean']>;
  checkVerificationCode?: Maybe<Scalars['Boolean']>;
  deleteUserAddress?: Maybe<Scalars['Boolean']>;
  setUserDealOptOut?: Maybe<Scalars['Boolean']>;
  createImageUploadUrl: Image;
  createBackSideImageUploadUrl: Image;
  createGroceryStoreItemImages?: Maybe<Array<Image>>;
  createGroceryStoreCategoryImage: Image;
  createRestaurant: Restaurant;
  updateRestaurant: Restaurant;
  deleteRestaurant: Scalars['Boolean'];
  activateRestaurant: Scalars['Boolean'];
  applyRestaurant: Scalars['Boolean'];
  updateWaitMinutes?: Maybe<WaitMinutes>;
  bizSuspendedOrderStatus?: Maybe<Scalars['Boolean']>;
  createDishCategory: DishCategory;
  updateDishCategory: DishCategory;
  deleteDishCategory: Scalars['Boolean'];
  createDish: Dish;
  updateDish: Dish;
  deleteDish: Scalars['Boolean'];
  updateIngredientsInfo: Scalars['Boolean'];
  updateRestaurantPizzaSizes?: Maybe<Array<PizzaSize>>;
  updateRestaurantPizzaCrusts?: Maybe<Array<PizzaCrust>>;
  updateRestaurantPizzaSauces: Array<Maybe<PizzaToppingGroup>>;
  updateRestaurantPizzaCheeses: Array<Maybe<PizzaToppingGroup>>;
  updateRestaurantPizzaToppings?: Maybe<Array<PizzaToppingGroup>>;
  updateRestaurantPizzaHalfToppingPriceFactor?: Maybe<Scalars['Int']>;
  updateRestaurantPizzaDensityMultipliers?: Maybe<Array<PizzaToppingDensityMultiplier>>;
  updateDishCategoryDisplayOrder: Scalars['Boolean'];
  updateDishDisplayOrder: Scalars['Boolean'];
  restaurantEmployeeUpdateDishStatus?: Maybe<Scalars['Boolean']>;
  bizVacationSet?: Maybe<Scalars['Boolean']>;
  createTable: Table;
  updateTable: Table;
  deleteTable: Scalars['Boolean'];
  createBizEmployee: BizEmployee;
  updateBizEmployee: BizEmployee;
  deleteBizEmployee: Scalars['Boolean'];
  loginBizEmployee: LoginBizEmployeeOutput;
  createOrder: CreateOrderOutput;
  updateOrderStatus: Scalars['Boolean'];
  createTableServiceOrder: Order;
  closeTableServiceOrder: Scalars['Boolean'];
  orderPartialRefund: Scalars['Boolean'];
  increaseOrderExpectedTime: Scalars['Boolean'];
  createCoupon: Coupon;
  deleteCoupon: Scalars['Boolean'];
  createGroceryCoupon: GroceryCoupon;
  deleteGroceryCoupon: Scalars['Boolean'];
  addUserFeedBack: Scalars['Boolean'];
  createReview: Scalars['Boolean'];
  deleteDishReview: Scalars['Boolean'];
  customerCreateOrderFeeBack: OrderFeeBack;
  updateOrderFeeBack: OrderFeeBack;
  closeOrderFeeBack: Scalars['Boolean'];
  deliveryCostEstimateRequest: DeliveryCostEstimateOutput;
  validateDriverOtp?: Maybe<Scalars['Boolean']>;
  phoneVerifications: Scalars['Boolean'];
  verifyPhoneVerificationCode: Scalars['Boolean'];
  createWebsite?: Maybe<WebSiteData>;
  updateWebsite?: Maybe<WebSiteData>;
  deleteSingleWebsiteBlogDataForBiz?: Maybe<Scalars['Boolean']>;
  updateSingleWebsiteBlog?: Maybe<WebSiteData>;
  createGroceryStore: GroceryStore;
  updateGroceryStore: GroceryStore;
  AddToGroceryStoreCart: GroceryStoreCart;
  createGroceryOrder: CreateGroceryOrderOutput;
  updateGroceryOrderStatus: Scalars['Boolean'];
  groceryOrderPartialRefund: Scalars['Boolean'];
  createGroceryBizEmployee: GroceryBizEmployee;
  updateGroceryBizEmployee: GroceryBizEmployee;
  deleteGroceryBizEmployee: Scalars['Boolean'];
  loginGroceryBizEmployee: LoginGroceryBizEmployeeOutput;
  grocerySuspendedOrderStatus?: Maybe<Scalars['Boolean']>;
  updateGroceryDefaultItems?: Maybe<Scalars['Boolean']>;
  updateGroceryWaitMinutes?: Maybe<GroceryWaitMinutes>;
  activateGroceryStore: Scalars['Boolean'];
  groceryDeliveryEstimateRequest: GroceryDeliveryEstimateOutput;
  createTextBroadcast: TextBroadcast;
  deleteTextBroadcast?: Maybe<Scalars['Boolean']>;
  updateTextBroadcast: TextBroadcast;
  addBroadcastPhoneNumbers: Scalars['Boolean'];
  requestTextMarketing?: Maybe<Scalars['Boolean']>;
  updateNextMonthBroadcast: TextBroadcast;
  sendBroadcast?: Maybe<Scalars['Boolean']>;
  createCryptoCoupon: CryptoCoupon;
  applyCryptoCoupon: ApplyCryptoCouponOutput;
  resetCryptoCoupon?: Maybe<Scalars['Boolean']>;
  requestCryptoCouponPromotion?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateStripeSetupIntentArgs = {
  input?: Maybe<CreateStripeSetupIntentInput>;
};


export type MutationDeleteStripeCardArgs = {
  input: DeleteStripeCardInput;
};


export type MutationUpdateUserAddressArgs = {
  input: UpdateUserAddressInput;
};


export type MutationUpdateUserOrderPhoneAndOrderEmailArgs = {
  input: UpdateUserOrderPhoneAndOrderEmailInput;
};


export type MutationSendVerificationCodeArgs = {
  input: SendVerificationCodeInput;
};


export type MutationCheckVerificationCodeArgs = {
  input: CheckVerificationCodeInput;
};


export type MutationSetUserDealOptOutArgs = {
  input: SetUserDealOptOutInput;
};


export type MutationCreateImageUploadUrlArgs = {
  input: CreateImageUploadUrlInput;
};


export type MutationCreateBackSideImageUploadUrlArgs = {
  input: CreateBackSideImageImageUploadUrlInput;
};


export type MutationCreateGroceryStoreItemImagesArgs = {
  input: CreateGroceryStoreItemImagesInput;
};


export type MutationCreateGroceryStoreCategoryImageArgs = {
  input?: Maybe<CreateGroceryStoreCategoryImageInput>;
};


export type MutationCreateRestaurantArgs = {
  input: CreateRestaurantInput;
};


export type MutationUpdateRestaurantArgs = {
  input: UpdateRestaurantInput;
};


export type MutationDeleteRestaurantArgs = {
  input: DeleteRestaurantInput;
};


export type MutationActivateRestaurantArgs = {
  input: ActivateRestaurantInput;
};


export type MutationApplyRestaurantArgs = {
  input: ApplyRestaurantInput;
};


export type MutationUpdateWaitMinutesArgs = {
  input: UpdateWaitMinutesInput;
};


export type MutationBizSuspendedOrderStatusArgs = {
  input: BizOrderPauseDateInput;
};


export type MutationCreateDishCategoryArgs = {
  input: CreateDishCategoryInput;
};


export type MutationUpdateDishCategoryArgs = {
  input: UpdateDishCategoryInput;
};


export type MutationDeleteDishCategoryArgs = {
  input: DeleteDishCategoryInput;
};


export type MutationCreateDishArgs = {
  input: CreateDishInput;
};


export type MutationUpdateDishArgs = {
  input: UpdateDishInput;
};


export type MutationDeleteDishArgs = {
  input?: Maybe<DeleteDishInput>;
};


export type MutationUpdateIngredientsInfoArgs = {
  input?: Maybe<UpdateIngredientsInfoInput>;
};


export type MutationUpdateRestaurantPizzaSizesArgs = {
  input: UpdateRestaurantPizzaSizesInput;
};


export type MutationUpdateRestaurantPizzaCrustsArgs = {
  input: UpdateRestaurantPizzaCrustsInput;
};


export type MutationUpdateRestaurantPizzaSaucesArgs = {
  input: UpdateRestaurantPizzaSaucesInput;
};


export type MutationUpdateRestaurantPizzaCheesesArgs = {
  input: UpdateRestaurantPizzaCheesesInput;
};


export type MutationUpdateRestaurantPizzaToppingsArgs = {
  input: UpdateRestaurantPizzaToppingsInput;
};


export type MutationUpdateRestaurantPizzaHalfToppingPriceFactorArgs = {
  input: UpdateRestaurantPizzaHalfToppingPriceFactorInput;
};


export type MutationUpdateRestaurantPizzaDensityMultipliersArgs = {
  input: UpdateRestaurantPizzaDensityMultipliersInput;
};


export type MutationUpdateDishCategoryDisplayOrderArgs = {
  input: UpdateDishCategoryDisplayOrderInput;
};


export type MutationUpdateDishDisplayOrderArgs = {
  input: UpdateDishDisplayOrderInput;
};


export type MutationRestaurantEmployeeUpdateDishStatusArgs = {
  input: EmployeeUpdateDishStatusInput;
};


export type MutationBizVacationSetArgs = {
  input: VacationDataInput;
};


export type MutationCreateTableArgs = {
  input: CreateTableInput;
};


export type MutationUpdateTableArgs = {
  input: UpdateTableInput;
};


export type MutationDeleteTableArgs = {
  input: DeleteTableInput;
};


export type MutationCreateBizEmployeeArgs = {
  input: CreateBizEmployeeInput;
};


export type MutationUpdateBizEmployeeArgs = {
  input: UpdateBizEmployeeInput;
};


export type MutationDeleteBizEmployeeArgs = {
  input: DeleteBizEmployeeInput;
};


export type MutationLoginBizEmployeeArgs = {
  input: LoginBizEmployeeInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationUpdateOrderStatusArgs = {
  input: UpdateOrderStatusInput;
};


export type MutationCreateTableServiceOrderArgs = {
  input: CreateTableServiceOrderInput;
};


export type MutationCloseTableServiceOrderArgs = {
  input: CloseTableServiceOrderInput;
};


export type MutationOrderPartialRefundArgs = {
  input: OrderPartialRefundInput;
};


export type MutationIncreaseOrderExpectedTimeArgs = {
  input: IncreaseOrderExpectedTimeInput;
};


export type MutationCreateCouponArgs = {
  input: CreateCouponInput;
};


export type MutationDeleteCouponArgs = {
  input: DeleteCouponInput;
};


export type MutationCreateGroceryCouponArgs = {
  input: CreateGroceryCouponInput;
};


export type MutationDeleteGroceryCouponArgs = {
  input: DeleteGroceryCouponInput;
};


export type MutationAddUserFeedBackArgs = {
  input: AddUserFeedBackInput;
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};


export type MutationDeleteDishReviewArgs = {
  input: DeleteDishReviewInput;
};


export type MutationCustomerCreateOrderFeeBackArgs = {
  input?: Maybe<CustomerCreateFeeBackInput>;
};


export type MutationUpdateOrderFeeBackArgs = {
  input?: Maybe<UpdateFeeBackInput>;
};


export type MutationCloseOrderFeeBackArgs = {
  input?: Maybe<CloseFeeBackInput>;
};


export type MutationDeliveryCostEstimateRequestArgs = {
  input: DeliveryCostEstimateRequestInput;
};


export type MutationValidateDriverOtpArgs = {
  input: ValidateDriverOtpInput;
};


export type MutationPhoneVerificationsArgs = {
  input: PhoneVerificationsInput;
};


export type MutationVerifyPhoneVerificationCodeArgs = {
  input: VerifyPhoneVerificationCodeInput;
};


export type MutationCreateWebsiteArgs = {
  input: CreateWebsiteContentInput;
};


export type MutationUpdateWebsiteArgs = {
  input: UpdateWebsiteContentInput;
};


export type MutationDeleteSingleWebsiteBlogDataForBizArgs = {
  input: DeleteSingleWebsiteBlogDataForBizInput;
};


export type MutationUpdateSingleWebsiteBlogArgs = {
  input: UpdateSingleWebsiteBlogInput;
};


export type MutationCreateGroceryStoreArgs = {
  input: CreateGroceryStoreInput;
};


export type MutationUpdateGroceryStoreArgs = {
  input: UpdateGroceryStoreInput;
};


export type MutationAddToGroceryStoreCartArgs = {
  input?: Maybe<AddToGroceryStoreCartInput>;
};


export type MutationCreateGroceryOrderArgs = {
  input: CreateGroceryOrderInput;
};


export type MutationUpdateGroceryOrderStatusArgs = {
  input: UpdateGroceryOrderStatusInput;
};


export type MutationGroceryOrderPartialRefundArgs = {
  input: GroceryOrderPartialRefundInput;
};


export type MutationCreateGroceryBizEmployeeArgs = {
  input: CreateGroceryBizEmployeeInput;
};


export type MutationUpdateGroceryBizEmployeeArgs = {
  input: UpdateGroceryBizEmployeeInput;
};


export type MutationDeleteGroceryBizEmployeeArgs = {
  input: DeleteGroceryBizEmployeeInput;
};


export type MutationLoginGroceryBizEmployeeArgs = {
  input: LoginGroceryBizEmployeeInput;
};


export type MutationGrocerySuspendedOrderStatusArgs = {
  input: GroceryOrderPauseDateInput;
};


export type MutationUpdateGroceryDefaultItemsArgs = {
  input?: Maybe<UpdateGroceryDefaultItemsInput>;
};


export type MutationUpdateGroceryWaitMinutesArgs = {
  input: UpdateGroceryWaitMinutesInput;
};


export type MutationActivateGroceryStoreArgs = {
  input: ActivateGroceryStoreInput;
};


export type MutationGroceryDeliveryEstimateRequestArgs = {
  input: GroceryDeliveryEstimateInput;
};


export type MutationCreateTextBroadcastArgs = {
  input: CreateTextBroadcastInput;
};


export type MutationDeleteTextBroadcastArgs = {
  input?: Maybe<DeleteTextBroadcastInput>;
};


export type MutationUpdateTextBroadcastArgs = {
  input: UpdateTextBroadcastInput;
};


export type MutationAddBroadcastPhoneNumbersArgs = {
  input?: Maybe<AddBroadcastPhoneNumbersInput>;
};


export type MutationRequestTextMarketingArgs = {
  input?: Maybe<RequestTextMarketingInput>;
};


export type MutationUpdateNextMonthBroadcastArgs = {
  input?: Maybe<UpdateNextMonthBroadcastInput>;
};


export type MutationSendBroadcastArgs = {
  input?: Maybe<SendBroadcastInput>;
};


export type MutationCreateCryptoCouponArgs = {
  input: CreateCryptoCouponInput;
};


export type MutationApplyCryptoCouponArgs = {
  input: ApplyCryptoCouponInput;
};


export type MutationResetCryptoCouponArgs = {
  input: ResetCryptoCouponInput;
};


export type MutationRequestCryptoCouponPromotionArgs = {
  input: RequestCryptoCouponPromotionInput;
};

export type OpsSetting = {
  __typename?: 'OpsSetting';
  disableTakeoutOrderDetailDisplay?: Maybe<Scalars['Boolean']>;
  disableDineinOrderDetailDisplay?: Maybe<Scalars['Boolean']>;
  disableDineoutOrderDetailDisplay?: Maybe<Scalars['Boolean']>;
  disableDeliveryOrderDetailDisplay?: Maybe<Scalars['Boolean']>;
};

export type OpsSettingInput = {
  disableTakeoutOrderDetailDisplay?: Maybe<Scalars['Boolean']>;
  disableDineinOrderDetailDisplay?: Maybe<Scalars['Boolean']>;
  disableDineoutOrderDetailDisplay?: Maybe<Scalars['Boolean']>;
  disableDeliveryOrderDetailDisplay?: Maybe<Scalars['Boolean']>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  requestId: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  orderNumber: Scalars['String'];
  type: OrderType;
  status: OrderStatus;
  deliveryStatusBoons?: Maybe<DeliveryStatusBoons>;
  deliveryStatusDoordashClassic?: Maybe<DeliveryStatusDoordashClassic>;
  buyer: LokobeeUser;
  cookingTimeMins?: Maybe<Scalars['Int']>;
  noOfBags?: Maybe<Scalars['Int']>;
  specialHandlingInstruction?: Maybe<Scalars['String']>;
  dropOffInstruction?: Maybe<Scalars['String']>;
  seller: Restaurant;
  items?: Maybe<Array<OrderLineItem>>;
  note?: Maybe<Scalars['String']>;
  timeLines?: Maybe<Array<Maybe<OrderTimeLine>>>;
  deliveryTimelineBoons?: Maybe<Array<Maybe<DeliveryOrderBoonsTimeLine>>>;
  deliveryTimelineDoordashClassic?: Maybe<Array<Maybe<DeliveryOrderDoordashClassicTimeLine>>>;
  costEstimate?: Maybe<DeliveryCostEstimateDdb>;
  expectTime?: Maybe<Scalars['Timestamp']>;
  bizExpectTime?: Maybe<Scalars['Timestamp']>;
  deliveryAddress?: Maybe<Address>;
  deliveryDistance?: Maybe<Scalars['Float']>;
  paymentType?: Maybe<PaymentType>;
  taxRate?: Maybe<TaxRate>;
  subTotal?: Maybe<MoneyAmount>;
  tax?: Maybe<MoneyAmount>;
  total?: Maybe<MoneyAmount>;
  tip?: Maybe<MoneyAmount>;
  lokobeeFee?: Maybe<MoneyAmount>;
  onlinePaymentFee?: Maybe<MoneyAmount>;
  serviceFee?: Maybe<MoneyAmount>;
  deliveryFee?: Maybe<MoneyAmount>;
  tableName?: Maybe<Scalars['String']>;
  partySize?: Maybe<Scalars['Int']>;
  deliveryNote?: Maybe<Scalars['String']>;
  couponDiscount?: Maybe<MoneyAmount>;
  couponId?: Maybe<Scalars['String']>;
  couponCode?: Maybe<Scalars['String']>;
  subTotalAfterCoupon?: Maybe<MoneyAmount>;
  redeemPoints?: Maybe<Scalars['Int']>;
  adjustAmount?: Maybe<MoneyAmount>;
  adjustReason?: Maybe<Scalars['String']>;
  totalAfterAdjust?: Maybe<MoneyAmount>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  orderEmail?: Maybe<Scalars['String']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
  boonsDeliveryFeeTotal?: Maybe<MoneyAmount>;
  deliveryProvider?: Maybe<DeliveryProviders>;
  deliveryMode?: Maybe<DeliveryModes>;
  deliveryRequest?: Maybe<DeliveryRequest>;
  isRated?: Maybe<Scalars['Boolean']>;
  stripePaymentType?: Maybe<Scalars['String']>;
  stripeCardWallet?: Maybe<Scalars['String']>;
  deliveryWaitMinutes?: Maybe<Scalars['Int']>;
  doordashClassicQuoteId?: Maybe<Scalars['String']>;
  deliveryDasherDoordashClassic?: Maybe<DeliveryDasherDoordashClassic>;
  deliveryDasherStatusDoordashClassic?: Maybe<DeliveryDasherStatusDoordashClassic>;
  custDisplayName?: Maybe<Scalars['String']>;
  phoneOrder?: Maybe<Scalars['Boolean']>;
  workedOn?: Maybe<Scalars['String']>;
  coupon?: Maybe<CryptoCouponInfo>;
  sessionId?: Maybe<Scalars['String']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges?: Maybe<Array<OrderConnectionEdge>>;
  pageInfo: PageInfo;
};

export type OrderConnectionEdge = {
  __typename?: 'OrderConnectionEdge';
  node: Order;
  cursor: Scalars['String'];
};

export type OrderDishExtraItem = {
  __typename?: 'OrderDishExtraItem';
  dishExtraGroupId: Scalars['ID'];
  dishExtraGroupTitle?: Maybe<Array<MultiLangString>>;
  dishExtraItem: DishExtraItem;
  count?: Maybe<Scalars['Int']>;
};


export type OrderDishExtraItemDishExtraGroupTitleArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};

export type OrderDishExtraItemInput = {
  dishExtraGroupId: Scalars['ID'];
  dishExtraItemId: Scalars['ID'];
  count: Scalars['Int'];
};

export type OrderFeeBack = {
  __typename?: 'OrderFeeBack';
  seller: Restaurant;
  order: Order;
  user: LokobeeUser;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
  isOpen?: Maybe<Scalars['Boolean']>;
  rateScore?: Maybe<Scalars['Float']>;
};

export type OrderFeeBackConnection = {
  __typename?: 'OrderFeeBackConnection';
  edges?: Maybe<Array<OrderFeeBackConnectionEdge>>;
  pageInfo: PageInfo;
};

export type OrderFeeBackConnectionEdge = {
  __typename?: 'OrderFeeBackConnectionEdge';
  node: OrderFeeBack;
  cursor: Scalars['String'];
};

export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  dishId?: Maybe<Scalars['ID']>;
  dishCategoryTitle?: Maybe<Array<Maybe<MultiLangString>>>;
  dishCategoryDescription?: Maybe<Array<Maybe<MultiLangString>>>;
  dishTitle?: Maybe<Array<Maybe<MultiLangString>>>;
  dishDescription?: Maybe<Array<Maybe<MultiLangString>>>;
  dishImages?: Maybe<Array<MultiSizeImage>>;
  count: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  dishType?: Maybe<DishType>;
  dishPrice?: Maybe<DishPrice>;
  dishExtras?: Maybe<Array<OrderDishExtraItem>>;
  pizzaBasePrice?: Maybe<PizzaBasePrice>;
  pizzaCrust?: Maybe<OrderPizzaCrust>;
  pizzaSauces?: Maybe<Array<Maybe<OrderPizzaTopping>>>;
  pizzaCheeses?: Maybe<Array<Maybe<OrderPizzaTopping>>>;
  pizzaToppings?: Maybe<Array<Maybe<OrderPizzaTopping>>>;
  leftDishId?: Maybe<Scalars['ID']>;
  leftDishTitle?: Maybe<Array<Maybe<MultiLangString>>>;
  leftDishDescription?: Maybe<Array<Maybe<MultiLangString>>>;
  leftDishImages?: Maybe<Array<MultiSizeImage>>;
  rightDishId?: Maybe<Scalars['String']>;
  rightDishTitle?: Maybe<Array<Maybe<MultiLangString>>>;
  rightDishDescription?: Maybe<Array<Maybe<MultiLangString>>>;
  rightDishImages?: Maybe<Array<MultiSizeImage>>;
  isReward?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
};


export type OrderLineItemDishCategoryTitleArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type OrderLineItemDishCategoryDescriptionArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type OrderLineItemDishTitleArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type OrderLineItemDishDescriptionArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type OrderLineItemLeftDishTitleArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type OrderLineItemLeftDishDescriptionArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type OrderLineItemRightDishTitleArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type OrderLineItemRightDishDescriptionArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};

export type OrderLineItemInput = {
  dishId: Scalars['ID'];
  dishPriceId?: Maybe<Scalars['ID']>;
  dishExtras?: Maybe<Array<OrderDishExtraItemInput>>;
  count: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  pizzaBasePriceId?: Maybe<Scalars['ID']>;
  pizzaCrustId?: Maybe<Scalars['ID']>;
  pizzaSauces?: Maybe<Array<OrderPizzaToppingInput>>;
  pizzaCheeses?: Maybe<Array<OrderPizzaToppingInput>>;
  pizzaToppings?: Maybe<Array<OrderPizzaToppingInput>>;
  leftDishId?: Maybe<Scalars['ID']>;
  rightDishId?: Maybe<Scalars['ID']>;
  isReward?: Maybe<Scalars['Boolean']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
};

export type OrderPartialRefundInput = {
  sellerBizId: Scalars['ID'];
  orderNumber: Scalars['ID'];
  adjustAmount: Scalars['Int'];
  adjustReason?: Maybe<Scalars['String']>;
};

export enum OrderPauseStatus {
  Pause = 'PAUSE',
  Resume = 'RESUME'
}

export type OrderPizzaCrust = {
  __typename?: 'OrderPizzaCrust';
  crustId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
};

export type OrderPizzaTopping = {
  __typename?: 'OrderPizzaTopping';
  toppingId: Scalars['ID'];
  toppingGroupId: Scalars['ID'];
  toppingGroupTitle?: Maybe<Scalars['String']>;
  side?: Maybe<PizzaToppingSide>;
  toppingTitle?: Maybe<Scalars['String']>;
  toppingDescription?: Maybe<Scalars['String']>;
  toppingDensity?: Maybe<PizzaToppingDensity>;
  toppingPrice?: Maybe<Price>;
  preset?: Maybe<Scalars['Boolean']>;
};

export type OrderPizzaToppingInput = {
  toppingGroupId: Scalars['ID'];
  toppingId: Scalars['ID'];
  side: PizzaToppingSide;
  toppingDensity: PizzaToppingDensity;
  priceId: Scalars['ID'];
};

export enum OrderStatus {
  Created = 'CREATED',
  Placed = 'PLACED',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Ready = 'READY',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type OrderTimeLine = {
  __typename?: 'OrderTimeLine';
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  status: OrderStatus;
  title: Scalars['String'];
  msg: Scalars['String'];
};

export enum OrderTimeLineStatus {
  Placed = 'PLACED',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Ready = 'READY',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export enum OrderTimeLineTitle {
  OrderPlaced = 'Order_Placed',
  OrderAccepted = 'Order_Accepted',
  OrderRejected = 'Order_Rejected',
  OrderReady = 'Order_Ready',
  OrderClosed = 'Order_Closed',
  OrderCancelled = 'Order_Cancelled'
}

export enum OrderType {
  Takeout = 'TAKEOUT',
  Delivery = 'DELIVERY',
  DineIn = 'DINE_IN',
  DineOut = 'DINE_OUT',
  TableService = 'TABLE_SERVICE',
  Dining = 'DINING'
}

export type OwnerGetCryptoCouponsByBizIdInput = {
  bizId: Scalars['ID'];
};

export type OwnerGetGroceryStoreByIdInput = {
  storeId: Scalars['String'];
};

export type OwnerGetRestaurantByIdInput = {
  id: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPrevPage?: Maybe<Scalars['Boolean']>;
};

export type PauseDataInput = {
  type: OrderType;
  status: OrderPauseStatus;
  orderPauseDate?: Maybe<Scalars['String']>;
};

export type PaymentSetting = {
  __typename?: 'PaymentSetting';
  onlinePaymentTypes?: Maybe<Array<PaymentType>>;
  onsitePaymentTypes?: Maybe<Array<PaymentType>>;
  onlinePaymentFee?: Maybe<Fee>;
  serviceFee?: Maybe<Array<Fee>>;
  tipPercentOptions?: Maybe<Array<Scalars['Int']>>;
  tipDefaultPercent?: Maybe<Scalars['Int']>;
};

export type PaymentSettingInput = {
  onlinePaymentTypes?: Maybe<Array<PaymentType>>;
  onsitePaymentTypes?: Maybe<Array<PaymentType>>;
  onlinePaymentFee?: Maybe<FeeInput>;
  serviceFee?: Maybe<Array<FeeInput>>;
  tipPercentOptions?: Maybe<Array<Scalars['Int']>>;
  tipDefaultPercent?: Maybe<Scalars['Int']>;
};

export enum PaymentType {
  OnlineStripe = 'ONLINE_STRIPE',
  Onsite = 'ONSITE',
  OnsiteCash = 'ONSITE_CASH',
  OnsiteVisaCard = 'ONSITE_VISA_CARD',
  OnsiteMasterCard = 'ONSITE_MASTER_CARD',
  OnsiteAmericanExpressCard = 'ONSITE_AMERICAN_EXPRESS_CARD',
  OnsiteDiscoverCard = 'ONSITE_DISCOVER_CARD',
  OnsiteApplePay = 'ONSITE_APPLE_PAY',
  OnsiteGooglePay = 'ONSITE_GOOGLE_PAY'
}

export type PhoneVerificationsInput = {
  phoneNumber: Scalars['String'];
};

export type PizzaBasePrice = {
  __typename?: 'PizzaBasePrice';
  id: Scalars['ID'];
  size: PizzaSize;
  price: Price;
  isDefault: Scalars['Boolean'];
  discountAmount?: Maybe<Scalars['Int']>;
};

export type PizzaBasePriceInput = {
  sizeId: Scalars['ID'];
  price: PriceInput;
  isDefault: Scalars['Boolean'];
  discountAmount?: Maybe<Scalars['Int']>;
};

export type PizzaCrust = {
  __typename?: 'PizzaCrust';
  id: Scalars['ID'];
  partId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sizes: Array<PizzaSize>;
  prices: Array<PizzaCrustPrice>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type PizzaCrustInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  prices: Array<PizzaCrustPriceInput>;
};

export type PizzaCrustPrice = {
  __typename?: 'PizzaCrustPrice';
  size: PizzaSize;
  price: Price;
};

export type PizzaCrustPriceInput = {
  sizeId: Scalars['ID'];
  price: PriceInput;
};

export type PizzaParts = {
  __typename?: 'PizzaParts';
  bizId: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  sizes?: Maybe<Array<PizzaSize>>;
  crusts?: Maybe<Array<PizzaCrust>>;
  sauces?: Maybe<Array<PizzaToppingGroup>>;
  cheeses?: Maybe<Array<PizzaToppingGroup>>;
  toppings?: Maybe<Array<PizzaToppingGroup>>;
  halfToppingPriceFactor?: Maybe<Scalars['Int']>;
  densityMultipliers?: Maybe<Array<Maybe<PizzaToppingDensityMultiplier>>>;
};

export type PizzaSize = {
  __typename?: 'PizzaSize';
  id: Scalars['ID'];
  partId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enableHalfHalf: Scalars['Boolean'];
};

export type PizzaSizeInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enableHalfHalf: Scalars['Boolean'];
};

export enum PizzaToppingDensity {
  None = 'None',
  Light = 'Light',
  Regular = 'Regular',
  Extra = 'Extra',
  Double = 'Double',
  Triple = 'Triple'
}

export type PizzaToppingDensityMultiplier = {
  __typename?: 'PizzaToppingDensityMultiplier';
  density: PizzaToppingDensity;
  multiplier: Scalars['Float'];
};

export type PizzaToppingDensityMultiplierInput = {
  density: PizzaToppingDensity;
  multiplier: Scalars['Float'];
};

export type PizzaToppingDensityPrice = {
  __typename?: 'PizzaToppingDensityPrice';
  density: PizzaToppingDensity;
  price: Price;
  partPrice: Price;
};

export type PizzaToppingDensityPriceInput = {
  density: PizzaToppingDensity;
  price: PriceInput;
};

export type PizzaToppingGroup = {
  __typename?: 'PizzaToppingGroup';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  items: Array<PizzaToppingItem>;
  enableHalfHalf: Scalars['Boolean'];
  type: PizzaToppingType;
  maxSelect: Scalars['Int'];
};

export type PizzaToppingGroupInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  items: Array<PizzaToppingItemInput>;
  enableHalfHalf: Scalars['Boolean'];
};

export type PizzaToppingItem = {
  __typename?: 'PizzaToppingItem';
  id: Scalars['ID'];
  partId: Scalars['ID'];
  groupPartId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  prices: Array<PizzaToppingPrice>;
  preset: Scalars['Boolean'];
};

export type PizzaToppingItemInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  prices: Array<PizzaToppingPriceInput>;
};

export type PizzaToppingPrice = {
  __typename?: 'PizzaToppingPrice';
  id: Scalars['ID'];
  size: PizzaSize;
  densities: Array<PizzaToppingDensity>;
  densityPrices: Array<PizzaToppingDensityPrice>;
};

export type PizzaToppingPriceInput = {
  id?: Maybe<Scalars['ID']>;
  sizeId: Scalars['ID'];
  densityPrices: Array<PizzaToppingDensityPriceInput>;
};

export enum PizzaToppingSide {
  Left = 'Left',
  Right = 'Right',
  Whole = 'Whole'
}

export enum PizzaToppingType {
  Sauce = 'Sauce',
  Cheese = 'Cheese',
  Topping = 'Topping'
}

export type Price = {
  __typename?: 'Price';
  currencyCode: CurrencyCode;
  intValue: Scalars['Int'];
  shift: Scalars['Int'];
};

export type PriceInput = {
  currencyCode: CurrencyCode;
  strValue: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getLokobeeUser?: Maybe<LokobeeUser>;
  getUserOrderPhoneAndOrderEmail?: Maybe<LokobeeUser>;
  getRestaurant: Restaurant;
  ownerGetRestaurantById: Restaurant;
  getRestaurantsByOwner: RestaurantConnection;
  getRestaurantsByLocation: RestaurantConnection;
  getRestaurantsByZipcode: RestaurantConnection;
  getRestaurantsByStateCity: RestaurantConnection;
  getRestaurantByPhoneNumber?: Maybe<Restaurant>;
  getWaitMinutes?: Maybe<WaitMinutes>;
  getRestaurantByNanoid?: Maybe<Restaurant>;
  getBizOrderPauseDate?: Maybe<Scalars['Boolean']>;
  getBizAllPauseDate?: Maybe<BizAllPauseDate>;
  getTable: Table;
  getTables?: Maybe<Array<Table>>;
  downloadTablesCard: DownloadTablesCard;
  getStoreCard: StoreCard;
  getMenuCard: MenuCard;
  getBizCard: BizCard;
  checkBackSideImageExists?: Maybe<Scalars['Boolean']>;
  getDish: Dish;
  getDishes: DishConnection;
  getAllDishes?: Maybe<Array<Dish>>;
  getDishCategoriesOfRestaurant?: Maybe<Array<DishCategory>>;
  getDishCategory?: Maybe<DishCategory>;
  getTableServices?: Maybe<DishCategory>;
  getPizzaParts?: Maybe<PizzaParts>;
  getDishCategoryDisplayOrder?: Maybe<CategoryDisplayOrder>;
  getDishDisplayOrder?: Maybe<Array<DishDisplayOrder>>;
  getRewardItems?: Maybe<Array<Dish>>;
  getIngredientsInfo?: Maybe<Array<DishIngredient>>;
  getRewardPointsForConsumer?: Maybe<Array<ConsumerRewardPoint>>;
  getBizEmployee: BizEmployee;
  getBizEmployees?: Maybe<Array<BizEmployee>>;
  getStripeConnectUrl: Scalars['String'];
  getStripeConnectUrlForGrocery: Scalars['String'];
  getOrderByOrderNumber?: Maybe<Order>;
  getOrderByOrderId?: Maybe<Order>;
  getBuyerOrders?: Maybe<OrderConnection>;
  getBuyerOngoingOrders?: Maybe<Array<Maybe<Order>>>;
  getSellerOrders?: Maybe<OrderConnection>;
  getSellerOngoingOrders?: Maybe<Array<Maybe<Order>>>;
  getSellerOrdersByCouponId?: Maybe<Array<Maybe<Order>>>;
  getCoupons?: Maybe<Array<Coupon>>;
  verifyCoupon: VerifyCouponOutput;
  getCouponsByRestaurant?: Maybe<Array<Coupon>>;
  verifyGroceryCoupon: VerifyGroceryCouponOutput;
  getCouponsByGroceryStore?: Maybe<Array<GroceryCoupon>>;
  getMonthAnalyticCsv: Analytic;
  getGoogleApiRestriction?: Maybe<Scalars['String']>;
  getBizReviews?: Maybe<BizReviewConnection>;
  getBizReviewById?: Maybe<BizReview>;
  getConsumerReviews?: Maybe<ConsumerDishReviewConnection>;
  consumerGetOrderFeeBackByOrderId: OrderFeeBack;
  consumerGetOrderFeeBacks?: Maybe<OrderFeeBackConnection>;
  restaurantGetOrderFeeBacks?: Maybe<OrderFeeBackConnection>;
  restaurantGetOrderFeeBackById?: Maybe<OrderFeeBack>;
  getWebsiteData?: Maybe<WebSiteData>;
  getWebAndBizData?: Maybe<WebAndBizData>;
  checkWebsiteUrlExits?: Maybe<Scalars['Boolean']>;
  getWebsiteDataForBiz?: Maybe<WebSiteData>;
  getSingleWebsiteBlogDataForBiz?: Maybe<Articles>;
  getWebsiteListForRestaurant?: Maybe<Array<Maybe<LokobeePartnerWebsite>>>;
  boonsDotIoGetDeliveryDetails?: Maybe<Scalars['Boolean']>;
  getGroceryStore: GroceryStore;
  ownerGetGroceryStoreById: GroceryStore;
  getGroceryStoreByNanoid?: Maybe<GroceryStore>;
  getGroceryStoresByOwner?: Maybe<Array<GroceryStore>>;
  getGroceryStoresByLocation: GroceryStoreConnection;
  getCategoriesByStoreId?: Maybe<Array<GroceryStoreCategory>>;
  getCategoriesWithItemsByStoreId?: Maybe<Array<GroceryStoreCategoryWithItems>>;
  getGroceryDefaultItems?: Maybe<Array<GroceryStoreCategoryWithItems>>;
  getGroceryItemsByCategoryId?: Maybe<Array<GroceryStoreItem>>;
  getGroceryItemsBySubCategoryId?: Maybe<Array<GroceryStoreItem>>;
  getGroceryStoreCart?: Maybe<GroceryStoreCart>;
  getGrocerySellerOrders?: Maybe<GroceryOrderConnection>;
  getGrocerySellerOngoingOrders?: Maybe<Array<GroceryOrder>>;
  getGroceryOrderByOrderNumber?: Maybe<GroceryOrder>;
  getGroceryOrderByOrderId?: Maybe<GroceryOrder>;
  getBuyerGroceryOrders?: Maybe<GroceryOrderConnection>;
  getGroceryBizEmployee: GroceryBizEmployee;
  getGroceryBizEmployees?: Maybe<Array<GroceryBizEmployee>>;
  getAllGroceryItemsImageCount?: Maybe<Array<GroceryItemImageCount>>;
  getGroceryRewardPointsForConsumer?: Maybe<Array<ConsumerGroceryRewardPoint>>;
  getGroceryRewardItems?: Maybe<Array<GroceryStoreItem>>;
  getGroceryOrderPauseDate?: Maybe<Scalars['Boolean']>;
  getGroceryAllPauseDate?: Maybe<GroceryAllPauseDate>;
  getGroceryWaitMinutes?: Maybe<GroceryWaitMinutes>;
  queryGrocerySearch?: Maybe<Array<GroceryStoreItem>>;
  getGroceryDiscountedItems?: Maybe<Array<GroceryStoreItem>>;
  getTextBroadcast?: Maybe<TextBroadcast>;
  getRestaurantTextBroadcasts?: Maybe<Array<TextBroadcast>>;
  getTextHistory?: Maybe<Array<TextBroadcastHistory>>;
  getTextBroadcastPhoneNumbers: Array<Scalars['String']>;
  getCryptoCouponsByBizId: Array<CryptoCoupon>;
  ownerGetCryptoCouponsByBizId: Array<CryptoCoupon>;
  getCryptoCouponHistory?: Maybe<CryptoCouponConnection>;
  getCouponByCouponId?: Maybe<CryptoCoupon>;
  getCCTokenValue?: Maybe<CcTokenOutput>;
};


export type QueryGetRestaurantArgs = {
  input: GetRestaurantInput;
};


export type QueryOwnerGetRestaurantByIdArgs = {
  input: OwnerGetRestaurantByIdInput;
};


export type QueryGetRestaurantsByOwnerArgs = {
  input?: Maybe<GetRestaurantsByOwnerInput>;
};


export type QueryGetRestaurantsByLocationArgs = {
  input: GetRestaurantsNearbyInput;
};


export type QueryGetRestaurantsByZipcodeArgs = {
  input: GetRestaurantsByZipcodeInput;
};


export type QueryGetRestaurantsByStateCityArgs = {
  input: GetRestaurantsByStateCityInput;
};


export type QueryGetRestaurantByPhoneNumberArgs = {
  input: GetRestaurantByPhoneNumberInput;
};


export type QueryGetWaitMinutesArgs = {
  input: GetWaitMinutesInput;
};


export type QueryGetRestaurantByNanoidArgs = {
  input: GetRestaurantByNanoidInput;
};


export type QueryGetBizOrderPauseDateArgs = {
  input: GetBizOrderPauseDateInput;
};


export type QueryGetBizAllPauseDateArgs = {
  input: GetBizAllOrderPauseDateInput;
};


export type QueryGetTableArgs = {
  input: GetTableInput;
};


export type QueryGetTablesArgs = {
  input: GetTablesInput;
};


export type QueryDownloadTablesCardArgs = {
  input: DownloadTablesCardInput;
};


export type QueryGetStoreCardArgs = {
  input: GetStoreCardInput;
};


export type QueryGetMenuCardArgs = {
  input: GetMenuCardInput;
};


export type QueryGetBizCardArgs = {
  input: GetBizCardInput;
};


export type QueryCheckBackSideImageExistsArgs = {
  input: CheckBackSideImageExistsInput;
};


export type QueryGetDishArgs = {
  input: GetDishInput;
};


export type QueryGetDishesArgs = {
  input: GetDishesInput;
};


export type QueryGetAllDishesArgs = {
  input: GetAllDishesInput;
};


export type QueryGetDishCategoriesOfRestaurantArgs = {
  input: GetDishCategoriesOfRestaurantInput;
};


export type QueryGetDishCategoryArgs = {
  input: GetDishCategoryInput;
};


export type QueryGetTableServicesArgs = {
  input: GetTableServicesInput;
};


export type QueryGetPizzaPartsArgs = {
  input: GetPizzaPartsInput;
};


export type QueryGetDishCategoryDisplayOrderArgs = {
  input: GetDishCategoryDisplayOrderInput;
};


export type QueryGetDishDisplayOrderArgs = {
  input: GetDishDisplayOrderInput;
};


export type QueryGetRewardItemsArgs = {
  input: GetRewardItemsInput;
};


export type QueryGetIngredientsInfoArgs = {
  input: GetIngredientsInfoInput;
};


export type QueryGetRewardPointsForConsumerArgs = {
  input?: Maybe<GetRewardPointsForConsumerInput>;
};


export type QueryGetBizEmployeeArgs = {
  input: GetBizEmployeeInput;
};


export type QueryGetBizEmployeesArgs = {
  input: GetBizEmployeesInput;
};


export type QueryGetStripeConnectUrlArgs = {
  input: GetStripeConnectUrlInput;
};


export type QueryGetStripeConnectUrlForGroceryArgs = {
  input: GetStripeConnectUrlForGroceryStoreInput;
};


export type QueryGetOrderByOrderNumberArgs = {
  input: GetOrderByOrderNumberInput;
};


export type QueryGetOrderByOrderIdArgs = {
  input: GetOrderByOrderIdInput;
};


export type QueryGetBuyerOrdersArgs = {
  input: GetBuyerOrdersInput;
};


export type QueryGetBuyerOngoingOrdersArgs = {
  input: GetBuyerOngoingOrdersInput;
};


export type QueryGetSellerOrdersArgs = {
  input: GetSellerOrdersInput;
};


export type QueryGetSellerOngoingOrdersArgs = {
  input: GetSellerOngoingOrdersInput;
};


export type QueryGetSellerOrdersByCouponIdArgs = {
  input: GetSellerOrdersByCouponIdInput;
};


export type QueryGetCouponsArgs = {
  input: GetCouponsByBizIdInput;
};


export type QueryVerifyCouponArgs = {
  input?: Maybe<VerifyCouponInput>;
};


export type QueryGetCouponsByRestaurantArgs = {
  input?: Maybe<GetCouponsByRestaurantInput>;
};


export type QueryVerifyGroceryCouponArgs = {
  input?: Maybe<VerifyGroceryCouponInput>;
};


export type QueryGetCouponsByGroceryStoreArgs = {
  input?: Maybe<GetCouponsByGroceryStoreInput>;
};


export type QueryGetMonthAnalyticCsvArgs = {
  input: GetMonthAnalyticCsvInput;
};


export type QueryGetGoogleApiRestrictionArgs = {
  input: GetGoogleApiRestrictionInput;
};


export type QueryGetBizReviewsArgs = {
  input: GetBizReviewsInput;
};


export type QueryGetBizReviewByIdArgs = {
  input: GetBizReviewByIdInput;
};


export type QueryGetConsumerReviewsArgs = {
  input: GetConsumerReviewsInput;
};


export type QueryConsumerGetOrderFeeBackByOrderIdArgs = {
  input: CustomerGetFeeBackByOrderIdInput;
};


export type QueryConsumerGetOrderFeeBacksArgs = {
  input: CustomerGetFeeBacksInput;
};


export type QueryRestaurantGetOrderFeeBacksArgs = {
  input: RestaurantGetFeeBackInput;
};


export type QueryRestaurantGetOrderFeeBackByIdArgs = {
  input: RestaurantGetFeeBackByIdInput;
};


export type QueryGetWebsiteDataArgs = {
  input: GetWebsiteDataInput;
};


export type QueryGetWebAndBizDataArgs = {
  input: GetWebAndBizDataInput;
};


export type QueryCheckWebsiteUrlExitsArgs = {
  input: CheckWebsiteUrlExitsInput;
};


export type QueryGetWebsiteDataForBizArgs = {
  input: GetWebsiteDataForBizInput;
};


export type QueryGetSingleWebsiteBlogDataForBizArgs = {
  input: GetSingleWebsiteBlogDataForBiz;
};


export type QueryBoonsDotIoGetDeliveryDetailsArgs = {
  input: BoonsDotIoGetDeliveryDetailsRequestInput;
};


export type QueryGetGroceryStoreArgs = {
  input: GetGroceryStoreInput;
};


export type QueryOwnerGetGroceryStoreByIdArgs = {
  input: OwnerGetGroceryStoreByIdInput;
};


export type QueryGetGroceryStoreByNanoidArgs = {
  input: GetGroceryStoreByNanoidInput;
};


export type QueryGetGroceryStoresByLocationArgs = {
  input: GetGroceryStoresNearbyInput;
};


export type QueryGetCategoriesByStoreIdArgs = {
  input: GetCategoriesByStoreIdInput;
};


export type QueryGetCategoriesWithItemsByStoreIdArgs = {
  input: GetCategoriesWithItemsByStoreIdInput;
};


export type QueryGetGroceryItemsByCategoryIdArgs = {
  input: GetGroceryItemsByCategoryIdInput;
};


export type QueryGetGroceryItemsBySubCategoryIdArgs = {
  input: GetGroceryItemsBySubCategoryIdInput;
};


export type QueryGetGroceryStoreCartArgs = {
  input?: Maybe<GetGroceryStoreCartInput>;
};


export type QueryGetGrocerySellerOrdersArgs = {
  input: GetGrocerySellerOrdersInput;
};


export type QueryGetGrocerySellerOngoingOrdersArgs = {
  input: GetGrocerySellerOngoingOrdersInput;
};


export type QueryGetGroceryOrderByOrderNumberArgs = {
  input: GetGroceryOrderByOrderNumberInput;
};


export type QueryGetGroceryOrderByOrderIdArgs = {
  input: GetGroceryOrderByOrderIdInput;
};


export type QueryGetBuyerGroceryOrdersArgs = {
  input?: Maybe<GetGroceryBuyerOrdersInput>;
};


export type QueryGetGroceryBizEmployeeArgs = {
  input: GetGroceryBizEmployeeInput;
};


export type QueryGetGroceryBizEmployeesArgs = {
  input: GetGroceryBizEmployeesInput;
};


export type QueryGetGroceryRewardPointsForConsumerArgs = {
  input?: Maybe<GetGroceryRewardPointsForConsumerInput>;
};


export type QueryGetGroceryRewardItemsArgs = {
  input: GetGroceryRewardItemsInput;
};


export type QueryGetGroceryOrderPauseDateArgs = {
  input: GetGroceryOrderPauseDateInput;
};


export type QueryGetGroceryAllPauseDateArgs = {
  input: GetGroceryAllOrderPauseDateInput;
};


export type QueryGetGroceryWaitMinutesArgs = {
  input: GetGroceryWaitMinutesInput;
};


export type QueryQueryGrocerySearchArgs = {
  input?: Maybe<GrocerySearchInput>;
};


export type QueryGetGroceryDiscountedItemsArgs = {
  input: GetGroceryDiscountedItemsInput;
};


export type QueryGetTextBroadcastArgs = {
  input?: Maybe<GetTextBroadcastInput>;
};


export type QueryGetRestaurantTextBroadcastsArgs = {
  input?: Maybe<GetRestaurantTextBroadcastsInput>;
};


export type QueryGetTextHistoryArgs = {
  input?: Maybe<GetTextHistoryInput>;
};


export type QueryGetTextBroadcastPhoneNumbersArgs = {
  input?: Maybe<GetBroadcastPhoneNumbersInput>;
};


export type QueryGetCryptoCouponsByBizIdArgs = {
  input?: Maybe<GetCryptoCouponsByBizIdInput>;
};


export type QueryOwnerGetCryptoCouponsByBizIdArgs = {
  input?: Maybe<OwnerGetCryptoCouponsByBizIdInput>;
};


export type QueryGetCryptoCouponHistoryArgs = {
  input?: Maybe<GetCryptoCouponHistoryInput>;
};


export type QueryGetCouponByCouponIdArgs = {
  input?: Maybe<GetCouponByCouponIdInput>;
};


export type QueryGetCcTokenValueArgs = {
  input: GetCcTokenValue;
};

export type RegistrationFcmTokenInput = {
  idToken?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
};

export type RequestCryptoCouponPromotionInput = {
  bizId: Scalars['ID'];
};

export type RequestTextMarketingInput = {
  bizId: Scalars['ID'];
};

export type ResetCryptoCouponInput = {
  token: Scalars['String'];
  sessionId?: Maybe<Scalars['String']>;
};

export type Restaurant = {
  __typename?: 'Restaurant';
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  lokobeePlan: LokobeePlan;
  type?: Maybe<RestaurantType>;
  i18n?: Maybe<I18NSetting>;
  name?: Maybe<Array<Maybe<MultiLangString>>>;
  subName?: Maybe<Array<Maybe<MultiLangString>>>;
  description?: Maybe<Array<Maybe<MultiLangString>>>;
  announcement?: Maybe<Array<Maybe<MultiLangString>>>;
  email?: Maybe<Scalars['String']>;
  orderEmails?: Maybe<Array<Scalars['String']>>;
  reportEmails?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Scalars['String']>;
  virtualPhone?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  addressNote?: Maybe<Array<Maybe<MultiLangString>>>;
  bizHours?: Maybe<Array<BizHour>>;
  taxRate?: Maybe<TaxRate>;
  logo?: Maybe<Image>;
  storeCard?: Maybe<Image>;
  menuCard?: Maybe<Image>;
  bizCardBack?: Maybe<Image>;
  bizCardFront?: Maybe<Image>;
  defaultImg?: Maybe<Image>;
  bizCardBackSideImg?: Maybe<Image>;
  images?: Maybe<Array<MultiSizeImage>>;
  tables?: Maybe<Array<Table>>;
  onlineStatus: RestaurantStatus;
  isOnVacation?: Maybe<Scalars['Boolean']>;
  isLokobeePartner?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<CurrencyCode>;
  dishDisplaySetting?: Maybe<RestaurantDishDisplaySetting>;
  issueRewardPoint?: Maybe<Scalars['Boolean']>;
  rewardSetting?: Maybe<RewardSetting>;
  takeoutPaymentSetting?: Maybe<PaymentSetting>;
  dineinPaymentSetting?: Maybe<PaymentSetting>;
  dineoutPaymentSetting?: Maybe<PaymentSetting>;
  deliveryPaymentSetting?: Maybe<PaymentSetting>;
  owner?: Maybe<LokobeeUser>;
  staff?: Maybe<Array<BizEmployee>>;
  hasStripeAccount?: Maybe<Scalars['Boolean']>;
  stripeAccount?: Maybe<Scalars['String']>;
  enableTakeoutOrder: Scalars['Boolean'];
  enableDineInOrder: Scalars['Boolean'];
  enableDineOutOrder: Scalars['Boolean'];
  enableDeliveryOrder: Scalars['Boolean'];
  enableDishNote: Scalars['Boolean'];
  deliverySetting?: Maybe<DeliverySetting>;
  takeoutWaitMinutes?: Maybe<Scalars['Int']>;
  cookingWaitMinutes?: Maybe<Scalars['Int']>;
  takeoutOnlinePaymentTypes?: Maybe<Array<PaymentType>>;
  opsSetting?: Maybe<OpsSetting>;
  coupons?: Maybe<Array<Coupon>>;
  enablePizzaCompiler?: Maybe<Scalars['Boolean']>;
  lokobeeFee: Scalars['Boolean'];
  lokobeeFeePercent?: Maybe<Scalars['Float']>;
  lokobeeFeeLimit?: Maybe<Scalars['Int']>;
  lokobeeDiningFeePercent?: Maybe<Scalars['Float']>;
  lokobeeDiningFeeLimit?: Maybe<Scalars['Int']>;
  googleReviewLink?: Maybe<Scalars['String']>;
  autoOrderConfirm?: Maybe<Scalars['Boolean']>;
  callForNewOrder?: Maybe<Scalars['Boolean']>;
  nanoid?: Maybe<Scalars['String']>;
  rateScore?: Maybe<Scalars['Float']>;
  rateCount?: Maybe<Scalars['Int']>;
  takeoutPauseDate?: Maybe<Scalars['String']>;
  deliverPauseDate?: Maybe<Scalars['String']>;
  diningPauseDate?: Maybe<Scalars['String']>;
  webSite?: Maybe<Scalars['String']>;
  deliveryMethod?: Maybe<DeliveryModes>;
  deliveryProviders?: Maybe<DeliveryProviders>;
  bizCardBackSideMainTitle?: Maybe<Array<Maybe<MultiLangString>>>;
  bizCardBackSideMainSubTitle?: Maybe<Array<Maybe<MultiLangString>>>;
  bizPlan?: Maybe<BizPlan>;
  avgTimeForDriver?: Maybe<Scalars['Int']>;
  isDoordashPaused?: Maybe<Scalars['Boolean']>;
  isTextMarketingEnabled?: Maybe<Scalars['Boolean']>;
  isTextMarketingRequested?: Maybe<Scalars['Boolean']>;
  couponCredit?: Maybe<Scalars['Int']>;
  cryptoCouponEnabled?: Maybe<Scalars['Boolean']>;
  cryptoCouponExists?: Maybe<Scalars['Boolean']>;
  cryptoCoupons?: Maybe<Array<CryptoCoupon>>;
};


export type RestaurantNameArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type RestaurantSubNameArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type RestaurantDescriptionArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type RestaurantAnnouncementArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type RestaurantAddressNoteArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type RestaurantBizCardBackSideMainTitleArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};


export type RestaurantBizCardBackSideMainSubTitleArgs = {
  lang?: Maybe<LanguageCode>;
  defaultLang?: Maybe<LanguageCode>;
};

export type RestaurantConnection = {
  __typename?: 'RestaurantConnection';
  edges?: Maybe<Array<RestaurantConnectionEdge>>;
  pageInfo: PageInfo;
};

export type RestaurantConnectionEdge = {
  __typename?: 'RestaurantConnectionEdge';
  node: Restaurant;
  cursor: Scalars['String'];
  distance?: Maybe<Scalars['Float']>;
};

export type RestaurantDishDisplaySetting = {
  __typename?: 'RestaurantDishDisplaySetting';
  dishCategoryDisplayOrder?: Maybe<Array<Scalars['ID']>>;
};

export type RestaurantDishDisplaySettingInput = {
  dishCategoryDisplayOrder?: Maybe<Array<Scalars['ID']>>;
};

export type RestaurantGetFeeBackByIdInput = {
  bizId: Scalars['String'];
  orderId: Scalars['String'];
};

export type RestaurantGetFeeBackInput = {
  bizId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export enum RestaurantStatus {
  LokobeeAdminDisabled = 'LOKOBEE_ADMIN_DISABLED',
  Deleted = 'DELETED',
  Hidden = 'HIDDEN',
  OnVacation = 'ON_VACATION',
  Active = 'ACTIVE',
  UnderMaintenance = 'UNDER_MAINTENANCE',
  UnderConstruction = 'UNDER_CONSTRUCTION'
}

export enum RestaurantType {
  RegularRestaurant = 'REGULAR_RESTAURANT',
  HomeKitchen = 'HOME_KITCHEN',
  FoodTruck = 'FOOD_TRUCK',
  StoreRestaurant = 'STORE_RESTAURANT'
}

export enum RewardPointStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING'
}

export enum RewardPointType {
  StoreReward = 'STORE_REWARD',
  LokobeeReward = 'LOKOBEE_REWARD'
}

export type RewardSetting = {
  __typename?: 'RewardSetting';
  minStartPoints: Scalars['Int'];
  maxRedeemPointsPerUsage: Scalars['Int'];
  minRedeemPurchase: Scalars['Int'];
  pricePointRatio: Scalars['Int'];
};

export type RewardSettingInput = {
  minStartPoints: Scalars['Int'];
  maxRedeemPointsPerUsage: Scalars['Int'];
  minRedeemPurchase: Scalars['Int'];
  pricePointRatio?: Maybe<Scalars['Int']>;
};

export enum RewardType {
  ConsumerReward = 'CONSUMER_REWARD',
  BizReward = 'BIZ_REWARD'
}

export type SaveStripeCardInput = {
  source: Scalars['String'];
};

export type SendBroadcastInput = {
  bizId: Scalars['ID'];
  bizPhoneNumber: Scalars['String'];
  textBody: Scalars['String'];
  sendToAll: Scalars['Boolean'];
};

export type SendVerificationCodeInput = {
  phoneNumber: Scalars['String'];
};

export enum ServiceType {
  RestaurantPickUp = 'RESTAURANT_PICK_UP',
  RestaurantDineIn = 'RESTAURANT_DINE_IN',
  RestaurantDineOut = 'RESTAURANT_DINE_OUT',
  RestaurantDelivery = 'RESTAURANT_DELIVERY'
}

export type SetUserDealOptOutInput = {
  emailForDeals?: Maybe<Scalars['Boolean']>;
  smsForDeals?: Maybe<Scalars['Boolean']>;
};

export type StoreCard = {
  __typename?: 'StoreCard';
  id: Scalars['String'];
  cardImg?: Maybe<Image>;
};

export type StripeChargeInfo = {
  __typename?: 'StripeChargeInfo';
  chargeId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  card?: Maybe<StripePaymentCard>;
  chargeAmount?: Maybe<MoneyAmount>;
  chargeTime?: Maybe<Scalars['Timestamp']>;
  refunded?: Maybe<Scalars['Boolean']>;
  refundTime?: Maybe<Scalars['Timestamp']>;
  refundReason?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
};

export type StripePaymentCard = {
  __typename?: 'StripePaymentCard';
  brand?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type Table = {
  __typename?: 'Table';
  tableName: Scalars['String'];
  seats: Scalars['Int'];
  cardImg?: Maybe<Image>;
};

export type TaxMode = {
  __typename?: 'TaxMode';
  title: Scalars['String'];
  tax: TaxRate;
};

export type TaxModeInput = {
  title: Scalars['String'];
  tax: Scalars['String'];
};

export type TaxRate = {
  __typename?: 'TaxRate';
  intValue: Scalars['Int'];
  shift: Scalars['Int'];
};

export enum TCancelledReasonCode {
  ClPlst = 'CL_PLST',
  ClDr = 'CL_DR',
  ClAic = 'CL_AIC',
  ClUfa = 'CL_UFA',
  ClCua = 'CL_CUA',
  ClOtr = 'CL_OTR'
}

export enum TDelayedReasonCode {
  DlDmg = 'DL_DMG',
  DlPp = 'DL_PP',
  DlCfp = 'DL_CFP',
  DlDdt = 'DL_DDT',
  DlVp = 'DL_VP',
  DlUec = 'DL_UEC',
  DlTlh = 'DL_TLH',
  DlOtr = 'DL_OTR'
}

export enum TDoordashReasonCode {
  CanceledOrderByDoordash = 'canceled_order_by_doordash',
  CanceledOrderByMerchantRequest = 'canceled_order_by_merchant_request',
  CanceledOrderByCustomerRequest = 'canceled_order_by_customer_request',
  DeliveredLate = 'delivered_late',
  DeliveredEarly = 'delivered_early',
  NeverDelivered = 'never_delivered',
  MissingOrIncorrectItems = 'missing_or_incorrect_items',
  PoorFoodQuality = 'poor_food_quality',
  DeliveryQuality = 'delivery_quality',
  HealthSafetyConcern = 'health_safety_concern',
  AdjustTip = 'adjust_tip',
  SpecialInstructionsNotFollowed = 'special_instructions_not_followed',
  CancelByMerchant = 'cancel_by_merchant',
  Other = 'other'
}

export type TextBroadcast = {
  __typename?: 'TextBroadcast';
  bizId: Scalars['ID'];
  id: Scalars['ID'];
  bizPhoneNumber: Scalars['String'];
  frequency: Frequency;
  dayOfWeek: Scalars['Int'];
  timeHour: Scalars['Int'];
  timeMin: Scalars['Int'];
  textBody: Scalars['String'];
  lastBroadcast?: Maybe<Scalars['Timestamp']>;
  nextBroadcast?: Maybe<Scalars['Timestamp']>;
  status: BroadcastStatus;
  endDate?: Maybe<Scalars['Timestamp']>;
};

export type TextBroadcastHistory = {
  __typename?: 'TextBroadcastHistory';
  bizId: Scalars['ID'];
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  nextBroadcast: Scalars['Timestamp'];
  noOfTextSent: Scalars['Int'];
};


export type UpdateBizEmployeeInput = {
  bizId: Scalars['ID'];
  uid: Scalars['ID'];
  userName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type UpdateDishCategoryDisplayOrderInput = {
  bizId: Scalars['ID'];
  catIds: Array<Scalars['ID']>;
};

export type UpdateDishCategoryInput = {
  id: Scalars['ID'];
  bizId: Scalars['ID'];
  title?: Maybe<Array<MultiLangStringInput>>;
  description?: Maybe<Array<MultiLangStringInput>>;
  type?: Maybe<DishType>;
};

export type UpdateDishDisplayOrderInput = {
  bizId: Scalars['ID'];
  catId: Scalars['ID'];
  dishIds: Array<Scalars['ID']>;
};

export type UpdateDishInput = {
  bizId: Scalars['ID'];
  id: Scalars['ID'];
  categoryId?: Maybe<Scalars['ID']>;
  title?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  description?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  price?: Maybe<Array<Maybe<DishPriceInput>>>;
  imageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<DishTag>>>;
  status?: Maybe<DishStatus>;
  extraGroups?: Maybe<Array<Maybe<DishExtraGroupInput>>>;
  type?: Maybe<DishType>;
  pizzaBasePrice?: Maybe<Array<PizzaBasePriceInput>>;
  pizzaCrustIds?: Maybe<Array<Scalars['String']>>;
  pizzaDefaultCrustId?: Maybe<Scalars['String']>;
  pizzaSauce?: Maybe<DishPizzaSauceInput>;
  pizzaCheese?: Maybe<DishPizzaCheeseInput>;
  pizzaToppings?: Maybe<Array<DishPizzaToppingInput>>;
  isReward?: Maybe<Scalars['Boolean']>;
  rewardPoints?: Maybe<Scalars['Int']>;
  limitPerDay?: Maybe<Scalars['Int']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
  isAlcohol?: Maybe<Scalars['Boolean']>;
};

export type UpdateFeeBackInput = {
  bizId: Scalars['String'];
  orderId: Scalars['String'];
  userUid: Scalars['String'];
  message: Scalars['String'];
  rateScore?: Maybe<Scalars['Float']>;
};

export type UpdateGroceryBizEmployeeInput = {
  storeId: Scalars['ID'];
  uid: Scalars['ID'];
  userName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type UpdateGroceryDefaultItemsInput = {
  categories?: Maybe<Array<GroceryStoreCategoryInput>>;
};

export type UpdateGroceryOrderStatusInput = {
  buyerUid: Scalars['ID'];
  sellerStoreId: Scalars['ID'];
  orderId: Scalars['ID'];
  newStatus: GroceryOrderStatus;
  msg?: Maybe<Scalars['String']>;
  noOfBags?: Maybe<Scalars['Int']>;
  packingWaitMins?: Maybe<Scalars['Int']>;
  specialHandlingInstruction?: Maybe<Scalars['String']>;
};

export type UpdateGroceryStoreInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<GroceryStoreCategoryInput>>;
  description?: Maybe<Scalars['String']>;
  logoId?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  timezone?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['String']>;
  status?: Maybe<GroceryStoreStatus>;
  bizHours?: Maybe<Array<GroceryBizHourInput>>;
  enableTakeoutOrder?: Maybe<Scalars['Boolean']>;
  enableDeliveryOrder?: Maybe<Scalars['Boolean']>;
  takeoutPaymentSetting?: Maybe<GroceryPaymentSettingInput>;
  deliveryPaymentSetting?: Maybe<GroceryPaymentSettingInput>;
  taxModes?: Maybe<Array<TaxModeInput>>;
  deliverySetting?: Maybe<GroceryDeliverySettingInput>;
  takeoutWaitMinutes?: Maybe<Scalars['Int']>;
  announcement?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  orderEmails?: Maybe<Array<Scalars['String']>>;
  reportEmails?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Scalars['String']>;
  googleReviewLink?: Maybe<Scalars['String']>;
  galleryImageIds?: Maybe<Array<Scalars['String']>>;
  issueRewardPoint?: Maybe<Scalars['Boolean']>;
  rewardSetting?: Maybe<GroceryRewardSettingInput>;
  deliveryProvider?: Maybe<GroceryDeliveryProviders>;
  deliveryMode?: Maybe<GroceryDeliveryModes>;
  packingWaitMins?: Maybe<Scalars['Int']>;
};

export type UpdateGroceryWaitMinutesInput = {
  id: Scalars['ID'];
  takeoutWaitMinutes?: Maybe<Scalars['Int']>;
  deliveryWaitMinutes?: Maybe<Scalars['Int']>;
  packingWaitMins?: Maybe<Scalars['Int']>;
};

export type UpdateIngredientsInfoInput = {
  bizId: Scalars['ID'];
  ingredientInfo: Array<DishIngredientInput>;
};

export type UpdateMenuCardInput = {
  bizId: Scalars['ID'];
};

export type UpdateNextMonthBroadcastInput = {
  bizId: Scalars['ID'];
  frequency: Frequency;
  dayOfWeek: Scalars['Int'];
  timeHour: Scalars['Int'];
  timeMin: Scalars['Int'];
};

export type UpdateOrderStatusInput = {
  buyerUid: Scalars['ID'];
  sellerBizId: Scalars['ID'];
  orderId: Scalars['ID'];
  newStatus: OrderStatus;
  deliveryProvider?: Maybe<DeliveryProviders>;
  type?: Maybe<OrderType>;
  deliveryQuoteId?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  noOfBags?: Maybe<Scalars['Int']>;
  cookingWaitMins?: Maybe<Scalars['Int']>;
  specialHandlingInstruction?: Maybe<Scalars['String']>;
  delayEtaBy?: Maybe<Scalars['Int']>;
};

export type UpdateRestaurantInput = {
  id: Scalars['ID'];
  type?: Maybe<RestaurantType>;
  i18n?: Maybe<I18NSettingInput>;
  name?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  subName?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  description?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  email?: Maybe<Scalars['String']>;
  orderEmails?: Maybe<Array<Scalars['String']>>;
  reportEmails?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Scalars['String']>;
  virtualPhone?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  addressNote?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  announcement?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  bizHours?: Maybe<Array<BizHourInput>>;
  taxRate?: Maybe<Scalars['String']>;
  logoId?: Maybe<Scalars['String']>;
  defaultImage?: Maybe<Scalars['String']>;
  bizCardBackSideImg?: Maybe<Scalars['String']>;
  imageIds?: Maybe<Array<Scalars['String']>>;
  isOnVacation?: Maybe<Scalars['Boolean']>;
  onlineStatus?: Maybe<RestaurantStatus>;
  currencyCode?: Maybe<CurrencyCode>;
  dishDisplaySetting?: Maybe<RestaurantDishDisplaySettingInput>;
  issueRewardPoint?: Maybe<Scalars['Boolean']>;
  rewardSetting?: Maybe<RewardSettingInput>;
  takeoutPaymentSetting?: Maybe<PaymentSettingInput>;
  dineinPaymentSetting?: Maybe<PaymentSettingInput>;
  dineoutPaymentSetting?: Maybe<PaymentSettingInput>;
  deliveryPaymentSetting?: Maybe<PaymentSettingInput>;
  enableTakeoutOrder?: Maybe<Scalars['Boolean']>;
  enableDineInOrder?: Maybe<Scalars['Boolean']>;
  enableDineOutOrder?: Maybe<Scalars['Boolean']>;
  enableDeliveryOrder?: Maybe<Scalars['Boolean']>;
  enableDishNote?: Maybe<Scalars['Boolean']>;
  deliverySetting?: Maybe<DeliverySettingInput>;
  takeoutWaitMinutes?: Maybe<Scalars['Int']>;
  cookingWaitMinutes?: Maybe<Scalars['Int']>;
  opsSetting?: Maybe<OpsSettingInput>;
  googleReviewLink?: Maybe<Scalars['String']>;
  autoOrderConfirm?: Maybe<Scalars['Boolean']>;
  callForNewOrder?: Maybe<Scalars['Boolean']>;
  webSite?: Maybe<Scalars['String']>;
  takeoutPauseDate?: Maybe<Scalars['String']>;
  deliverPauseDate?: Maybe<Scalars['String']>;
  diningPauseDate?: Maybe<Scalars['String']>;
  vacation?: Maybe<VacationInput>;
  bizCardBackSideMainTitle?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  bizCardBackSideMainSubTitle?: Maybe<Array<Maybe<MultiLangStringInput>>>;
  avgTimeForDriver?: Maybe<Scalars['Int']>;
  bizWebSiteUrl?: Maybe<Scalars['String']>;
};

export type UpdateRestaurantPizzaCheesesInput = {
  bizId: Scalars['ID'];
  cheeses?: Maybe<Array<PizzaToppingGroupInput>>;
};

export type UpdateRestaurantPizzaCrustsInput = {
  bizId: Scalars['ID'];
  crusts?: Maybe<Array<PizzaCrustInput>>;
};

export type UpdateRestaurantPizzaDensityMultipliersInput = {
  bizId: Scalars['ID'];
  densityMultipliers: Array<PizzaToppingDensityMultiplierInput>;
};

export type UpdateRestaurantPizzaHalfToppingPriceFactorInput = {
  bizId: Scalars['ID'];
  halfToppingPriceFactor: Scalars['Int'];
};

export type UpdateRestaurantPizzaSaucesInput = {
  bizId: Scalars['ID'];
  sauces?: Maybe<Array<PizzaToppingGroupInput>>;
};

export type UpdateRestaurantPizzaSizesInput = {
  bizId: Scalars['ID'];
  sizes?: Maybe<Array<PizzaSizeInput>>;
};

export type UpdateRestaurantPizzaToppingsInput = {
  bizId: Scalars['ID'];
  toppings?: Maybe<Array<PizzaToppingGroupInput>>;
};

export type UpdateSingleWebsiteBlogInput = {
  bizId: Scalars['ID'];
  blog: ArticlesInput;
};

export type UpdateStoreCardInput = {
  bizId: Scalars['ID'];
};

export type UpdateTableInput = {
  bizId: Scalars['ID'];
  tableName: Scalars['String'];
  seats: Scalars['Int'];
};

export type UpdateTextBroadcastInput = {
  bizId: Scalars['ID'];
  id: Scalars['ID'];
  bizPhoneNumber?: Maybe<Scalars['String']>;
  textBody?: Maybe<Scalars['String']>;
};

export type UpdateUserAddressInput = {
  address: AddressInput;
};

export type UpdateUserOrderPhoneAndOrderEmailInput = {
  orderPhoneNumber: Scalars['String'];
  orderEmail?: Maybe<Scalars['String']>;
};

export type UpdateWaitMinutesInput = {
  id: Scalars['ID'];
  takeoutWaitMinutes?: Maybe<Scalars['Int']>;
  deliveryWaitMinutes?: Maybe<Scalars['Int']>;
  cookingWaitMinutes?: Maybe<Scalars['Int']>;
};

export type UpdateWebsiteContentInput = {
  bizId: Scalars['ID'];
  url: Scalars['String'];
  displayImage?: Maybe<Scalars['String']>;
  headline1?: Maybe<Scalars['String']>;
  headline2?: Maybe<Scalars['String']>;
  about?: Maybe<Array<Maybe<WebsiteContentAboutInput>>>;
  menuPicture?: Maybe<Scalars['String']>;
  menuHeadline?: Maybe<Scalars['String']>;
  fbLink?: Maybe<Scalars['String']>;
  instaLink?: Maybe<Scalars['String']>;
  testimonials?: Maybe<Array<WebsiteContentTestimonialsInput>>;
  googleAnalyticsTrackingId?: Maybe<Scalars['String']>;
  blog?: Maybe<Array<Maybe<ArticlesInput>>>;
  keywords?: Maybe<Scalars['String']>;
};


export type UserMetadata = {
  __typename?: 'UserMetadata';
  lastSignInTime?: Maybe<Scalars['Timestamp']>;
  creationTime?: Maybe<Scalars['Timestamp']>;
  lastRefreshTime?: Maybe<Scalars['Timestamp']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  uid: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
};

export type Vacation = {
  __typename?: 'Vacation';
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type VacationDataInput = {
  id: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type VacationInput = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type ValidateDriverOtpInput = {
  optCode?: Maybe<Scalars['String']>;
  orderNumer?: Maybe<Scalars['String']>;
  bizId?: Maybe<Scalars['String']>;
};

export type VerifyCouponInput = {
  bizId: Scalars['ID'];
  code: Scalars['String'];
  subtotal: MoneyAmountInput;
  verifyDay?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
};

export type VerifyCouponOutput = {
  __typename?: 'VerifyCouponOutput';
  success: Scalars['Boolean'];
  error?: Maybe<CouponError>;
  token?: Maybe<Scalars['String']>;
  coupon?: Maybe<Coupon>;
};

export type VerifyGroceryCouponInput = {
  storeId: Scalars['ID'];
  code: Scalars['String'];
  subtotal: Scalars['Int'];
  verifyDay?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['String']>;
};

export type VerifyGroceryCouponOutput = {
  __typename?: 'VerifyGroceryCouponOutput';
  success: Scalars['Boolean'];
  error?: Maybe<CouponError>;
  token?: Maybe<Scalars['String']>;
  coupon?: Maybe<GroceryCoupon>;
};

export type VerifyPhoneVerificationCodeInput = {
  phoneNumber: Scalars['String'];
  code: Scalars['String'];
};

export type WaitMinutes = {
  __typename?: 'WaitMinutes';
  takeoutWaitMinutes?: Maybe<Scalars['Int']>;
  deliveryWaitMinutes?: Maybe<Scalars['Int']>;
  cookingWaitMinutes?: Maybe<Scalars['Int']>;
};

export type WebAndBizData = {
  __typename?: 'WebAndBizData';
  web?: Maybe<WebSiteData>;
  restaurant?: Maybe<Restaurant>;
  dish?: Maybe<Array<Maybe<Dish>>>;
};

export type WebsiteContentAbout = {
  __typename?: 'WebsiteContentAbout';
  id?: Maybe<Scalars['String']>;
  picture?: Maybe<Image>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type WebsiteContentAboutInput = {
  picture?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type WebsiteContentTestimonials = {
  __typename?: 'WebsiteContentTestimonials';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
};

export type WebsiteContentTestimonialsInput = {
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
};

export type WebSiteData = {
  __typename?: 'WebSiteData';
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  url?: Maybe<Scalars['String']>;
  bizId?: Maybe<Scalars['ID']>;
  displayImage?: Maybe<Image>;
  headline1?: Maybe<Scalars['String']>;
  headline2?: Maybe<Scalars['String']>;
  about?: Maybe<Array<Maybe<WebsiteContentAbout>>>;
  menuPicture?: Maybe<Image>;
  menuHeadline?: Maybe<Scalars['String']>;
  fbLink?: Maybe<Scalars['String']>;
  instaLink?: Maybe<Scalars['String']>;
  testimonials?: Maybe<Array<Maybe<WebsiteContentTestimonials>>>;
  googleAnalyticsTrackingId?: Maybe<Scalars['String']>;
  blog?: Maybe<Array<Maybe<Articles>>>;
  keywords?: Maybe<Scalars['String']>;
  status?: Maybe<WebSiteStatus>;
};

export enum WebSiteStatus {
  Hidden = 'HIDDEN',
  Active = 'ACTIVE'
}

export type CryptoCouponFragment = (
  { __typename?: 'CryptoCoupon' }
  & Pick<CryptoCoupon, 'bizId' | 'code' | 'id' | 'createdAt' | 'name' | 'description' | 'discountType' | 'discountValue' | 'maxDiscount' | 'startDate' | 'endDate' | 'minSpending' | 'maxUse' | 'isPrivate' | 'isSingleUse' | 'orderType' | 'dayOfWeek' | 'consumerReward' | 'cryptoUnit'>
);

export type DishFragment = (
  { __typename?: 'Dish' }
  & Pick<Dish, 'id' | 'status' | 'isAlcohol' | 'isDiscounted' | 'isReward' | 'rewardPoints' | 'rateScore' | 'rateCount' | 'type' | 'tags'>
  & { title?: Maybe<Array<Maybe<(
    { __typename?: 'MultiLangString' }
    & Pick<MultiLangString, 'text'>
  )>>>, category: (
    { __typename?: 'DishCategory' }
    & Pick<DishCategory, 'id'>
    & { title?: Maybe<Array<Maybe<(
      { __typename?: 'MultiLangString' }
      & Pick<MultiLangString, 'text'>
    )>>> }
  ), description?: Maybe<Array<Maybe<(
    { __typename?: 'MultiLangString' }
    & Pick<MultiLangString, 'text'>
  )>>>, price?: Maybe<Array<(
    { __typename?: 'DishPrice' }
    & Pick<DishPrice, 'id' | 'isDefault' | 'discountAmount'>
    & { size?: Maybe<Array<(
      { __typename?: 'MultiLangString' }
      & Pick<MultiLangString, 'text'>
    )>>, price: (
      { __typename?: 'Price' }
      & Pick<Price, 'currencyCode' | 'intValue' | 'shift'>
    ) }
  )>>, images?: Maybe<Array<(
    { __typename?: 'MultiSizeImage' }
    & Pick<MultiSizeImage, 'id'>
    & { preview: (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'url'>
    ), md: (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'url'>
    ), xl: (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'url'>
    ) }
  )>>, pizzaBasePrice?: Maybe<Array<(
    { __typename?: 'PizzaBasePrice' }
    & Pick<PizzaBasePrice, 'id' | 'isDefault' | 'discountAmount'>
    & { size: (
      { __typename?: 'PizzaSize' }
      & Pick<PizzaSize, 'id' | 'title' | 'enableHalfHalf'>
    ), price: (
      { __typename?: 'Price' }
      & Pick<Price, 'intValue' | 'shift'>
    ) }
  )>> }
);

export type StoreItemFragmentFragment = (
  { __typename?: 'GroceryStoreItem' }
  & Pick<GroceryStoreItem, 'id' | 'categoryId' | 'subCategoryId' | 'title' | 'price' | 'description' | 'upcCode' | 'status' | 'size' | 'brand' | 'taxMode' | 'isReward' | 'rewardPoints'>
  & { images?: Maybe<Array<(
    { __typename?: 'MultiSizeImage' }
    & { preview: (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'url'>
    ), lg: (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'url'>
    ), md: (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'url'>
    ), xl: (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'url'>
    ) }
  )>> }
);

export type ProfileFragment = (
  { __typename?: 'Restaurant' }
  & Pick<Restaurant, 'virtualPhone' | 'phone' | 'googleReviewLink'>
  & { name?: Maybe<Array<Maybe<(
    { __typename?: 'MultiLangString' }
    & Pick<MultiLangString, 'text'>
  )>>>, subName?: Maybe<Array<Maybe<(
    { __typename?: 'MultiLangString' }
    & Pick<MultiLangString, 'text'>
  )>>>, logo?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'url'>
  )>, address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'text' | 'street' | 'city' | 'state' | 'country' | 'zipcode' | 'lat' | 'lng'>
  )>, description?: Maybe<Array<Maybe<(
    { __typename?: 'MultiLangString' }
    & Pick<MultiLangString, 'text'>
  )>>>, taxRate?: Maybe<(
    { __typename?: 'TaxRate' }
    & Pick<TaxRate, 'shift' | 'intValue'>
  )>, i18n?: Maybe<(
    { __typename?: 'I18NSetting' }
    & Pick<I18NSetting, 'timezone'>
  )> }
);

export type BusinessHoursFragment = (
  { __typename?: 'Restaurant' }
  & { bizHours?: Maybe<Array<(
    { __typename?: 'BizHour' }
    & Pick<BizHour, 'serviceType' | 'serviceName'>
    & { hours: Array<(
      { __typename?: 'BizHourSlot' }
      & Pick<BizHourSlot, 'openDay' | 'openHour' | 'openMinute' | 'closeHour' | 'closeMinute' | 'closeDay'>
    )> }
  )>> }
);

export type TableFragment = (
  { __typename?: 'Restaurant' }
  & { tables?: Maybe<Array<(
    { __typename?: 'Table' }
    & Pick<Table, 'tableName' | 'seats'>
  )>> }
);

export type AnnouncementFragment = (
  { __typename?: 'Restaurant' }
  & { announcement?: Maybe<Array<Maybe<(
    { __typename?: 'MultiLangString' }
    & Pick<MultiLangString, 'text'>
  )>>> }
);

export type RewardSettingFragment = (
  { __typename?: 'Restaurant' }
  & { rewardSetting?: Maybe<(
    { __typename?: 'RewardSetting' }
    & Pick<RewardSetting, 'minStartPoints' | 'maxRedeemPointsPerUsage' | 'minRedeemPurchase' | 'pricePointRatio'>
  )> }
);

export type GalleryFragment = (
  { __typename?: 'Restaurant' }
  & { images?: Maybe<Array<(
    { __typename?: 'MultiSizeImage' }
    & { md: (
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'url'>
    ) }
  )>> }
);

export type BizPlanFragment = (
  { __typename?: 'Restaurant' }
  & { bizPlan?: Maybe<(
    { __typename?: 'BizPlan' }
    & Pick<BizPlan, 'planId' | 'planName'>
    & { bizPlanDetails?: Maybe<(
      { __typename?: 'BizPlanType' }
      & { DINE_OUT?: Maybe<(
        { __typename?: 'BizPlanModel' }
        & Pick<BizPlanModel, 'custPctVal' | 'custPctValLower' | 'custAbsVal' | 'bizPctVal' | 'bizPctValLower' | 'bizAbsVal'>
      )>, TAKEOUT?: Maybe<(
        { __typename?: 'BizPlanModel' }
        & Pick<BizPlanModel, 'custPctVal' | 'custPctValLower' | 'custAbsVal' | 'bizPctVal' | 'bizPctValLower' | 'bizAbsVal'>
      )>, DELIVERY?: Maybe<(
        { __typename?: 'DeliveryModel' }
        & { SELF?: Maybe<(
          { __typename?: 'BizPlanModel' }
          & Pick<BizPlanModel, 'custPctVal' | 'custPctValLower' | 'custAbsVal' | 'bizPctVal' | 'bizPctValLower' | 'bizAbsVal'>
        )>, DOORDASH_CLASSIC?: Maybe<(
          { __typename?: 'BizPlanModel' }
          & Pick<BizPlanModel, 'custPctVal' | 'custPctValLower' | 'custAbsVal' | 'bizPctVal' | 'bizPctValLower' | 'bizAbsVal'>
        )> }
      )> }
    )> }
  )> }
);

export type ApplyCryptoCouponMutationVariables = {
  input: ApplyCryptoCouponInput;
};


export type ApplyCryptoCouponMutation = (
  { __typename?: 'Mutation' }
  & { applyCryptoCoupon: (
    { __typename?: 'ApplyCryptoCouponOutput' }
    & Pick<ApplyCryptoCouponOutput, 'success' | 'error' | 'token'>
    & { coupon?: Maybe<(
      { __typename?: 'CryptoCoupon' }
      & Pick<CryptoCoupon, 'bizId' | 'code' | 'id' | 'createdAt' | 'name' | 'description' | 'discountType' | 'discountValue' | 'maxDiscount' | 'startDate' | 'endDate' | 'minSpending' | 'maxUse' | 'isPrivate' | 'isSingleUse' | 'orderType' | 'dayOfWeek' | 'consumerReward' | 'cryptoUnit'>
    )> }
  ) }
);

export type DeliveryCostEstimateRequestMutationVariables = {
  input: DeliveryCostEstimateRequestInput;
};


export type DeliveryCostEstimateRequestMutation = (
  { __typename?: 'Mutation' }
  & { deliveryCostEstimateRequest: (
    { __typename?: 'DeliveryCostEstimateOutput' }
    & Pick<DeliveryCostEstimateOutput, 'fee' | 'distance' | 'duration' | 'quote_id'>
  ) }
);

export type DeliveryCostEstimateRequestsMutationVariables = {
  input: DeliveryCostEstimateRequestInput;
};


export type DeliveryCostEstimateRequestsMutation = (
  { __typename?: 'Mutation' }
  & { deliveryCostEstimateRequest: (
    { __typename?: 'DeliveryCostEstimateOutput' }
    & Pick<DeliveryCostEstimateOutput, 'quote_id' | 'doordashFee' | 'doordashDeliveryTime'>
  ) }
);

export type CreateDishMutationVariables = {
  input: CreateDishInput;
};


export type CreateDishMutation = (
  { __typename?: 'Mutation' }
  & { createDish: (
    { __typename?: 'Dish' }
    & Pick<Dish, 'id' | 'status'>
    & { title?: Maybe<Array<Maybe<(
      { __typename?: 'MultiLangString' }
      & Pick<MultiLangString, 'text'>
    )>>>, category: (
      { __typename?: 'DishCategory' }
      & { title?: Maybe<Array<Maybe<(
        { __typename?: 'MultiLangString' }
        & Pick<MultiLangString, 'text'>
      )>>> }
    ), description?: Maybe<Array<Maybe<(
      { __typename?: 'MultiLangString' }
      & Pick<MultiLangString, 'text'>
    )>>>, price?: Maybe<Array<(
      { __typename?: 'DishPrice' }
      & Pick<DishPrice, 'isDefault'>
      & { size?: Maybe<Array<(
        { __typename?: 'MultiLangString' }
        & Pick<MultiLangString, 'text'>
      )>>, price: (
        { __typename?: 'Price' }
        & Pick<Price, 'currencyCode' | 'intValue' | 'shift'>
      ) }
    )>>, images?: Maybe<Array<(
      { __typename?: 'MultiSizeImage' }
      & Pick<MultiSizeImage, 'id'>
      & { md: (
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'url'>
      ) }
    )>> }
  ) }
);

export type UpdateDishMutationVariables = {
  input: UpdateDishInput;
};


export type UpdateDishMutation = (
  { __typename?: 'Mutation' }
  & { updateDish: (
    { __typename?: 'Dish' }
    & Pick<Dish, 'id' | 'status'>
    & { title?: Maybe<Array<Maybe<(
      { __typename?: 'MultiLangString' }
      & Pick<MultiLangString, 'text'>
    )>>>, category: (
      { __typename?: 'DishCategory' }
      & { title?: Maybe<Array<Maybe<(
        { __typename?: 'MultiLangString' }
        & Pick<MultiLangString, 'text'>
      )>>> }
    ), description?: Maybe<Array<Maybe<(
      { __typename?: 'MultiLangString' }
      & Pick<MultiLangString, 'text'>
    )>>>, price?: Maybe<Array<(
      { __typename?: 'DishPrice' }
      & Pick<DishPrice, 'isDefault'>
      & { size?: Maybe<Array<(
        { __typename?: 'MultiLangString' }
        & Pick<MultiLangString, 'text'>
      )>>, price: (
        { __typename?: 'Price' }
        & Pick<Price, 'currencyCode' | 'intValue' | 'shift'>
      ) }
    )>>, images?: Maybe<Array<(
      { __typename?: 'MultiSizeImage' }
      & Pick<MultiSizeImage, 'id'>
      & { md: (
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'url'>
      ) }
    )>> }
  ) }
);

export type DeleteDishMutationVariables = {
  input: DeleteDishInput;
};


export type DeleteDishMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDish'>
);

export type CreateReviewMutationVariables = {
  input: CreateReviewInput;
};


export type CreateReviewMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createReview'>
);

export type GroceryDeliveryEstimateRequestMutationVariables = {
  input: GroceryDeliveryEstimateInput;
};


export type GroceryDeliveryEstimateRequestMutation = (
  { __typename?: 'Mutation' }
  & { groceryDeliveryEstimateRequest: (
    { __typename?: 'GroceryDeliveryEstimateOutput' }
    & Pick<GroceryDeliveryEstimateOutput, 'quote_id' | 'doordashFee' | 'doordashDeliveryTime'>
  ) }
);

export type CreateGroceryOrderMutationVariables = {
  input: CreateGroceryOrderInput;
};


export type CreateGroceryOrderMutation = (
  { __typename?: 'Mutation' }
  & { createGroceryOrder: (
    { __typename?: 'CreateGroceryOrderOutput' }
    & Pick<CreateGroceryOrderOutput, 'orderId' | 'requestId' | 'redirectUrl'>
  ) }
);

export type AddToGroceryStoreCartMutationVariables = {
  input: AddToGroceryStoreCartInput;
};


export type AddToGroceryStoreCartMutation = (
  { __typename?: 'Mutation' }
  & { AddToGroceryStoreCart: (
    { __typename?: 'GroceryStoreCart' }
    & Pick<GroceryStoreCart, 'redeemedPoints'>
    & { items?: Maybe<Array<(
      { __typename?: 'GroceryStoreCartItem' }
      & Pick<GroceryStoreCartItem, 'quantity'>
      & { item: (
        { __typename?: 'GroceryStoreItem' }
        & StoreItemFragmentFragment
      ) }
    )>>, rewardItems?: Maybe<Array<(
      { __typename?: 'GroceryStoreCartItem' }
      & Pick<GroceryStoreCartItem, 'quantity'>
      & { item: (
        { __typename?: 'GroceryStoreItem' }
        & StoreItemFragmentFragment
      ) }
    )>> }
  ) }
);

export type CreateImageUploadUrlMutationVariables = {
  input: CreateImageUploadUrlInput;
};


export type CreateImageUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { createImageUploadUrl: (
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'usage' | 'url'>
  ) }
);

export type CreateOrderMutationVariables = {
  input: CreateOrderInput;
};


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder: (
    { __typename?: 'CreateOrderOutput' }
    & Pick<CreateOrderOutput, 'orderId' | 'requestId' | 'redirectUrl'>
  ) }
);

export type CreateStripeSetupIntentMutationVariables = {
  input: CreateStripeSetupIntentInput;
};


export type CreateStripeSetupIntentMutation = (
  { __typename?: 'Mutation' }
  & { createStripeSetupIntent: (
    { __typename?: 'CreateStripeSetupIntentOutput' }
    & Pick<CreateStripeSetupIntentOutput, 'clientSecret'>
  ) }
);

export type CreateTableServiceOrderMutationVariables = {
  input: CreateTableServiceOrderInput;
};


export type CreateTableServiceOrderMutation = (
  { __typename?: 'Mutation' }
  & { createTableServiceOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type ResetCryptoCouponMutationVariables = {
  input: ResetCryptoCouponInput;
};


export type ResetCryptoCouponMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetCryptoCoupon'>
);

export type SetUserDealOptOutMutationVariables = {
  input: SetUserDealOptOutInput;
};


export type SetUserDealOptOutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setUserDealOptOut'>
);

export type AddFeedbackMutationVariables = {
  input: AddUserFeedBackInput;
};


export type AddFeedbackMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addUserFeedBack'>
);

export type UpdateUserOrderPhoneAndOrderEmailMutationVariables = {
  input: UpdateUserOrderPhoneAndOrderEmailInput;
};


export type UpdateUserOrderPhoneAndOrderEmailMutation = (
  { __typename?: 'Mutation' }
  & { updateUserOrderPhoneAndOrderEmail?: Maybe<(
    { __typename?: 'LokobeeUser' }
    & Pick<LokobeeUser, 'uid' | 'email' | 'orderEmail' | 'orderPhoneNumber'>
  )> }
);

export type VerifyCouponQueryVariables = {
  input?: Maybe<VerifyCouponInput>;
};


export type VerifyCouponQuery = (
  { __typename?: 'Query' }
  & { verifyCoupon: (
    { __typename?: 'VerifyCouponOutput' }
    & Pick<VerifyCouponOutput, 'success' | 'error' | 'token'>
    & { coupon?: Maybe<(
      { __typename?: 'Coupon' }
      & Pick<Coupon, 'id' | 'discountType' | 'discountValue' | 'taxMethod' | 'code' | 'subtotalThreshold' | 'maxDiscount' | 'isPrivate' | 'orderType' | 'dayOfWeek' | 'isSingleUse' | 'startDate' | 'endDate'>
    )> }
  ) }
);

export type VerifyGroceryCouponQueryQueryVariables = {
  input?: Maybe<VerifyGroceryCouponInput>;
};


export type VerifyGroceryCouponQueryQuery = (
  { __typename?: 'Query' }
  & { verifyGroceryCoupon: (
    { __typename?: 'VerifyGroceryCouponOutput' }
    & Pick<VerifyGroceryCouponOutput, 'success' | 'error' | 'token'>
    & { coupon?: Maybe<(
      { __typename?: 'GroceryCoupon' }
      & Pick<GroceryCoupon, 'id' | 'discountType' | 'discountValue' | 'code' | 'subtotalThreshold' | 'maxDiscount' | 'isPrivate' | 'orderType' | 'dayOfWeek' | 'startDate' | 'endDate'>
    )> }
  ) }
);

export type GetCryptoCouponsByBizIdQueryVariables = {
  input: GetCryptoCouponsByBizIdInput;
};


export type GetCryptoCouponsByBizIdQuery = (
  { __typename?: 'Query' }
  & { getCryptoCouponsByBizId: Array<(
    { __typename?: 'CryptoCoupon' }
    & CryptoCouponFragment
  )> }
);

export type GetCouponByCouponIdQueryVariables = {
  input: GetCouponByCouponIdInput;
};


export type GetCouponByCouponIdQuery = (
  { __typename?: 'Query' }
  & { getCouponByCouponId?: Maybe<(
    { __typename?: 'CryptoCoupon' }
    & CryptoCouponFragment
  )> }
);

export type GetDishesQueryVariables = {
  activeDishesInput: GetDishesInput;
  hiddenDishesInput: GetDishesInput;
};


export type GetDishesQuery = (
  { __typename?: 'Query' }
  & { activeDishes: (
    { __typename?: 'DishConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'DishConnectionEdge' }
      & { node: (
        { __typename?: 'Dish' }
        & DishFragment
      ) }
    )>> }
  ), hiddenDishes: (
    { __typename?: 'DishConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'DishConnectionEdge' }
      & { node: (
        { __typename?: 'Dish' }
        & DishFragment
      ) }
    )>> }
  ) }
);

export type GetActiveDishesQueryVariables = {
  input: GetDishesInput;
};


export type GetActiveDishesQuery = (
  { __typename?: 'Query' }
  & { getDishes: (
    { __typename?: 'DishConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'DishConnectionEdge' }
      & { node: (
        { __typename?: 'Dish' }
        & DishFragment
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type GetDishQueryVariables = {
  input: GetDishInput;
};


export type GetDishQuery = (
  { __typename?: 'Query' }
  & { getDish: (
    { __typename?: 'Dish' }
    & { restaurant: (
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id' | 'hasStripeAccount' | 'lokobeeFee'>
    ), extraGroups?: Maybe<Array<(
      { __typename?: 'DishExtraGroup' }
      & Pick<DishExtraGroup, 'id' | 'minSelect' | 'maxSelect' | 'multiQtyPerItem' | 'isOptional'>
      & { title?: Maybe<Array<(
        { __typename?: 'MultiLangString' }
        & Pick<MultiLangString, 'text'>
      )>>, items: Array<(
        { __typename?: 'DishExtraItem' }
        & Pick<DishExtraItem, 'id' | 'disabled'>
        & { title?: Maybe<Array<(
          { __typename?: 'MultiLangString' }
          & Pick<MultiLangString, 'text'>
        )>>, price: (
          { __typename?: 'Price' }
          & Pick<Price, 'intValue' | 'shift' | 'currencyCode'>
        ) }
      )> }
    )>>, pizzaCrust?: Maybe<Array<(
      { __typename?: 'PizzaCrust' }
      & Pick<PizzaCrust, 'id' | 'title' | 'description' | 'isDefault'>
      & { sizes: Array<(
        { __typename?: 'PizzaSize' }
        & Pick<PizzaSize, 'id' | 'title' | 'enableHalfHalf'>
      )>, prices: Array<(
        { __typename?: 'PizzaCrustPrice' }
        & { size: (
          { __typename?: 'PizzaSize' }
          & Pick<PizzaSize, 'id'>
        ), price: (
          { __typename?: 'Price' }
          & Pick<Price, 'intValue' | 'shift'>
        ) }
      )> }
    )>>, pizzaToppingGroups?: Maybe<Array<(
      { __typename?: 'PizzaToppingGroup' }
      & Pick<PizzaToppingGroup, 'id' | 'title' | 'description'>
      & { items: Array<(
        { __typename?: 'PizzaToppingItem' }
        & Pick<PizzaToppingItem, 'id' | 'title' | 'description' | 'preset'>
        & { prices: Array<(
          { __typename?: 'PizzaToppingPrice' }
          & Pick<PizzaToppingPrice, 'id' | 'densities'>
          & { size: (
            { __typename?: 'PizzaSize' }
            & Pick<PizzaSize, 'id' | 'title' | 'enableHalfHalf'>
          ), densityPrices: Array<(
            { __typename?: 'PizzaToppingDensityPrice' }
            & Pick<PizzaToppingDensityPrice, 'density'>
            & { price: (
              { __typename?: 'Price' }
              & Pick<Price, 'intValue' | 'shift'>
            ), partPrice: (
              { __typename?: 'Price' }
              & Pick<Price, 'intValue' | 'shift'>
            ) }
          )> }
        )> }
      )> }
    )>>, pizzaSauce?: Maybe<(
      { __typename?: 'PizzaToppingGroup' }
      & Pick<PizzaToppingGroup, 'id' | 'title' | 'description'>
      & { items: Array<(
        { __typename?: 'PizzaToppingItem' }
        & Pick<PizzaToppingItem, 'id' | 'title' | 'description' | 'preset'>
        & { prices: Array<(
          { __typename?: 'PizzaToppingPrice' }
          & Pick<PizzaToppingPrice, 'id' | 'densities'>
          & { size: (
            { __typename?: 'PizzaSize' }
            & Pick<PizzaSize, 'id' | 'title' | 'enableHalfHalf'>
          ), densityPrices: Array<(
            { __typename?: 'PizzaToppingDensityPrice' }
            & Pick<PizzaToppingDensityPrice, 'density'>
            & { price: (
              { __typename?: 'Price' }
              & Pick<Price, 'intValue' | 'shift'>
            ), partPrice: (
              { __typename?: 'Price' }
              & Pick<Price, 'intValue' | 'shift'>
            ) }
          )> }
        )> }
      )> }
    )>, pizzaCheese?: Maybe<(
      { __typename?: 'PizzaToppingGroup' }
      & Pick<PizzaToppingGroup, 'id' | 'title' | 'description'>
      & { items: Array<(
        { __typename?: 'PizzaToppingItem' }
        & Pick<PizzaToppingItem, 'id' | 'title' | 'description' | 'preset'>
        & { prices: Array<(
          { __typename?: 'PizzaToppingPrice' }
          & Pick<PizzaToppingPrice, 'id' | 'densities'>
          & { size: (
            { __typename?: 'PizzaSize' }
            & Pick<PizzaSize, 'id' | 'title' | 'enableHalfHalf'>
          ), densityPrices: Array<(
            { __typename?: 'PizzaToppingDensityPrice' }
            & Pick<PizzaToppingDensityPrice, 'density'>
            & { price: (
              { __typename?: 'Price' }
              & Pick<Price, 'intValue' | 'shift'>
            ), partPrice: (
              { __typename?: 'Price' }
              & Pick<Price, 'intValue' | 'shift'>
            ) }
          )> }
        )> }
      )> }
    )> }
    & DishFragment
  ) }
);

export type GetDishCategoriesOfRestaurantQueryVariables = {
  input: GetDishCategoriesOfRestaurantInput;
};


export type GetDishCategoriesOfRestaurantQuery = (
  { __typename?: 'Query' }
  & { getDishCategoriesOfRestaurant?: Maybe<Array<(
    { __typename?: 'DishCategory' }
    & Pick<DishCategory, 'id'>
    & { title?: Maybe<Array<Maybe<(
      { __typename?: 'MultiLangString' }
      & Pick<MultiLangString, 'text'>
    )>>>, dishes?: Maybe<Array<(
      { __typename?: 'Dish' }
      & Pick<Dish, 'status'>
    )>> }
  )>> }
);

export type GetAllActiveDishesQueryVariables = {
  input: GetAllDishesInput;
};


export type GetAllActiveDishesQuery = (
  { __typename?: 'Query' }
  & { getAllDishes?: Maybe<Array<(
    { __typename?: 'Dish' }
    & DishFragment
  )>> }
);

export type GetRewardItemsQueryVariables = {
  input: GetRewardItemsInput;
};


export type GetRewardItemsQuery = (
  { __typename?: 'Query' }
  & { getRewardItems?: Maybe<Array<(
    { __typename?: 'Dish' }
    & DishFragment
  )>> }
);

export type GetBuyerDeliveryOrdersQueryVariables = {
  input: GetBuyerOngoingOrdersInput;
};


export type GetBuyerDeliveryOrdersQuery = (
  { __typename?: 'Query' }
  & { getBuyerOngoingOrders?: Maybe<Array<Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'orderNumber' | 'type' | 'deliveryProvider' | 'noOfBags' | 'specialHandlingInstruction' | 'cookingTimeMins' | 'expectTime' | 'deliveryStatusBoons'>
    & { deliveryTimelineBoons?: Maybe<Array<Maybe<(
      { __typename?: 'DeliveryOrderBoonsTimeLine' }
      & Pick<DeliveryOrderBoonsTimeLine, 'id' | 'createdAt' | 'status' | 'title' | 'msg'>
    )>>> }
  )>>> }
);

export type GetGoogleApiRestrictionQueryVariables = {
  input: GetGoogleApiRestrictionInput;
};


export type GetGoogleApiRestrictionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGoogleApiRestriction'>
);

export type GetGroceryStoresByLocationQueryVariables = {
  input: GetGroceryStoresNearbyInput;
};


export type GetGroceryStoresByLocationQuery = (
  { __typename?: 'Query' }
  & { getGroceryStoresByLocation: (
    { __typename?: 'GroceryStoreConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'GroceryStoreConnectionEdge' }
      & { node: (
        { __typename?: 'GroceryStore' }
        & Pick<GroceryStore, 'id' | 'name' | 'timezone'>
        & { logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'url'>
        )>, address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'lat' | 'lng'>
        )>, taxModes?: Maybe<Array<(
          { __typename?: 'TaxMode' }
          & Pick<TaxMode, 'title'>
          & { tax: (
            { __typename?: 'TaxRate' }
            & Pick<TaxRate, 'intValue' | 'shift'>
          ) }
        )>> }
      ) }
    )>> }
  ) }
);

export type GetGroceryStoreQueryVariables = {
  input: GetGroceryStoreInput;
};


export type GetGroceryStoreQuery = (
  { __typename?: 'Query' }
  & { getGroceryStore: (
    { __typename?: 'GroceryStore' }
    & Pick<GroceryStore, 'id' | 'phone' | 'description' | 'announcement' | 'name' | 'timezone' | 'takeoutWaitMinutes' | 'enableTakeoutOrder' | 'enableDeliveryOrder' | 'lokobeeFee' | 'lokobeeFeeLimit' | 'lokobeeFeePercent' | 'hasStripeAccount' | 'issueRewardPoint' | 'deliveryProvider' | 'deliveryMode'>
    & { logo?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'url'>
    )>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'text' | 'city' | 'street' | 'zipcode' | 'lat' | 'lng'>
    )>, coupons?: Maybe<Array<(
      { __typename?: 'GroceryCoupon' }
      & Pick<GroceryCoupon, 'dayOfWeek' | 'orderType' | 'code' | 'discountType' | 'discountValue' | 'maxUse' | 'startDate' | 'endDate' | 'maxDiscount' | 'name' | 'subtotalThreshold' | 'useCnt' | 'description'>
    )>>, bizHours?: Maybe<Array<(
      { __typename?: 'GroceryBizHour' }
      & Pick<GroceryBizHour, 'serviceType' | 'serviceName'>
      & { hours: Array<(
        { __typename?: 'BizHourSlot' }
        & Pick<BizHourSlot, 'openDay' | 'openHour' | 'openMinute' | 'closeHour' | 'closeMinute' | 'closeDay'>
      )> }
    )>>, taxModes?: Maybe<Array<(
      { __typename?: 'TaxMode' }
      & Pick<TaxMode, 'title'>
      & { tax: (
        { __typename?: 'TaxRate' }
        & Pick<TaxRate, 'intValue' | 'shift'>
      ) }
    )>>, gallery?: Maybe<Array<(
      { __typename?: 'MultiSizeImage' }
      & { md: (
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'url'>
      ) }
    )>>, categories?: Maybe<Array<(
      { __typename?: 'GroceryStoreCategory' }
      & Pick<GroceryStoreCategory, 'id' | 'title'>
      & { subCategories?: Maybe<Array<(
        { __typename?: 'GroceryStoreSubCategory' }
        & Pick<GroceryStoreSubCategory, 'id' | 'title'>
      )>>, image?: Maybe<(
        { __typename?: 'MultiSizeImage' }
        & Pick<MultiSizeImage, 'id'>
        & { preview: (
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'url'>
        ) }
      )> }
    )>>, bizPlan?: Maybe<(
      { __typename?: 'GroceryBizPlan' }
      & Pick<GroceryBizPlan, 'planId' | 'planName'>
      & { bizPlanDetails: (
        { __typename?: 'GroceryBizPlanType' }
        & { TAKEOUT?: Maybe<(
          { __typename?: 'GroceryBizPlanModel' }
          & Pick<GroceryBizPlanModel, 'custPctVal' | 'custPctValLower' | 'custAbsVal' | 'bizPctVal' | 'bizPctValLower' | 'bizAbsVal'>
        )>, DELIVERY?: Maybe<(
          { __typename?: 'GroceryDeliveryPlanModel' }
          & { SELF?: Maybe<(
            { __typename?: 'GroceryBizPlanModel' }
            & Pick<GroceryBizPlanModel, 'custPctVal' | 'custPctValLower' | 'custAbsVal' | 'bizPctVal' | 'bizPctValLower' | 'bizAbsVal'>
          )>, DOORDASH_CLASSIC?: Maybe<(
            { __typename?: 'GroceryBizPlanModel' }
            & Pick<GroceryBizPlanModel, 'custPctVal' | 'custPctValLower' | 'custAbsVal' | 'bizPctVal' | 'bizPctValLower' | 'bizAbsVal'>
          )> }
        )> }
      ) }
    )>, takeoutPaymentSetting?: Maybe<(
      { __typename?: 'GroceryPaymentSetting' }
      & Pick<GroceryPaymentSetting, 'onlinePaymentTypes' | 'onsitePaymentTypes' | 'tipPercentOptions' | 'tipDefaultPercent'>
    )>, deliveryPaymentSetting?: Maybe<(
      { __typename?: 'GroceryPaymentSetting' }
      & Pick<GroceryPaymentSetting, 'onlinePaymentTypes' | 'onsitePaymentTypes' | 'tipPercentOptions' | 'tipDefaultPercent'>
    )>, deliverySetting?: Maybe<(
      { __typename?: 'GroceryDeliverySetting' }
      & Pick<GroceryDeliverySetting, 'waitMinutes' | 'freeDeliveryAmount' | 'minDeliveryAmount' | 'deliveryRadius'>
      & { fees: Array<(
        { __typename?: 'GroceryDeliveryFee' }
        & Pick<GroceryDeliveryFee, 'mile' | 'fee'>
      )> }
    )>, rewardSetting?: Maybe<(
      { __typename?: 'GroceryRewardSetting' }
      & Pick<GroceryRewardSetting, 'minStartPoints' | 'maxRedeemPointsPerUsage' | 'minRedeemPurchase'>
    )> }
  ) }
);

export type GetGroceryItemsByCategoryIdQueryVariables = {
  input: GetGroceryItemsByCategoryIdInput;
};


export type GetGroceryItemsByCategoryIdQuery = (
  { __typename?: 'Query' }
  & { getGroceryItemsByCategoryId?: Maybe<Array<(
    { __typename?: 'GroceryStoreItem' }
    & StoreItemFragmentFragment
  )>> }
);

export type GetGroceryRewardItemsQueryVariables = {
  input: GetGroceryRewardItemsInput;
};


export type GetGroceryRewardItemsQuery = (
  { __typename?: 'Query' }
  & { getGroceryRewardItems?: Maybe<Array<(
    { __typename?: 'GroceryStoreItem' }
    & StoreItemFragmentFragment
  )>> }
);

export type GetGroceryStoreCartQueryVariables = {
  input: GetGroceryStoreCartInput;
};


export type GetGroceryStoreCartQuery = (
  { __typename?: 'Query' }
  & { getGroceryStoreCart?: Maybe<(
    { __typename?: 'GroceryStoreCart' }
    & Pick<GroceryStoreCart, 'redeemedPoints'>
    & { items?: Maybe<Array<(
      { __typename?: 'GroceryStoreCartItem' }
      & Pick<GroceryStoreCartItem, 'quantity'>
      & { item: (
        { __typename?: 'GroceryStoreItem' }
        & StoreItemFragmentFragment
      ) }
    )>>, rewardItems?: Maybe<Array<(
      { __typename?: 'GroceryStoreCartItem' }
      & Pick<GroceryStoreCartItem, 'quantity'>
      & { item: (
        { __typename?: 'GroceryStoreItem' }
        & StoreItemFragmentFragment
      ) }
    )>> }
  )> }
);

export type QueryGrocerySearchQueryVariables = {
  input: GrocerySearchInput;
};


export type QueryGrocerySearchQuery = (
  { __typename?: 'Query' }
  & { queryGrocerySearch?: Maybe<Array<(
    { __typename?: 'GroceryStoreItem' }
    & StoreItemFragmentFragment
  )>> }
);

export type GetGroceryDiscountedItemsQueryVariables = {
  input: GetGroceryDiscountedItemsInput;
};


export type GetGroceryDiscountedItemsQuery = (
  { __typename?: 'Query' }
  & { getGroceryDiscountedItems?: Maybe<Array<(
    { __typename?: 'GroceryStoreItem' }
    & StoreItemFragmentFragment
  )>> }
);

export type GetOrderByOrderIdQueryVariables = {
  input: GetOrderByOrderIdInput;
};


export type GetOrderByOrderIdQuery = (
  { __typename?: 'Query' }
  & { getOrderByOrderId?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'status' | 'orderNumber' | 'note' | 'deliveryNote' | 'orderPhoneNumber' | 'orderEmail' | 'expectTime' | 'dropOffInstruction' | 'deliveryDistance' | 'paymentType' | 'type' | 'createdAt' | 'redeemPoints' | 'adjustReason' | 'isDiscounted'>
    & { deliveryAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'text'>
    )>, seller: (
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id' | 'phone'>
      & { name?: Maybe<Array<Maybe<(
        { __typename?: 'MultiLangString' }
        & Pick<MultiLangString, 'text'>
      )>>>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )>, subName?: Maybe<Array<Maybe<(
        { __typename?: 'MultiLangString' }
        & Pick<MultiLangString, 'text'>
      )>>>, i18n?: Maybe<(
        { __typename?: 'I18NSetting' }
        & Pick<I18NSetting, 'timezone'>
      )> }
    ), subTotal?: Maybe<(
      { __typename?: 'MoneyAmount' }
      & Pick<MoneyAmount, 'intValue' | 'shift'>
    )>, taxRate?: Maybe<(
      { __typename?: 'TaxRate' }
      & Pick<TaxRate, 'intValue' | 'shift'>
    )>, total?: Maybe<(
      { __typename?: 'MoneyAmount' }
      & Pick<MoneyAmount, 'intValue' | 'shift'>
    )>, tip?: Maybe<(
      { __typename?: 'MoneyAmount' }
      & Pick<MoneyAmount, 'intValue' | 'shift'>
    )>, tax?: Maybe<(
      { __typename?: 'MoneyAmount' }
      & Pick<MoneyAmount, 'intValue' | 'shift'>
    )>, deliveryFee?: Maybe<(
      { __typename?: 'MoneyAmount' }
      & Pick<MoneyAmount, 'intValue' | 'shift'>
    )>, couponDiscount?: Maybe<(
      { __typename?: 'MoneyAmount' }
      & Pick<MoneyAmount, 'intValue' | 'shift'>
    )>, lokobeeFee?: Maybe<(
      { __typename?: 'MoneyAmount' }
      & Pick<MoneyAmount, 'intValue' | 'shift'>
    )>, adjustAmount?: Maybe<(
      { __typename?: 'MoneyAmount' }
      & Pick<MoneyAmount, 'intValue' | 'shift'>
    )>, totalAfterAdjust?: Maybe<(
      { __typename?: 'MoneyAmount' }
      & Pick<MoneyAmount, 'intValue' | 'shift'>
    )>, coupon?: Maybe<(
      { __typename?: 'CryptoCouponInfo' }
      & Pick<CryptoCouponInfo, 'id' | 'code'>
    )>, deliveryRequest?: Maybe<(
      { __typename?: 'DeliveryRequest' }
      & { doordashClassicPayload?: Maybe<(
        { __typename?: 'DeliveryDoorDashPayload' }
        & Pick<DeliveryDoorDashPayload, 'delivery_tracking_url'>
        & { dasher?: Maybe<(
          { __typename?: 'DoordashDasher' }
          & Pick<DoordashDasher, 'first_name' | 'last_name' | 'dasher_phone_number_for_customer'>
        )> }
      )> }
    )>, deliveryTimelineBoons?: Maybe<Array<Maybe<(
      { __typename?: 'DeliveryOrderBoonsTimeLine' }
      & Pick<DeliveryOrderBoonsTimeLine, 'id' | 'createdAt' | 'deliveryTrackingId' | 'status' | 'title' | 'msg' | 'cancelledReason' | 'cancelledReasonCode' | 'delayReason' | 'delayedReasonCode'>
    )>>>, deliveryTimelineDoordashClassic?: Maybe<Array<Maybe<(
      { __typename?: 'DeliveryOrderDoordashClassicTimeLine' }
      & Pick<DeliveryOrderDoordashClassicTimeLine, 'id' | 'createdAt' | 'status' | 'title' | 'msg' | 'cancelledReason' | 'cancelledReasonCode' | 'delayReason' | 'delayedReasonCode'>
    )>>>, timeLines?: Maybe<Array<Maybe<(
      { __typename?: 'OrderTimeLine' }
      & Pick<OrderTimeLine, 'id' | 'createdAt' | 'status' | 'title' | 'msg'>
    )>>>, items?: Maybe<Array<(
      { __typename?: 'OrderLineItem' }
      & Pick<OrderLineItem, 'dishId' | 'note' | 'count' | 'dishType' | 'isAlcohol' | 'isReward' | 'isDiscounted' | 'points'>
      & { dishCategoryTitle?: Maybe<Array<Maybe<(
        { __typename?: 'MultiLangString' }
        & Pick<MultiLangString, 'text'>
      )>>>, dishTitle?: Maybe<Array<Maybe<(
        { __typename?: 'MultiLangString' }
        & Pick<MultiLangString, 'text'>
      )>>>, dishDescription?: Maybe<Array<Maybe<(
        { __typename?: 'MultiLangString' }
        & Pick<MultiLangString, 'text'>
      )>>>, dishPrice?: Maybe<(
        { __typename?: 'DishPrice' }
        & Pick<DishPrice, 'discountAmount'>
        & { price: (
          { __typename?: 'Price' }
          & Pick<Price, 'intValue' | 'shift'>
        ), size?: Maybe<Array<(
          { __typename?: 'MultiLangString' }
          & Pick<MultiLangString, 'text'>
        )>> }
      )>, dishExtras?: Maybe<Array<(
        { __typename?: 'OrderDishExtraItem' }
        & Pick<OrderDishExtraItem, 'count' | 'dishExtraGroupId'>
        & { dishExtraGroupTitle?: Maybe<Array<(
          { __typename?: 'MultiLangString' }
          & Pick<MultiLangString, 'text'>
        )>>, dishExtraItem: (
          { __typename?: 'DishExtraItem' }
          & { title?: Maybe<Array<(
            { __typename?: 'MultiLangString' }
            & Pick<MultiLangString, 'text'>
          )>>, price: (
            { __typename?: 'Price' }
            & Pick<Price, 'intValue' | 'shift'>
          ) }
        ) }
      )>>, pizzaBasePrice?: Maybe<(
        { __typename?: 'PizzaBasePrice' }
        & Pick<PizzaBasePrice, 'id' | 'discountAmount'>
        & { size: (
          { __typename?: 'PizzaSize' }
          & Pick<PizzaSize, 'id' | 'title'>
        ), price: (
          { __typename?: 'Price' }
          & Pick<Price, 'intValue' | 'shift'>
        ) }
      )>, pizzaCrust?: Maybe<(
        { __typename?: 'OrderPizzaCrust' }
        & Pick<OrderPizzaCrust, 'crustId' | 'title'>
        & { price?: Maybe<(
          { __typename?: 'Price' }
          & Pick<Price, 'intValue' | 'shift'>
        )> }
      )>, pizzaSauces?: Maybe<Array<Maybe<(
        { __typename?: 'OrderPizzaTopping' }
        & Pick<OrderPizzaTopping, 'toppingId' | 'toppingGroupId' | 'toppingGroupTitle' | 'side' | 'toppingTitle' | 'toppingDescription' | 'toppingDensity' | 'preset'>
        & { toppingPrice?: Maybe<(
          { __typename?: 'Price' }
          & Pick<Price, 'intValue' | 'shift'>
        )> }
      )>>>, pizzaCheeses?: Maybe<Array<Maybe<(
        { __typename?: 'OrderPizzaTopping' }
        & Pick<OrderPizzaTopping, 'toppingId' | 'toppingGroupId' | 'toppingGroupTitle' | 'side' | 'toppingTitle' | 'toppingDescription' | 'toppingDensity' | 'preset'>
        & { toppingPrice?: Maybe<(
          { __typename?: 'Price' }
          & Pick<Price, 'intValue' | 'shift'>
        )> }
      )>>>, pizzaToppings?: Maybe<Array<Maybe<(
        { __typename?: 'OrderPizzaTopping' }
        & Pick<OrderPizzaTopping, 'toppingId' | 'toppingGroupId' | 'toppingGroupTitle' | 'side' | 'toppingTitle' | 'toppingDescription' | 'toppingDensity' | 'preset'>
        & { toppingPrice?: Maybe<(
          { __typename?: 'Price' }
          & Pick<Price, 'intValue' | 'shift'>
        )> }
      )>>> }
    )>> }
  )> }
);

export type GetBuyerOrdersQueryVariables = {
  input: GetBuyerOrdersInput;
};


export type GetBuyerOrdersQuery = (
  { __typename?: 'Query' }
  & { getBuyerOrders?: Maybe<(
    { __typename?: 'OrderConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'OrderConnectionEdge' }
      & { node: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'isRated' | 'createdAt' | 'expectTime' | 'note' | 'orderNumber' | 'deliveryProvider' | 'deliveryMode' | 'partySize' | 'paymentType' | 'status' | 'type' | 'redeemPoints'>
        & { buyer: (
          { __typename?: 'LokobeeUser' }
          & Pick<LokobeeUser, 'displayName'>
        ), deliveryAddress?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'text'>
        )>, items?: Maybe<Array<(
          { __typename?: 'OrderLineItem' }
          & Pick<OrderLineItem, 'dishId' | 'count'>
          & { dishTitle?: Maybe<Array<Maybe<(
            { __typename?: 'MultiLangString' }
            & Pick<MultiLangString, 'text'>
          )>>> }
        )>>, seller: (
          { __typename?: 'Restaurant' }
          & Pick<Restaurant, 'id'>
          & { name?: Maybe<Array<Maybe<(
            { __typename?: 'MultiLangString' }
            & Pick<MultiLangString, 'text'>
          )>>>, logo?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )>, i18n?: Maybe<(
            { __typename?: 'I18NSetting' }
            & Pick<I18NSetting, 'timezone'>
          )> }
        ), total?: Maybe<(
          { __typename?: 'MoneyAmount' }
          & Pick<MoneyAmount, 'intValue' | 'shift'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPrevPage' | 'endCursor'>
    ) }
  )> }
);

export type GetBuyerGroceryOrdersQueryVariables = {
  input: GetGroceryBuyerOrdersInput;
};


export type GetBuyerGroceryOrdersQuery = (
  { __typename?: 'Query' }
  & { getBuyerGroceryOrders?: Maybe<(
    { __typename?: 'GroceryOrderConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'GroceryOrderConnectionEdge' }
      & { node: (
        { __typename?: 'GroceryOrder' }
        & Pick<GroceryOrder, 'id' | 'isRated' | 'createdAt' | 'expectTime' | 'note' | 'orderNumber' | 'paymentType' | 'status' | 'type' | 'redeemPoints' | 'total' | 'deliveryProvider'>
        & { buyer: (
          { __typename?: 'LokobeeUser' }
          & Pick<LokobeeUser, 'displayName'>
        ), deliveryAddress?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'text'>
        )>, items?: Maybe<Array<(
          { __typename?: 'GroceryOrderLineItem' }
          & Pick<GroceryOrderLineItem, 'title' | 'itemId' | 'count'>
        )>>, seller: (
          { __typename?: 'GroceryStore' }
          & Pick<GroceryStore, 'id' | 'name' | 'timezone'>
          & { logo?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPrevPage' | 'endCursor'>
    ) }
  )> }
);

export type GetGroceryOrderByOrderIdQueryVariables = {
  input: GetGroceryOrderByOrderIdInput;
};


export type GetGroceryOrderByOrderIdQuery = (
  { __typename?: 'Query' }
  & { getGroceryOrderByOrderId?: Maybe<(
    { __typename?: 'GroceryOrder' }
    & Pick<GroceryOrder, 'id' | 'status' | 'expectTime' | 'orderNumber' | 'note' | 'lokobeeFee' | 'totalTax' | 'deliveryNote' | 'adjustReason' | 'isDiscounted' | 'redeemPoints' | 'deliveryDistance' | 'orderEmail' | 'orderPhoneNumber' | 'paymentType' | 'type' | 'createdAt' | 'subTotal' | 'total' | 'tip' | 'deliveryFee' | 'couponDiscount' | 'adjustAmount' | 'totalAfterAdjust'>
    & { seller: (
      { __typename?: 'GroceryStore' }
      & Pick<GroceryStore, 'id' | 'name' | 'phone'>
      & { logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )>, address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'text'>
      )> }
    ), deliveryAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'text'>
    )>, timeLines?: Maybe<Array<Maybe<(
      { __typename?: 'GroceryOrderTimeLine' }
      & Pick<GroceryOrderTimeLine, 'id' | 'createdAt' | 'msg' | 'status'>
    )>>>, doordashDeliveryTimeline?: Maybe<Array<Maybe<(
      { __typename?: 'GroceryDoordashDeliveryTimeline' }
      & Pick<GroceryDoordashDeliveryTimeline, 'id' | 'createdAt' | 'reason' | 'status'>
    )>>>, buyer: (
      { __typename?: 'LokobeeUser' }
      & Pick<LokobeeUser, 'phoneNumber' | 'email' | 'displayName'>
    ), coupon?: Maybe<(
      { __typename?: 'GroceryCoupon' }
      & Pick<GroceryCoupon, 'code'>
    )>, items?: Maybe<Array<(
      { __typename?: 'GroceryOrderLineItem' }
      & Pick<GroceryOrderLineItem, 'itemId' | 'title' | 'upcCode' | 'size' | 'categoryId' | 'subCategoryId' | 'brand' | 'price' | 'status' | 'description' | 'taxMode' | 'count' | 'isReward' | 'isDiscounted' | 'isAlcohol'>
    )>> }
  )> }
);

export type GetRestaurantsByLocationQueryVariables = {
  input: GetRestaurantsNearbyInput;
};


export type GetRestaurantsByLocationQuery = (
  { __typename?: 'Query' }
  & { getRestaurantsByLocation: (
    { __typename?: 'RestaurantConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'RestaurantConnectionEdge' }
      & Pick<RestaurantConnectionEdge, 'cursor'>
      & { node: (
        { __typename?: 'Restaurant' }
        & Pick<Restaurant, 'id' | 'rateScore' | 'rateCount' | 'issueRewardPoint' | 'enableTakeoutOrder' | 'enableDineInOrder' | 'enableDeliveryOrder' | 'enableDineOutOrder' | 'cryptoCouponEnabled' | 'cryptoCouponExists'>
        & { name?: Maybe<Array<Maybe<(
          { __typename?: 'MultiLangString' }
          & Pick<MultiLangString, 'text'>
        )>>>, subName?: Maybe<Array<Maybe<(
          { __typename?: 'MultiLangString' }
          & Pick<MultiLangString, 'text'>
        )>>>, description?: Maybe<Array<Maybe<(
          { __typename?: 'MultiLangString' }
          & Pick<MultiLangString, 'text'>
        )>>>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'url'>
        )>, images?: Maybe<Array<(
          { __typename?: 'MultiSizeImage' }
          & Pick<MultiSizeImage, 'id'>
          & { md: (
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'url'>
          ), preview: (
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'url'>
          ) }
        )>>, address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'lat' | 'lng'>
        )>, i18n?: Maybe<(
          { __typename?: 'I18NSetting' }
          & Pick<I18NSetting, 'timezone'>
        )>, coupons?: Maybe<Array<(
          { __typename?: 'Coupon' }
          & Pick<Coupon, 'code' | 'discountType' | 'discountValue' | 'maxUse' | 'startDate' | 'endDate' | 'maxDiscount'>
        )>> }
      ) }
    )>> }
  ) }
);

export type GetRestaurantsByLocationWithUserRewardsQueryVariables = {
  input: GetRestaurantsNearbyInput;
};


export type GetRestaurantsByLocationWithUserRewardsQuery = (
  { __typename?: 'Query' }
  & { getRestaurantsByLocation: (
    { __typename?: 'RestaurantConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'RestaurantConnectionEdge' }
      & Pick<RestaurantConnectionEdge, 'cursor'>
      & { node: (
        { __typename?: 'Restaurant' }
        & Pick<Restaurant, 'id' | 'issueRewardPoint'>
        & { name?: Maybe<Array<Maybe<(
          { __typename?: 'MultiLangString' }
          & Pick<MultiLangString, 'text'>
        )>>>, description?: Maybe<Array<Maybe<(
          { __typename?: 'MultiLangString' }
          & Pick<MultiLangString, 'text'>
        )>>>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'url'>
        )>, address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'lat' | 'lng'>
        )>, coupons?: Maybe<Array<(
          { __typename?: 'Coupon' }
          & Pick<Coupon, 'code' | 'discountType' | 'discountValue' | 'maxUse' | 'startDate' | 'endDate' | 'maxDiscount'>
        )>> }
      ) }
    )>> }
  ), getRewardPointsForConsumer?: Maybe<Array<(
    { __typename?: 'ConsumerRewardPoint' }
    & Pick<ConsumerRewardPoint, 'id' | 'issuer' | 'activePoints'>
  )>> }
);

export type GetRestaurantIdQueryVariables = {
  input: GetRestaurantInput;
};


export type GetRestaurantIdQuery = (
  { __typename?: 'Query' }
  & { getRestaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id'>
  ) }
);

export type GetRestaurantProfileQueryVariables = {
  input: GetRestaurantInput;
};


export type GetRestaurantProfileQuery = (
  { __typename?: 'Query' }
  & { getRestaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id'>
    & ProfileFragment
  ) }
);

export type GetRestaurantBusinsessHoursQueryVariables = {
  input: GetRestaurantInput;
};


export type GetRestaurantBusinsessHoursQuery = (
  { __typename?: 'Query' }
  & { getRestaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id'>
    & BusinessHoursFragment
  ) }
);

export type GetRestaurantTablesQueryVariables = {
  input: GetRestaurantInput;
};


export type GetRestaurantTablesQuery = (
  { __typename?: 'Query' }
  & { getRestaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id'>
    & TableFragment
  ) }
);

export type GetRestaurantGalleryQueryVariables = {
  input: GetRestaurantInput;
};


export type GetRestaurantGalleryQuery = (
  { __typename?: 'Query' }
  & { getRestaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id'>
    & GalleryFragment
  ) }
);

export type GetRestaurantAnnouncementQueryVariables = {
  input: GetRestaurantInput;
};


export type GetRestaurantAnnouncementQuery = (
  { __typename?: 'Query' }
  & { getRestaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id'>
    & AnnouncementFragment
  ) }
);

export type GetRestaurantRewardSettingQueryVariables = {
  input: GetRestaurantInput;
};


export type GetRestaurantRewardSettingQuery = (
  { __typename?: 'Query' }
  & { getRestaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id' | 'issueRewardPoint'>
    & RewardSettingFragment
  ) }
);

export type GetRestaurantQueryVariables = {
  input: GetRestaurantInput;
};


export type GetRestaurantQuery = (
  { __typename?: 'Query' }
  & { getRestaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id' | 'isLokobeePartner' | 'lokobeeFeePercent' | 'lokobeeFeeLimit' | 'issueRewardPoint' | 'enableDishNote' | 'takeoutWaitMinutes' | 'enableTakeoutOrder' | 'enableDineInOrder' | 'enableDineOutOrder' | 'enableDeliveryOrder' | 'hasStripeAccount' | 'lokobeeFee' | 'deliveryMethod' | 'deliveryProviders'>
    & { takeoutPaymentSetting?: Maybe<(
      { __typename?: 'PaymentSetting' }
      & Pick<PaymentSetting, 'tipPercentOptions' | 'tipDefaultPercent' | 'onlinePaymentTypes' | 'onsitePaymentTypes'>
    )>, dineoutPaymentSetting?: Maybe<(
      { __typename?: 'PaymentSetting' }
      & Pick<PaymentSetting, 'tipPercentOptions' | 'tipDefaultPercent' | 'onlinePaymentTypes' | 'onsitePaymentTypes'>
    )>, deliveryPaymentSetting?: Maybe<(
      { __typename?: 'PaymentSetting' }
      & Pick<PaymentSetting, 'tipPercentOptions' | 'tipDefaultPercent' | 'onlinePaymentTypes' | 'onsitePaymentTypes'>
    )>, deliverySetting?: Maybe<(
      { __typename?: 'DeliverySetting' }
      & Pick<DeliverySetting, 'waitMinutes' | 'deliveryRadius'>
      & { freeDeliveryAmount?: Maybe<(
        { __typename?: 'MoneyAmount' }
        & Pick<MoneyAmount, 'intValue' | 'shift'>
      )>, minDeliveryAmount?: Maybe<(
        { __typename?: 'MoneyAmount' }
        & Pick<MoneyAmount, 'intValue' | 'shift'>
      )>, fees: Array<(
        { __typename?: 'DeliveryFee' }
        & Pick<DeliveryFee, 'mile'>
        & { fee: (
          { __typename?: 'MoneyAmount' }
          & Pick<MoneyAmount, 'intValue' | 'shift'>
        ) }
      )> }
    )> }
    & AnnouncementFragment
    & ProfileFragment
    & BusinessHoursFragment
    & GalleryFragment
    & RewardSettingFragment
    & BizPlanFragment
  ) }
);

export type GetRestaurantProfileAndBizHoursQueryVariables = {
  input: GetRestaurantInput;
};


export type GetRestaurantProfileAndBizHoursQuery = (
  { __typename?: 'Query' }
  & { getRestaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id'>
    & ProfileFragment
    & BusinessHoursFragment
  ) }
);

export type GetRestaurantTakeOutPaymentSettingQueryVariables = {
  input: GetRestaurantInput;
};


export type GetRestaurantTakeOutPaymentSettingQuery = (
  { __typename?: 'Query' }
  & { getRestaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id'>
    & { takeoutPaymentSetting?: Maybe<(
      { __typename?: 'PaymentSetting' }
      & Pick<PaymentSetting, 'tipPercentOptions' | 'tipDefaultPercent' | 'onlinePaymentTypes' | 'onsitePaymentTypes'>
    )>, dineoutPaymentSetting?: Maybe<(
      { __typename?: 'PaymentSetting' }
      & Pick<PaymentSetting, 'tipPercentOptions' | 'tipDefaultPercent' | 'onlinePaymentTypes' | 'onsitePaymentTypes'>
    )>, deliveryPaymentSetting?: Maybe<(
      { __typename?: 'PaymentSetting' }
      & Pick<PaymentSetting, 'tipPercentOptions' | 'tipDefaultPercent' | 'onlinePaymentTypes' | 'onsitePaymentTypes'>
    )> }
  ) }
);

export type GetCouponsQueryVariables = {
  input: GetCouponsByBizIdInput;
};


export type GetCouponsQuery = (
  { __typename?: 'Query' }
  & { getCoupons?: Maybe<Array<(
    { __typename?: 'Coupon' }
    & Pick<Coupon, 'code' | 'name' | 'subtotalThreshold' | 'orderType' | 'dayOfWeek' | 'discountType' | 'discountValue' | 'maxUse' | 'useCnt' | 'startDate' | 'isSingleUse' | 'endDate' | 'description' | 'maxDiscount'>
  )>> }
);

export type GetTablesQueryVariables = {
  input: GetTablesInput;
};


export type GetTablesQuery = (
  { __typename?: 'Query' }
  & { getTables?: Maybe<Array<(
    { __typename?: 'Table' }
    & Pick<Table, 'tableName' | 'seats'>
  )>> }
);

export type GetTableServicesQueryVariables = {
  input: GetTableServicesInput;
};


export type GetTableServicesQuery = (
  { __typename?: 'Query' }
  & { getTableServices?: Maybe<(
    { __typename?: 'DishCategory' }
    & { dishes?: Maybe<Array<(
      { __typename?: 'Dish' }
      & Pick<Dish, 'id'>
      & { title?: Maybe<Array<Maybe<(
        { __typename?: 'MultiLangString' }
        & Pick<MultiLangString, 'text'>
      )>>>, price?: Maybe<Array<(
        { __typename?: 'DishPrice' }
        & { price: (
          { __typename?: 'Price' }
          & Pick<Price, 'intValue' | 'shift'>
        ) }
      )>> }
    )>> }
  )> }
);

export type GetLokobeeUserQueryVariables = {};


export type GetLokobeeUserQuery = (
  { __typename?: 'Query' }
  & { getLokobeeUser?: Maybe<(
    { __typename?: 'LokobeeUser' }
    & Pick<LokobeeUser, 'uid' | 'displayName' | 'smsForDeals' | 'emailForDeals' | 'email' | 'orderEmail' | 'orderPhoneNumber'>
    & { stripeChargeSources?: Maybe<Array<(
      { __typename?: 'ChargeSource' }
      & Pick<ChargeSource, 'id' | 'brand' | 'last4'>
    )>> }
  )> }
);

export type GetRewardPointsForConsumerQueryVariables = {
  input: GetRewardPointsForConsumerInput;
};


export type GetRewardPointsForConsumerQuery = (
  { __typename?: 'Query' }
  & { getRewardPointsForConsumer?: Maybe<Array<(
    { __typename?: 'ConsumerRewardPoint' }
    & Pick<ConsumerRewardPoint, 'id' | 'type' | 'issuer' | 'activePoints' | 'pendingPoints'>
  )>> }
);

export type GetGroceryRewardPointsForConsumerQueryVariables = {
  input: GetGroceryRewardPointsForConsumerInput;
};


export type GetGroceryRewardPointsForConsumerQuery = (
  { __typename?: 'Query' }
  & { getGroceryRewardPointsForConsumer?: Maybe<Array<(
    { __typename?: 'ConsumerGroceryRewardPoint' }
    & Pick<ConsumerGroceryRewardPoint, 'id' | 'type' | 'issuer' | 'activePoints' | 'pendingPoints'>
  )>> }
);


      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    