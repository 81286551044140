import { Box, Button, CircularProgress, Typography, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../style';
import coinImg from 'assets/img/cc_coin_img.png';
import CloseIcon from '@material-ui/icons/Close';
// import axios from 'axios';
import { Auth } from 'aws-amplify/';
import { getCryptoBalanceQuery } from 'graphql/query/getCryptoBalance.query';
import { convertToCCTokens } from 'util/number';
import { adChainCognitoConfig } from 'util/configureAmplifyCognito';

const MainWalletPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const [earnedCC, setEarnedCC] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [isBalanceFetched, setIsBalanceFetched] = useState<boolean>(false);

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  const getEarnedCCBalance = async () => {
    setLoading(true);

    adChainCognitoConfig();

    const user = await Auth.currentAuthenticatedUser();
    let sessionToken = user?.signInUserSession?.idToken?.getJwtToken() || null;
    setUserEmail(user?.username || null);
    if (sessionToken) {
      const apiUrl = process.env.REACT_APP_ADCHAIN_API_URL || '';
      fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify({ query: getCryptoBalanceQuery }),
        headers: {
          authorization: `Bearer ${sessionToken}`
        }
      })
        .then((res: any) => res.json())
        .then((response: any) => {
          setIsBalanceFetched(true);
          if (response && response.data && response.data.getCryptoBalance) {
            const { balance, cryptoUnit } = response.data.getCryptoBalance;
            setEarnedCC(convertToCCTokens(balance, cryptoUnit));
            setLoading(false);
          } else {
            setEarnedCC(convertToCCTokens(0, 'TWEI'));
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getEarnedCCBalance();
  }, []);
  const logoutUser = async () => {
    console.log('configuring to ad chain pool ', process.env.REACT_APP_ADCHAIN_COGNITO_USER_POOL_ID);

    Auth.configure({
      Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_ADCHAIN_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_ADCHAIN_CONSUMER_CLIENT_ID!
      }
    });

    Auth.signOut();
    history.push('/');
  };
  const redirectToWebsite = (link: string) => {
    window.open(link, '_blank');
  };
  return (
    <Box
      position={'relative'}
      style={{
        background: 'linear-gradient(90deg, #00ABEA, #753CBE)'
      }}>
      <Box>
        {/* header start */}
        <Box className={classes.mainContainer}>
          <div className={classes.mainHeader}>
            <Typography className={classes.title} variant="h6">
              Wallet
            </Typography>
            <CloseIcon id={'close'} className={classes.button} onClick={() => history.goBack()} color="inherit" />
          </div>
          {/* logout box */}
          {!isDesktop && (
            <Box padding={1} display={'flex'} justifyContent="space-between" alignItems={'center'} flex={1} color="white">
              <Typography variant="subtitle1">
                Logged in as: <br /> {userEmail}
              </Typography>
              <Button variant="contained" color="default" size="small" onClick={logoutUser}>
                Logout Wallet
              </Button>
            </Box>
          )}
          {/* end of logout box */}
        </Box>
        {/* header end */}
      </Box>
      <Box className={classes.walletContainer}>
        <Box className={classes.walletHeadCustom} flex={1} paddingX={2}>
          {loading ? (
            <Box paddingTop={10}>
              <CircularProgress size={50} color="secondary" />
            </Box>
          ) : (
            <Box>
              <Box display="flex" justifyContent={'center'} flex={1} width={'100%'} paddingY={5} marginBottom={5} flexDirection={'column'}>
                {/* logout box */}
                {isDesktop && (
                  <Box padding={1} display={'flex'} alignItems={'center'} paddingBottom={5} color="black">
                    <Typography variant="subtitle1">
                      Logged in as: <br /> {userEmail}
                    </Typography>
                    <Button
                      variant="contained"
                      color="default"
                      size="small"
                      onClick={logoutUser}
                      style={{ backgroundImage: 'linear-gradient(90deg, rgb(0, 171, 234), rgb(117, 60, 190))', color: 'white', marginLeft: '100px' }}>
                      Logout Wallet
                    </Button>
                  </Box>
                )}
                {/* end of logout box */}
                {isBalanceFetched ? (
                  <Box display={'flex'} justifyContent="space-between" maxWidth={350} margin={'0 auto'}>
                    <Box display="flex" paddingLeft={2} marginRight={2} justifyContent={'center'} alignItems="center">
                      <img src={coinImg} className={classes.coinImg} width="100%" alt="CBT Coin" />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box padding={2}>
                        <Typography variant="subtitle1">CashBack Tokens Owned</Typography>
                        <Typography variant="h2">
                          <strong>{earnedCC}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box display={'flex'} justifyContent="space-between" maxWidth={350} margin={'0 auto'}>
                    <Typography variant="h6">Something went wrong!</Typography>
                  </Box>
                )}
              </Box>
              <Box marginBottom={5} display={'flex'} justifyContent="center" flexDirection={'column'} alignItems={'center'}>
                <Box marginBottom={0}>
                  <Typography align="center" variant="subtitle1" gutterBottom>
                    Learn how the price of <br /> <strong>CashBack Token</strong> increases
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  onClick={() => redirectToWebsite('https://biz.maxcashback.ai/cashbacktoken')}
                  color="secondary"
                  size="small"
                  style={{
                    backgroundImage: 'linear-gradient(90deg, #00ABEA, #753CBE)'
                  }}>
                  Learn more
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MainWalletPage;
