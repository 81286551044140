import React, { useState, useContext, ChangeEvent, useCallback, useEffect } from 'react';
import { Box, Button, TextField, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Divider, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import { createOrderInput } from '../createOrderInput';
import { OrderType, PaymentType } from 'generated/types';
import OrderProcessingScreen from '../OrderProcessingScreen';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { useStyles } from './style';
import StripeInput from '../StripeInput';
import { AuthContext } from 'fbase/authContext';
import SecurePayments from 'assets/img/secure-payments.svg';
import ContactDetails from '../ContactDetails';
import CheckoutSummary from 'pages/Payment/CheckoutSummary';
import Big from 'big.js';
import Logger from 'util/logger';
import { useMutation } from '@apollo/react-hooks';
import { createOrderMutation } from 'graphql/mutations/order.mutation';
import { ApolloError } from 'apollo-client';
import PayByRequestButton from './PayByRequestButton';
import { ExpandMore, CreditCard } from '@material-ui/icons';
import RestaurantPause from 'components/RestaurantPause';
import { useRestaurantPause } from 'hooks/restaurant';
import { isFutureDate } from 'util/date';
import { useAlert } from 'hooks';
import moment from 'moment';

interface IProps {
  isSubmitted: boolean;
}

const PayOnline: React.FC<IProps> = () => {
  const classes = useStyles();

  const history = useHistory();

  const {
    state: { cartRestaurant, orderType, deliveryAddress, deliveryTime, numberOfPeople, tableNumber, boonsQuoteId, deliveryProvider, deliveryMode, cartSessionId }
  } = useStore();

  const { state } = useStore();

  const [loading, setLoading] = useState(false);

  const { lokobeeUser } = useContext(AuthContext);

  const [name, setName] = useState('');

  const [isSubmitBlocked, setSubmitBlocked] = useState(false);

  const [cardExpanded, setCardExpanded] = useState(false);

  const [paymentRequestButton, setPaymentRequestButton] = useState<boolean>(false);

  // Error Objects
  const [{ ccNumberError, ccExpiryError, ccCvcError }, setError] = useState<{
    ccNumberError: boolean;
    ccExpiryError: boolean;
    ccCvcError: boolean;
  }>({ ccNumberError: true, ccExpiryError: true, ccCvcError: true });

  // Reference to stripe object.
  const stripe = useStripe();

  const { lokoAlert } = useAlert();

  const [createOrder] = useMutation<any, any>(createOrderMutation, {
    onError: (err: ApolloError) => {
      if (err.graphQLErrors && err.graphQLErrors.length) {
        let errMsg = err.graphQLErrors[0].message || 'Failed to create order';

        let errMsgLower = errMsg.toLowerCase();

        if (errMsgLower.startsWith('incorrect total') || errMsgLower.startsWith('invalid input') || errMsgLower.startsWith('invalid sellerbizid') || errMsgLower.startsWith('incorrect redeempoints')) {
          errMsg = 'Failed to create order';
        }

        lokoAlert(errMsg);

        Logger.log('ORDER ERROR', {
          errorShownToUser: errMsg,
          error: err
        });
      } else {
        lokoAlert('Failed to create order');

        Logger.log('ORDER ERROR', {
          errorShownToUser: 'Failed to create order',
          error: err
        });
      }
    }
  });

  const elements = useElements();

  // On press of back button
  // const onBack = () => {
  //   history.replace('/payment');
  // };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isSubmitBlocked) {
      setSubmitBlocked(true);

      if (!elements || !stripe) {
        return;
      }

      const currentTime = moment();
      if (currentTime.isAfter(state.deliveryTime) && orderType === 'TAKE_OUT') {
        lokoAlert('Invalid pickup time!');
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      setLoading(true);

      if (!cardElement) {
        return;
      }

      if (!lokobeeUser) {
        lokoAlert('Please login to continue.');

        return;
      }

      let email = null;

      if (state.user && state.user.email) {
        email = state.user.email;
      }

      if (!email) {
        lokoAlert('Email does not exists');

        return;
      }

      let orderPhoneNumber = null;

      if (state.user && state.user.orderPhoneNumber) {
        orderPhoneNumber = state.user.orderPhoneNumber;
      }

      if (!orderPhoneNumber) {
        lokoAlert('Phone number does not exists');

        return;
      }
      try {
        if ((state.cartItems && state.cartItems.length) || (state.cartRewardItems && state.cartRewardItems.length)) {
          const { usedPoints, tip, totalPriceAfterTax, deliveryTime, orderNote, deliveryAddress, deliveryNote, orderType, serviceFee, subtotal, discount, isDiscountApplied } = state;

          if (!totalPriceAfterTax) {
            lokoAlert('Total price cannot be null');
            return;
          }

          setLoading(true);

          let totalAmount: any = totalPriceAfterTax.toFixed(2);

          const subtotalAmount =
            isDiscountApplied && discount
              ? Big(subtotal)
                  .minus(discount)
                  .toFixed(2)
              : Big(subtotal).toFixed(2);

          if ((state.cartItems && state.cartItems.length) || (state.cartRewardItems && state.cartRewardItems.length)) {
            const restaurantId = state.cartRestaurant?.id;

            const googleReviewLink = state.cartRestaurant?.googleReviewLink;

            const issueRewardPoint = state.cartRestaurant?.issueRewardPoint;

            const { tableNumber, numberOfPeople } = state;

            let type = OrderType.Takeout;

            if (orderType === 'DINE_OUT') type = OrderType.DineOut;

            if (orderType === 'DELIVERY') type = OrderType.Delivery;

            const intParsedNumberOfPeople = typeof numberOfPeople === 'number' ? numberOfPeople : parseInt(numberOfPeople, 10);

            const lokobeeServiceFee = serviceFee ? serviceFee?.toFixed(2) : '0';

            const phoneOrder = state.phoneOrder;

            const input = createOrderInput({
              cartItems: state.cartItems,
              cartRewardItems: state.cartRewardItems,
              expectTime: deliveryTime,
              note: orderNote,
              tipAmount: tip.toFixed(2),
              totalAmount,
              type,
              paymentType: PaymentType.OnlineStripe,
              email,
              orderPhoneNumber,
              tableName: tableNumber,
              partySize: intParsedNumberOfPeople,
              deliveryAddress,
              deliveryNote,
              couponToken: state.couponToken,
              serviceFee: lokobeeServiceFee,
              redeemPoints: usedPoints,
              isPrediscountEnabled: state.isPrediscountEnabled,
              deliveryFee: state.deliveryCharge.gt(Big(0)) ? state.deliveryCharge.toFixed(2) : undefined,
              dropOffInstruction: state.dropOffType,
              apartmentNumber: state.apartmentNumber,
              boonsQuoteId,
              deliveryProvider,
              deliveryMode,
              doordashClassicQuoteId: state.doordashQuoteId,
              phoneOrder: phoneOrder,
              sessionId: cartSessionId
            });

            const response = await createOrder({
              variables: {
                input
              }
            });

            if (response && response.data) {
              const {
                data: {
                  createOrder: { clientSecret }
                }
              } = response;

              const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                  billing_details: {
                    name
                  },
                  card: cardElement
                }
              });

              if (result.error) {
                Logger.log(`PAYMENT ERROR`, {
                  createOrderResponse: response,
                  stripeError: result
                });
                throw new Error(result.error.message ? result.error.message : 'Stripe error');
              } else {
                // The PayByCardUI has been processed!
                if (result?.paymentIntent?.status === 'succeeded') {
                  // Show a success message to your customer
                  // There's a risk of the customer closing the window before callback
                  // execution. Set up a webhook or plugin to listen for the
                  // payment_intent.succeeded event that handles any business critical
                  // post-PayByCardUI actions.
                  history.push('/success', {
                    show: true,
                    restaurantId,
                    subtotalAmount,
                    googleReviewLink,
                    issueRewardPoint
                  });
                } else {
                  Logger.log('PAYMENT INTENT ERROR', {
                    createOrderResponse: response,
                    stripeError: result
                  });

                  throw new Error('Payment failed');
                }
              }
            } else {
              Logger.log('ORDER ERROR', {
                createOrderInput: input,
                createOrderResponse: response
              });
              throw new Error('');
            }
          } else {
            Logger.log('CART ERROR', state);
            throw new Error('Cart error');
          }
        } else {
          Logger.log('CART ERROR', state);
          throw new Error('Cart error');
        }
      } catch (e) {
        setSubmitBlocked(false);

        setLoading(false);

        if (e && (e as any).message !== '') {
          lokoAlert((e as any).message);
        }
      }
    }
  };

  const onCardElementChange = (type: 'ccnumber' | 'ccexpiry' | 'ccCvc') => (event: any) => {
    if (event?.complete) {
      switch (type) {
        case 'ccnumber':
          setError((prev) => ({ ...prev, ccNumberError: false }));
          break;
        case 'ccexpiry':
          setError((prev) => ({ ...prev, ccExpiryError: false }));
          break;
        case 'ccCvc':
          setError((prev) => ({ ...prev, ccCvcError: false }));
      }
    }

    if (event?.error && event.error?.code) {
      switch (type) {
        case 'ccnumber':
          setError((prev) => ({ ...prev, ccNumberError: true }));
          break;
        case 'ccexpiry':
          setError((prev) => ({ ...prev, ccExpiryError: true }));
          break;
        case 'ccCvc':
          setError((prev) => ({ ...prev, ccCvcError: true }));
      }
    }
  };

  const onNameChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setName(e.target.value);
  };

  const onCardExpanded = () => {
    setCardExpanded(!cardExpanded);
  };

  const canMakePaymentWithRequestButton = useCallback((result: any) => {
    if (result) {
      Object.keys(result).forEach((method: string) => {
        if (result[method]) {
          setPaymentRequestButton(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (paymentRequestButton) {
      setCardExpanded(false);
    } else {
      setCardExpanded(true);
    }
  }, [paymentRequestButton]);

  const { deliveryPause, diningPause, takeOutPause } = useRestaurantPause({ restaurant: cartRestaurant });

  const isPaused = (orderType === 'TAKE_OUT' && takeOutPause) || (orderType === 'DINE_OUT' && diningPause) || (orderType === 'DELIVERY' && deliveryPause);

  const futureOrder = (orderType === 'TAKE_OUT' || orderType === 'DELIVERY') && isFutureDate(deliveryTime);

  /**
   * @type {Boolean} - Boolean flag to determine whether add button should be disabled or not.
   */
  const isDisabled = !stripe || !name || ccNumberError || ccExpiryError || ccCvcError || isSubmitBlocked || (isPaused && !futureOrder);

  const render = () => {
    return (
      <>
        {loading && <OrderProcessingScreen />}
        <Box paddingX={1} flex={1} overflow="scroll" className={classes.payment}>
          <ExpansionPanel
            className={classes.expansionPanel}
            expanded={cardExpanded}
            onClick={() => {
              onCardExpanded();
            }}>
            <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMore />} aria-controls="card-content" id="payment-content-card">
              <Box display="flex" justifyContent="space-around">
                <CreditCard />
                <Box marginX={1}>
                  <Typography>Credit/Debit Card</Typography>
                </Box>
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails} onClick={(event) => event.stopPropagation()}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Box display="flex" flex={1} paddingY={2} flexDirection="column" overflow="auto">
                  <Box marginBottom={2}>
                    <TextField value={name} label="Name on card" onChange={onNameChangeHandler} fullWidth={true} variant="outlined" />
                  </Box>
                  <Box marginBottom={2}>
                    <TextField
                      label="Credit Card Number"
                      name="ccnumber"
                      variant="outlined"
                      required={true}
                      fullWidth={true}
                      onChange={onCardElementChange('ccnumber')}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: StripeInput,
                        inputProps: {
                          component: CardNumberElement
                        }
                      }}
                    />
                  </Box>
                  <Box display="flex">
                    <Box marginRight={1} flex={1}>
                      <TextField
                        label="Expiry date"
                        name="ccexpiry"
                        variant="outlined"
                        required={true}
                        onChange={onCardElementChange('ccexpiry')}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: StripeInput,
                          inputProps: {
                            component: CardExpiryElement
                          }
                        }}
                      />
                    </Box>
                    <Box flex={1}>
                      <TextField
                        label="CVC"
                        name="ccexpiry"
                        variant="outlined"
                        onChange={onCardElementChange('ccCvc')}
                        required={true}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: StripeInput,
                          inputProps: {
                            component: CardCvcElement
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {cartRestaurant && <RestaurantPause restaurant={cartRestaurant} />}
                  <Button type="submit" variant="contained" color="primary" fullWidth={true} disabled={isDisabled} startIcon={<CreditCard />}>
                    buy with card
                  </Button>
                </Box>
              </form>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <Box>
            {paymentRequestButton && (
              <Box paddingY={2}>
                <Grid container spacing={0} alignItems="center">
                  <Grid item xs={5}>
                    <Divider />
                  </Grid>
                  <Grid item xs={2}>
                    <Box textAlign="center" fontWeight="500">
                      <Typography variant="h6">OR</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Divider />
                  </Grid>
                </Grid>
              </Box>
            )}
            <PayByRequestButton canMakePayment={canMakePaymentWithRequestButton} />
          </Box>
          <Box paddingY={2} display="flex" justifyContent="center">
            <a target="_blank" rel="noopener noreferrer" href="https://stripe.com/docs/security/stripe">
              <img src={SecurePayments} alt="Secure Payments" />
            </a>
          </Box>
          <Box paddingY={1}>
            <ContactDetails />
            <CheckoutSummary
              restaurantName={cartRestaurant?.getName}
              restaurantAddress={cartRestaurant?.getAddress}
              orderType={orderType}
              partySize={numberOfPeople}
              tableNumber={tableNumber}
              deliveryAddress={deliveryAddress}
              eta={deliveryTime}
            />
          </Box>
        </Box>
      </>
    );
  };

  return render();
};

export default PayOnline;
