import React, { useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useStore } from 'store';
import { createOrderInput } from '../createOrderInput';
import { OrderType, PaymentType } from 'generated/types';
import OrderProcessingScreen from '../OrderProcessingScreen';
import { AuthContext } from 'fbase/authContext';
import Big from 'big.js';
import Logger from 'util/logger';
import { useMutation } from '@apollo/react-hooks';
import { createOrderMutation } from 'graphql/mutations/order.mutation';
import { useAlert } from 'hooks';
import { setLocalStorage } from 'util/storage';
import { isRestaurantClosed } from 'pages/MyCart/util';
import { ServiceType } from 'generated/types';
import { filter } from 'lodash';
import { Box, Button } from '@material-ui/core';
import RestaurantPause from 'components/RestaurantPause';
import { useRestaurantPause } from 'hooks/restaurant';
import { isFutureDate } from 'util/date';

const PayByCash: React.FC = () => {
  const history = useHistory();

  const { state } = useStore();

  const [loading, setLoading] = useState(false);

  const { lokobeeUser } = useContext(AuthContext);

  const { lokoAlert } = useAlert();
  const couponData = state.couponData || null;
  const earnedCC = couponData ? { consumerReward: couponData?.consumerReward, cryptoUnit: couponData?.cryptoUnit } : null;

  const [createOrder, { called }] = useMutation<any, any>(createOrderMutation);

  const orderType = state.orderType;
  const handleSubmit = useCallback(async () => {
    const currentTime = moment();
    if (currentTime.isAfter(state.deliveryTime) && orderType === 'TAKE_OUT') {
      lokoAlert('Invalid pickup time!');
      return;
    }
    if (!lokobeeUser) {
      history.replace('/payment');

      lokoAlert('Please login to continue.');
      return;
    }

    let email = null;

    if (state.user && state.user.email) {
      email = state.user.email;
    }

    if (!email) {
      lokoAlert('No email provided');
      history.replace('/payment');
      return;
    }

    let orderPhoneNumber = null;

    if (state.user && state.user.orderPhoneNumber) {
      orderPhoneNumber = state.user.orderPhoneNumber;
    }

    if (!orderPhoneNumber) {
      lokoAlert('No phone number provided');
      history.replace('/payment');

      return;
    }

    try {
      if ((state.cartItems && state.cartItems.length) || (state.cartRewardItems && state.cartRewardItems.length)) {
        const {
          usedPoints,
          tip,
          totalPriceAfterTax,
          deliveryTime,
          orderNote,
          deliveryAddress,
          deliveryNote,
          orderType,
          serviceFee,
          subtotal,
          discount,
          isDiscountApplied,
          deliveryProvider,
          deliveryMode,
          cartRestaurant,
          bizPlan,
          cartSessionId
        } = state;

        if (!totalPriceAfterTax) {
          lokoAlert('Total price cannot be null');
          history.replace('/payment');
          return;
        }

        setLoading(true);

        let totalAmount: any = totalPriceAfterTax.toFixed(2);

        const subtotalAmount =
          isDiscountApplied && discount
            ? Big(subtotal)
                .minus(discount)
                .toFixed(2)
            : Big(subtotal).toFixed(2);

        if ((state.cartItems && state.cartItems.length) || (state.cartRewardItems && state.cartRewardItems.length)) {
          const restaurantId = state.cartRestaurant?.id;

          const googleReviewLink = state.cartRestaurant?.googleReviewLink;

          const issueRewardPoint = state.cartRestaurant?.issueRewardPoint;

          const { tableNumber, numberOfPeople } = state;

          let type = OrderType.Takeout;

          if (orderType === 'DINE_OUT') type = OrderType.DineOut;

          if (orderType === 'DELIVERY') type = OrderType.Delivery;

          const intParsedNumberOfPeople = typeof numberOfPeople === 'number' ? numberOfPeople : parseInt(numberOfPeople, 10);

          const lokobeeServiceFee = serviceFee ? serviceFee?.toFixed(2) : '0';

          const phoneOrder = state.phoneOrder;

          const bizHours = cartRestaurant?.getBizHours;

          const dineOutBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === ServiceType.RestaurantDineOut) : [];

          const takeoutBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === ServiceType.RestaurantPickUp) : [];

          const deliveryBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === ServiceType.RestaurantDelivery) : [];
          let restaurnatClosed = false;
          if (orderType === 'DINE_OUT') {
            restaurnatClosed = isRestaurantClosed(dineOutBizHours, orderType, deliveryProvider);
          }
          if (orderType === 'TAKE_OUT') {
            restaurnatClosed = isRestaurantClosed(takeoutBizHours, orderType, deliveryProvider);
          }
          if (orderType === 'DELIVERY') {
            restaurnatClosed = isRestaurantClosed(deliveryBizHours, orderType, deliveryProvider);
          }

          const input = createOrderInput({
            boonsQuoteId: state.boonsQuoteId,
            doordashClassicQuoteId: state.doordashQuoteId,
            cartItems: state.cartItems,
            cartRewardItems: state.cartRewardItems,
            expectTime: deliveryTime,
            note: orderNote,
            tipAmount: tip.toFixed(2),
            totalAmount,
            type,
            paymentType: PaymentType.Onsite,
            email,
            orderPhoneNumber,
            tableName: tableNumber,
            partySize: intParsedNumberOfPeople,
            deliveryAddress: deliveryAddress,
            deliveryNote: deliveryNote,
            couponToken: state.couponToken,
            serviceFee: lokobeeServiceFee,
            redeemPoints: usedPoints,
            deliveryFee: state.deliveryCharge.gt(Big(0)) ? state.deliveryCharge.toFixed(2) : undefined,
            isPrediscountEnabled: state.isPrediscountEnabled,
            dropOffInstruction: state.dropOffType,
            apartmentNumber: state.apartmentNumber,
            deliveryProvider: state.deliveryProvider,
            deliveryMode: state.deliveryMode,
            phoneOrder: phoneOrder,
            sessionId: cartSessionId
          });

          const response = await createOrder({
            variables: {
              input
            }
          });

          if (response && response.data) {
            const {
              data: {
                createOrder: { orderId }
              }
            } = response;

            if (orderId) {
              setLocalStorage(
                'orderState',
                JSON.stringify({
                  show: true,
                  restaurantId,
                  subtotalAmount,
                  googleReviewLink,
                  issueRewardPoint,
                  isRestaurnatClosed: restaurnatClosed,
                  orderType,
                  earnedCC
                })
              );

              setLocalStorage(
                'cartState',
                JSON.stringify({
                  cartItems: state.cartItems,
                  cartRewardItems: state.cartRewardItems,
                  deliveryProvider,
                  deliveryMode,
                  bizPlan,
                  timezone: cartRestaurant?.getTimezone
                })
              );

              history.push('/success?success=true');
            }
          } else {
            const { errors } = response;

            if (errors && errors.length) {
              let errMsg = errors[0].message || 'Failed to create order';

              let errMsgLower = errMsg.toLowerCase();

              if (
                errMsgLower.startsWith('incorrect total') ||
                errMsgLower.startsWith('invalid input') ||
                errMsgLower.startsWith('invalid sellerbizid') ||
                errMsgLower.startsWith('incorrect redeempoints')
              ) {
                errMsg = 'Failed to create order';
              }

              Logger.log('ORDER ERROR', {
                errorShownToUser: errMsg,
                error: errors
              });
              throw new Error(errMsg);
            } else {
              Logger.log('ORDER ERROR', {
                errorShownToUser: 'Failed to create order',
                error: errors
              });
              throw new Error('Failed to create order');
            }
          }
        } else {
          Logger.log('CART ERROR', state);
          throw new Error('Cart error');
        }
      } else {
        Logger.log('CART ERROR', state);
        throw new Error('Cart error');
      }
    } catch (e) {
      console.log(e);
      setLoading(false);

      let errMsg: string = (e as any).message;

      if (errMsg.toLowerCase().startsWith('graphql error:')) {
        errMsg = errMsg.substring(15);
      }

      lokoAlert(errMsg);

      history.push('/payment');
    }
  }, [createOrder, earnedCC, history, lokoAlert, lokobeeUser, orderType, state]);

  const { deliveryPause, diningPause, takeOutPause } = useRestaurantPause({ restaurant: state.cartRestaurant });

  const isPaused = (orderType === 'TAKE_OUT' && takeOutPause) || (orderType === 'DINE_OUT' && diningPause) || (orderType === 'DELIVERY' && deliveryPause);

  const futureOrder = (orderType === 'TAKE_OUT' || orderType === 'DELIVERY') && isFutureDate(state.deliveryTime);
  const onPaymentClick = () => {
    if (!loading && !called) {
      handleSubmit();
    }
  };

  return (
    <>
      {loading && <OrderProcessingScreen />}
      <Box padding={1}>
        {state.cartRestaurant && <RestaurantPause restaurant={state.cartRestaurant} />}
        <Button variant="contained" color="primary" fullWidth={true} onClick={onPaymentClick} disabled={(isPaused && !futureOrder) || loading}>
          place order
        </Button>
      </Box>
    </>
  );
};

export default PayByCash;
