import React from 'react';
import { useStyles } from '../style';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import DialogHeader from 'components/DialogHeader';
import coinImg from 'assets/img/cc_coin_img.png';
import { grey } from '@material-ui/core/colors';
interface IProps {
  togglePage: () => void;
}
const SellCoinPage: React.FC<IProps> = ({ togglePage }) => {
  const classes = useStyles();

  return (
    <Box bgcolor="#fff" height="100%" position={'relative'}>
      <DialogHeader text={'How do I sell my CashBack Tokens?'} onBack={togglePage} />
      <Box width={'100%'} height="100%" flex={1}>
        <Box display={'flex'} marginBottom={2} flexDirection="column" width={'100%'} alignItems="center" justifyContent={'center'}>
          <Box marginBottom={2}>
            <img src={coinImg} className={classes.coinImg} width="100%" alt="CBT Coin" />
          </Box>
          <Typography variant="subtitle1" align="center" className={classes.askToSell}>
            <strong> Are you sure</strong> <br /> you want to sell your CashBack Tokens (CBT)?
          </Typography>
        </Box>
        <Box padding={2} bgcolor={grey[200]}>
          <Box bgcolor={'white'} marginBottom={2} padding={2} borderRadius={10}>
            <Typography variant="subtitle1">
              <strong>CashBack Tokens </strong> represents your ownershrip in <strong>maxCashback</strong>
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <Typography variant="subtitle1">Sell your CBT when token starts trading on Exchange</Typography>
          </Box>
          <Box marginBottom={2}>
            <Typography variant="subtitle1">
              <i>Fun Fact: BitCoin started trading at 7 centes</i>
            </Typography>
          </Box>
          <Box paddingX="5%" marginBottom={2}>
            <Divider />
          </Box>
          <Box marginBottom={2}>
            <Typography variant="subtitle1" gutterBottom>
              Learn how the price of CBT increases as more CashBack Promotions get used over time!
            </Typography>
            <Button variant="contained" color="primary" size="small">
              Learn more
            </Button>
          </Box>
          <Box paddingX="5%" marginBottom={2}>
            <Divider />
          </Box>
          <Box display={'flex'} paddingY={2} alignItems="center" justifyContent={'center'}>
            <Button variant="contained" color="primary" size="small" disabled>
              Sell CashBack Tokens (Coming Soon)
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SellCoinPage;
