import { BizHour } from 'generated/types';
import moment from 'moment';
import { filter } from 'lodash';
import { DeliveryProvider } from 'generated/custom';

export const isRestaurantClosed = (bizHours: BizHour[], orderType: 'TAKE_OUT' | 'DINE_OUT' | 'DELIVERY' | '', deliveryProvider: DeliveryProvider) => {
  const curDate = moment();

  const curDay = curDate.day() ? curDate.day() : 7;

  const prevDay = curDate.day() !== 1 ? curDay - 1 : 7;

  let slotFound = false;

  bizHours.every(({ hours, serviceName }) => {
    const requiredSlot = filter(hours, ({ openDay }) => openDay === curDay)[0];

    const prevSlot = filter(hours, ({ openDay }) => openDay === prevDay)[0];

    if (requiredSlot) {
      const slotStart = curDate
        .clone()
        .hour(requiredSlot.openHour)
        .minute(requiredSlot.openMinute)
        .second(0);
      const slotEnd = curDate
        .clone()
        .hour(requiredSlot.closeHour)
        .minute(requiredSlot.closeMinute)
        .second(0);

      if (orderType === 'DELIVERY' && deliveryProvider === 'DOORDASH_CLASSIC') {
        slotEnd.subtract(20, 'm');
        const roundetEndMins = Math.ceil(slotEnd.minute() / 10) * 10;
        slotEnd.minute(roundetEndMins).second(0);
      }

      if (requiredSlot.openDay !== requiredSlot.closeDay) {
        slotEnd.add('1', 'day');
      }

      if (prevSlot && prevSlot.openDay !== prevSlot.closeDay) {
        const prevSlotStart = curDate
          .clone()
          .hour(0)
          .minute(0)
          .second(0);

        const prevSlotEnd: any = curDate
          .clone()
          .hour(prevSlot.closeHour)
          .minute(prevSlot.closeMinute)
          .second(0);

        if (orderType === 'DELIVERY' && deliveryProvider === 'DOORDASH_CLASSIC') {
          prevSlotEnd.subtract(20, 'm');
          const roundetEndMins = Math.ceil(prevSlotEnd.minute() / 10) * 10;
          prevSlotEnd.minute(roundetEndMins).second(0);
        }

        if (orderType === 'TAKE_OUT' || orderType === 'DELIVERY') {
          const openToday = curDate.date() === prevSlotStart.date() && curDate.month() === prevSlotStart.month() && curDate.year() === prevSlotStart.year();
          if (openToday && curDate.isAfter(prevSlotStart) && curDate.isBefore(prevSlotEnd)) {
            slotFound = true;

            return false;
          }
        }
        if (orderType === 'DINE_OUT') {
          if ((curDate.isAfter(prevSlotStart) || curDate.isSame(prevSlotStart)) && (curDate.isBefore(prevSlotEnd) || curDate.isSame(prevSlotEnd))) {
            slotFound = true;
            return false;
          }
        }
      }

      if (orderType === 'DINE_OUT') {
        if ((curDate.isAfter(slotStart) || curDate.isSame(slotStart)) && (curDate.isBefore(slotEnd) || curDate.isSame(slotEnd))) {
          slotFound = true;
          return false;
        }
      }
      if (orderType === 'TAKE_OUT' || orderType === 'DELIVERY') {
        const openToday = curDate.date() === slotStart.date() && curDate.month() === slotStart.month() && curDate.year() === slotStart.year();

        if (openToday && curDate.isBefore(slotEnd) && curDate.isAfter(slotStart)) {
          slotFound = true;
          return false;
        }
      }
    } else {
      if (prevSlot && prevSlot.openDay !== prevSlot.closeDay) {
        const prevSlotStart = curDate
          .clone()
          .hour(0)
          .minute(0)
          .second(0);

        const prevSlotEnd: any = curDate
          .clone()
          .hour(prevSlot.closeHour)
          .minute(prevSlot.closeMinute)
          .second(0);

        if (orderType === 'TAKE_OUT' || orderType === 'DELIVERY') {
          const openToday = curDate.date() === prevSlotStart.date() && curDate.month() === prevSlotStart.month() && curDate.year() === prevSlotStart.year();
          if (openToday && curDate.isAfter(prevSlotStart) && curDate.isBefore(prevSlotEnd)) {
            slotFound = true;
            return false;
          }
        }
        if (orderType === 'DINE_OUT') {
          if ((curDate.isAfter(prevSlotStart) || curDate.isSame(prevSlotStart)) && (curDate.isBefore(prevSlotEnd) || curDate.isSame(prevSlotEnd))) {
            slotFound = true;
            return false;
          }
        }
      }
    }
    return true;
  });

  if (slotFound) {
    return false;
  } else {
    return true;
  }
};
