import React, { useState, useContext } from 'react';
import { Box, Button, TextField, InputAdornment, IconButton, Grid } from '@material-ui/core';
import 'react-phone-number-input/style.css';
import { FirebaseContext } from 'fbase';
import { loginValidation } from './validations';
import { useFormik, FormikHelpers, getIn } from 'formik';
import { ILoginFormikInitialState } from './types';
import { useStore } from 'store';
import { useStyles } from './style';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useAlert } from 'hooks';
import { useApolloClient } from '@apollo/react-hooks';
import GoogleSignin from 'components/AccountModal/Login/GoogleSignIn';
import Loading from 'components/AccountModal/Login/Loading';
interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}
const LoginFormInner: React.FC<IProps> = ({ setStep }) => {
  const { firebase } = useContext(FirebaseContext);

  const auth = firebase!.getAuth();

  const { lokoAlert } = useAlert();
  const classes = useStyles();

  const [{ done }, setProgress] = useState({ loading: false, done: false });

  const { dispatch } = useStore();

  const [showPassword, setShowPassword] = useState(false);

  const client = useApolloClient();

  const handleClickShowPassword = () => {
    let tempShowPassword = showPassword;
    setShowPassword(!tempShowPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  /**
   *
   *
   * @param values - Form values
   * @param actions - Formik Helpers
   */
  const onSubmit = async (values: ILoginFormikInitialState, actions: FormikHelpers<ILoginFormikInitialState>) => {
    actions.setSubmitting(true);

    setProgress((prev) => ({ ...prev, loading: true }));

    const { email, password } = values;

    try {
      /**
       * If email already exists link it with credentials and update user profile.
       */

      let persistence = firebase?.getAuth().Auth.Persistence.LOCAL || 'LOCAL';
      await auth().setPersistence(persistence);
      await auth().signInWithEmailAndPassword(email, password);

      client.resetStore();
      dispatch({
        type: 'UI_ACTION_LOKOBEE_WALLET_LOGIN',
        payload: true /// onLoginSuccess() won't be executed because screen will be replaced on Auth state changed
      });

      dispatch({
        type: 'RESET_ONGOING_ORDERS'
      });
      actions.setSubmitting(false);
    } catch (e) {
      dispatch({
        type: 'UI_ACTION_LOKOBEE_WALLET_LOGIN',
        payload: false
      });
      let message = '';

      if ((e as any).code) {
        switch ((e as any).code) {
          case 'auth/user-disabled':
            message = 'User has been disabled';
            break;
          case 'auth/user-not-found':
            message = 'User not found. Please try again';
            break;
          case 'auth/wrong-password':
            message = 'Wrong Password';
            break;
          case 'auth/email-already-in-use':
            message = 'Email already exists';
            break;
          case 'auth/invalid-email':
            message = 'Invalid email';
            break;
          default:
            message = (e as any).message;
        }

        actions.setSubmitting(false);
        lokoAlert(message);
      }
    }
  };

  const formik = useFormik<ILoginFormikInitialState>({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnMount: true,
    validationSchema: loginValidation,
    onSubmit
  });

  const isSubmitButtonDisabled = !formik.isValid;

  return (
    <>
      <Box paddingY={3} display="flex" flexDirection={'column'} alignItems="center">
        <Loading open={formik.isSubmitting} done={done} />
        <GoogleSignin lokobeeWalletLogin={true} />
      </Box>
      <Box display="flex" flexDirection={'column'} paddingY={3} alignItems="center">
        <form className={classes.formWrapper} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={6} lg={6}>
              <TextField
                className={classes.textField}
                name="email"
                type="email"
                label="Email address"
                fullWidth
                placeholder="Email address"
                value={formik.values.email}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  formik.setFieldValue('email', e.target.value.trim());
                }}
                onFocus={formik.handleBlur}
                onBlur={formik.handleBlur}
                error={!!getIn(formik.touched, 'email') && !!getIn(formik.errors, 'email')}
                helperText={!!getIn(formik.touched, 'email') && !!getIn(formik.errors, 'email') ? getIn(formik.errors, 'email') : ''}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={12} xs={12} md={6} lg={6}>
              <TextField
                className={classes.textField}
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                label="Password"
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={!!getIn(formik.touched, 'password') && !!getIn(formik.errors, 'password')}
                helperText={!!getIn(formik.touched, 'password') && !!getIn(formik.errors, 'password') ? getIn(formik.errors, 'password') : ''}
                onBlur={formik.handleBlur}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>

          <Box display="flex" marginBottom={2}>
            <Button variant="text" className={classes.guestBtn} onClick={() => setStep(2)}>
              Forgot password?
            </Button>
          </Box>
          <Box marginBottom={2} display="flex" justifyContent="center" alignItems="center">
            <Box className={classes.btnContainer}>
              <Button type="submit" variant="contained" fullWidth className={classes.guestBtn} color="primary" size="medium" disabled={isSubmitButtonDisabled}>
                Sign in with Lokobee
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default LoginFormInner;
