import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { Box, Typography, MenuItem, TextField, Button, ButtonGroup, Dialog } from '@material-ui/core';
import { Stepper } from '@lokobee/lokobee-ui';
import Toppings from './Toppings';
import { Dish, PizzaToppingSide, OrderPizzaToppingInput, PizzaToppingDensity } from 'generated/types';
import RestaurantModel from 'models/Restaurant';
import { map, find, isEqual, filter, cloneDeep, groupBy, isEmpty } from 'lodash';
import { applyDiscount, convertPrice, convertPriceTo } from 'util/number';
import Big from 'big.js';
import { plainToClass } from 'class-transformer';
import { CartItem } from 'models';
import { useStore } from 'store';
import { useFormik } from 'formik';
import { ITabValues, IPizzaData } from './types';
import { convertResponseToFormValues, getOrderLineItems } from './utils';
import { useStyles } from './style';
import CancelIcon from '@material-ui/icons/Cancel';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { IDiscount } from 'generated/custom';

interface IProps {
  dish: Dish | null;
  restaurant: RestaurantModel | null;
  handleClose: () => void;
  hideImg: () => void;
  showImg: () => void;
  isHeaderExpanded: boolean;
}

const defaultInitialValue: IPizzaData = {
  selectedSizeId: '',
  selectedCrustId: '',
  halfAndHalf: false,
  leftPizza: {
    pizzaToppingsGroup: null,
    pizzaCheeseGroup: null,
    pizzaSauceGroup: null
  },
  rightPizza: {
    pizzaToppingsGroup: null,
    pizzaCheeseGroup: null,
    pizzaSauceGroup: null
  },
  wholePizza: {
    pizzaToppingsGroup: null,
    pizzaCheeseGroup: null,
    pizzaSauceGroup: null
  }
};

const DishTypePizza: React.FC<IProps> = ({ dish, restaurant, handleClose, isHeaderExpanded, hideImg, showImg }) => {
  const classes = useStyles();

  const {
    state: { cartItems, cartRewardItems },
    dispatch
  } = useStore();

  const [subtotal, setTotalPrice] = useState<{
    quantity: number;
    subtotal: number | string;
    subtotalToDiscount: number | string;
    totalPriceWithoutQuantity: number | string;
    totalPriceStrikedWithoutQuantity: number | string;
  }>({
    quantity: 1,
    subtotal: 0,
    subtotalToDiscount: 0,
    totalPriceWithoutQuantity: 0,
    totalPriceStrikedWithoutQuantity: 0
  });

  const [initialValues, setInitialValues] = useState(defaultInitialValue);

  const [dishNote, setDishNote] = useState<string | null>(null);

  const [activeTab, setActiveTab] = useState<ITabValues>('wholePizza');

  const [error, setError] = useState(false);

  const [errorMessage, setErrormessage] = useState('');

  const [displayDishNoteField, setDisplayDishNoteField] = useState(false);

  const [emptySauce, setEmptySauce] = useState(false);

  const [emptyCheese, setEmptyCheese] = useState(false);

  const [leftEmptySauce, setLeftEmptySauce] = useState(false);

  const [rightEmptySauce, setRightEmptySauce] = useState(false);

  const [leftEmptyCheese, setLeftEmptyCheese] = useState(false);

  const [rightEmptyCheese, setRightEmptyCheese] = useState(false);

  const [openEmptyItemsWarningDialog, setOpenEmptyItemsWarningDialog] = useState(false);

  /*
   * This flag checks if add to cart button should be enabled or not.
   */
  const orderEnabled = (restaurant?.enableDeliveryOrder || restaurant?.enableDineOutOrder || restaurant?.enableTakeoutOrder) && restaurant.isLokobeePartner;

  /*
   * set default values
   */
  useEffect(() => {
    if (dish) {
      const values = convertResponseToFormValues(dish);

      setInitialValues(values);
    }
  }, [dish]);

  /*
   * formik setup
   */

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (submittedValues: IPizzaData) => handleSubmit(submittedValues)
  });

  const { values, handleChange } = formik;

  useEffect(() => {
    const handleScroll = () => {
      const elemOffsetHeight = document.getElementById('scrollerBox')?.offsetHeight || 0;
      const elemScrollHeight = document.getElementById('scrollerBox')?.scrollHeight || 0;
      const viewDiff = elemScrollHeight - elemOffsetHeight;
      const st = window.pageYOffset || document.documentElement.scrollTop || document.getElementById('scrollerBox')?.scrollTop || 0;
      if (st > 0 && !(st >= viewDiff - 10)) {
        // downscroll code
        hideImg();
      } else {
        // upscroll code
      }
    };
    document.getElementById('scrollerBox')?.addEventListener('scroll', handleScroll, true);
  }, [hideImg]);

  const isDiscounted = useCallback(
    (sizeId: string) => {
      const basePrice: any = find(dish?.pizzaBasePrice, ({ size }) => size.id === sizeId);

      if (basePrice) {
        const { discountAmount } = basePrice || { price: { intValue: 0, shift: 0 }, discountAmount: 0 };

        if (dish?.isDiscounted && discountAmount) {
          return true;
        }

        return false;
      }
      return false;
    },
    [dish]
  );

  /*
   * Get pizza base price
   */
  const getPizzaBaseSizePrice = useCallback(
    (sizeId: string) => {
      const basePrice: any = find(dish?.pizzaBasePrice, ({ size }) => size.id === sizeId);

      if (basePrice) {
        const { price, discountAmount } = basePrice || { price: { intValue: 0, shift: 0 }, discountAmount: 0 };

        const { intValue, shift } = price;

        const priceString = convertPrice(intValue, shift);

        if (dish?.isDiscounted && discountAmount) {
          const discount: IDiscount = applyDiscount(priceString, convertPriceTo(discountAmount, 'DOLLAR'));

          return {
            price: discount.price,
            priceStriked: priceString,
            discountPercent: discount.percentage
          };
        }

        return {
          price: priceString,
          priceStriked: 0,
          discountPercent: 0
        };
      }

      return {
        price: 0,
        priceStriked: 0,
        discountPercent: 0
      };
    },
    [dish]
  );
  /*
   * Get pizza crust price
   */
  const getPizzaCrustPrice = useCallback(
    (sizeId: string, crustId: string) => {
      const activePizzaCrust = find(dish?.pizzaCrust, ({ id }) => id === crustId);

      const { intValue, shift } = find(activePizzaCrust?.prices, ({ size: { id } }) => id === sizeId)?.price || { intValue: 0, shift: 0 };

      return convertPrice(intValue, shift);
    },
    [dish]
  );

  /*
   * Check if half and half allowed for given size
   */
  const isHalfAndHalfAllowed = useCallback(
    (sizeId: string) => {
      return find(dish?.pizzaBasePrice, ({ size }) => size.id === sizeId)?.size.enableHalfHalf;
    },
    [dish]
  );

  /*
   * When set to half and half all customization moves to left half
   * whole part is reset
   */
  const setHalfAndHalf = () => {
    formik.setValues({
      ...values,
      halfAndHalf: true,
      leftPizza: cloneDeep(values.wholePizza),
      wholePizza: cloneDeep(formik.initialValues.wholePizza)
    });

    setActiveTab('leftPizza');
  };

  /*
   * When half and half is removed
   * if active tab is left then left customizations are moved to whole
   * if active tab is right then right customizations are moved to whole
   * left and right part is reset
   */
  const removeHalfAndHalf = useCallback(
    (event?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (activeTab === 'leftPizza') {
        const leftPizzaCopy = cloneDeep(values.leftPizza);

        formik.setValues({
          ...values,
          halfAndHalf: false,
          wholePizza: leftPizzaCopy,
          leftPizza: cloneDeep(formik.initialValues.leftPizza),
          rightPizza: cloneDeep(formik.initialValues.rightPizza)
        });
      }
      if (activeTab === 'rightPizza') {
        const rightPizzaCopy = cloneDeep(values.rightPizza);

        formik.setValues({
          ...values,
          halfAndHalf: false,
          wholePizza: rightPizzaCopy,
          leftPizza: cloneDeep(formik.initialValues.leftPizza),
          rightPizza: cloneDeep(formik.initialValues.rightPizza)
        });
      }
      setActiveTab('wholePizza');
    },
    [activeTab, formik, values]
  );

  /*
   * Check if selected crust is available for selected size
   * On size change if current state is half anf half but it is not allowed by new size
   * then remove half and half
   */
  useEffect(() => {
    const activePizzaBasePriceId = find(dish?.pizzaBasePrice, (l) => l.size.id === values.selectedSizeId)?.size.id || '';

    const activePizzaCrust = find(dish?.pizzaCrust, ({ id }) => id === values.selectedCrustId);

    if (filter(activePizzaCrust?.sizes, ({ id }) => id === activePizzaBasePriceId).length === 0) {
      setError(true);

      setErrormessage(map(find(dish?.pizzaCrust, ({ id }) => id === values.selectedCrustId)?.sizes, ({ title }) => title).join(' and '));
    } else {
      setError(false);

      setErrormessage('');
    }

    if (!isHalfAndHalfAllowed(values.selectedSizeId) && activeTab !== 'wholePizza') {
      removeHalfAndHalf();
    }
  }, [activeTab, dish, isHalfAndHalfAllowed, removeHalfAndHalf, values.selectedCrustId, values.selectedSizeId]);

  const onDishQuantityChange = (value: number) => {
    if (value > 0) {
      setTotalPrice((prev) => ({
        ...prev,
        quantity: value
      }));
    }
  };

  /*
   * calculate price
   */
  useEffect(() => {
    let sum = Big(0);
    if (values.halfAndHalf) {
      const sauces = groupBy([...(values.leftPizza.pizzaSauceGroup?.items || []), ...(values.rightPizza.pizzaSauceGroup?.items || [])], 'id');
      const cheeses = groupBy([...(values.leftPizza.pizzaCheeseGroup?.items || []), ...(values.rightPizza.pizzaCheeseGroup?.items || [])], 'id');

      let leftSauceCount = 0;
      let rightSauceCount = 0;
      let leftCheeseCount = 0;
      let rightCheeseCount = 0;

      for (const key in sauces) {
        let left = Big(0);
        let right = Big(0);
        const sauce = sauces[key];
        if (sauce[0]) {
          if (sauce[0].selectedDensity !== PizzaToppingDensity.None) {
            leftSauceCount += 1;
          }

          const { intValue, shift } = sauce[0].prices.find((l) => l.size.id === values.selectedSizeId)?.densityPrices.find((priceItem) => priceItem.density === sauce[0].selectedDensity)?.price || {
            intValue: 0,
            shift: 0
          };

          left = Big(convertPrice(intValue, shift));
        }
        if (sauce[1]) {
          if (sauce[1].selectedDensity !== PizzaToppingDensity.None) {
            rightSauceCount += 1;
          }

          const { intValue, shift } = sauce[1].prices.find((l) => l.size.id === values.selectedSizeId)?.densityPrices.find((priceItem) => priceItem.density === sauce[1].selectedDensity)?.price || {
            intValue: 0,
            shift: 0
          };
          right = Big(convertPrice(intValue, shift));
        }

        if (left.gt(right)) {
          sum = sum.add(left);
        } else {
          sum = sum.add(right);
        }
      }

      for (const key in cheeses) {
        let left = Big(0);
        let right = Big(0);
        const cheese = cheeses[key];

        if (cheese[0]) {
          if (cheese[0].selectedDensity !== PizzaToppingDensity.None) {
            leftCheeseCount += 1;
          }

          const { intValue, shift } = cheese[0].prices.find((l) => l.size.id === values.selectedSizeId)?.densityPrices.find((priceItem) => priceItem.density === cheese[0].selectedDensity)?.price || {
            intValue: 0,
            shift: 0
          };

          left = Big(convertPrice(intValue, shift));
        }
        if (cheese[1]) {
          if (cheese[1].selectedDensity !== PizzaToppingDensity.None) {
            rightCheeseCount += 1;
          }

          const { intValue, shift } = cheese[1].prices.find((l) => l.size.id === values.selectedSizeId)?.densityPrices.find((priceItem) => priceItem.density === cheese[1].selectedDensity)?.price || {
            intValue: 0,
            shift: 0
          };
          right = Big(convertPrice(intValue, shift));
        }

        if (left.gt(right)) {
          sum = sum.add(left);
        } else {
          sum = sum.add(right);
        }
      }

      const toppingGroups = groupBy([...(values.leftPizza.pizzaToppingsGroup || []), ...(values.rightPizza.pizzaToppingsGroup || [])], 'id');

      for (const groupKey in toppingGroups) {
        const toppingGroup = toppingGroups[groupKey];
        if (toppingGroup[0] && toppingGroup[0].items.length && toppingGroup[1] && toppingGroup[1].items.length) {
          const toppings = groupBy([...toppingGroup[0].items, ...toppingGroup[1].items], 'id');

          for (const key in toppings) {
            let left = Big(0);
            let right = Big(0);
            const topping = toppings[key];

            if (topping[0]) {
              const { intValue, shift } = topping[0].prices.find((l) => l.size.id === values.selectedSizeId)?.densityPrices.find((priceItem) => priceItem.density === topping[0].selectedDensity)
                ?.price || {
                intValue: 0,
                shift: 0
              };

              left = Big(convertPrice(intValue, shift));
            }
            if (topping[1]) {
              const { intValue, shift } = topping[1].prices.find((l) => l.size.id === values.selectedSizeId)?.densityPrices.find((priceItem) => priceItem.density === topping[1].selectedDensity)
                ?.price || {
                intValue: 0,
                shift: 0
              };
              right = Big(convertPrice(intValue, shift));
            }

            if (left.gt(right)) {
              sum = sum.add(left);
            } else {
              sum = sum.add(right);
            }
          }
        }
      }

      setLeftEmptySauce(!leftSauceCount && !isEmpty(sauces));
      setRightEmptySauce(!rightSauceCount && !isEmpty(sauces));
      setLeftEmptyCheese(!leftCheeseCount && !isEmpty(cheeses));
      setRightEmptyCheese(!rightCheeseCount && !isEmpty(cheeses));
    } else {
      values.wholePizza.pizzaToppingsGroup?.forEach(({ items }) => {
        items.forEach(({ prices, selectedDensity }) => {
          const { intValue, shift } = prices.find((l) => l.size.id === values.selectedSizeId)?.densityPrices.find((priceItem) => priceItem.density === selectedDensity)?.price || {
            intValue: 0,
            shift: 0
          };
          sum = sum.add(Big(convertPrice(intValue, shift)));
        });
      });

      let sauceCount = 0;
      values.wholePizza.pizzaSauceGroup?.items.forEach(({ prices, selectedDensity }) => {
        if (selectedDensity !== PizzaToppingDensity.None) {
          sauceCount += 1;
        }

        const { intValue, shift } = prices.find((l) => l.size.id === values.selectedSizeId)?.densityPrices.find((priceItem) => priceItem.density === selectedDensity)?.price || {
          intValue: 0,
          shift: 0
        };
        sum = sum.add(Big(convertPrice(intValue, shift)));
      });

      let cheeseCount = 0;
      values.wholePizza.pizzaCheeseGroup?.items.forEach(({ prices, selectedDensity }) => {
        if (selectedDensity !== PizzaToppingDensity.None) {
          cheeseCount += 1;
        }

        const { intValue, shift } = prices.find((l) => l.size.id === values.selectedSizeId)?.densityPrices.find((priceItem) => priceItem.density === selectedDensity)?.price || {
          intValue: 0,
          shift: 0
        };
        sum = sum.add(Big(convertPrice(intValue, shift)));
      });

      setEmptySauce(!sauceCount && !!values.wholePizza.pizzaSauceGroup?.items.length);
      setEmptyCheese(!cheeseCount && !!values.wholePizza.pizzaCheeseGroup?.items.length);
    }

    const basePrice = getPizzaBaseSizePrice(values.selectedSizeId).price;

    const basePriceStriked = getPizzaBaseSizePrice(values.selectedSizeId).priceStriked;

    const totalPriceAddingCrustAndBasePrice = sum.add(Big(basePrice)).add(Big(getPizzaCrustPrice(values.selectedSizeId, values.selectedCrustId)));

    const totalPriceStrikedAddingCrustAndBasePrice = sum.add(Big(basePriceStriked)).add(Big(getPizzaCrustPrice(values.selectedSizeId, values.selectedCrustId)));

    const tPrice = Big(subtotal.quantity).times(totalPriceAddingCrustAndBasePrice);

    const subtotalToDiscount = isDiscounted(values.selectedSizeId) ? sum.add(Big(getPizzaCrustPrice(values.selectedSizeId, values.selectedCrustId))) : totalPriceAddingCrustAndBasePrice;

    setTotalPrice((prev) => ({
      ...prev,
      subtotal: tPrice.toFixed(2),
      subtotalToDiscount: subtotalToDiscount.toFixed(2),
      totalPriceWithoutQuantity: totalPriceAddingCrustAndBasePrice.toFixed(2),
      totalPriceStrikedWithoutQuantity: totalPriceStrikedAddingCrustAndBasePrice.toFixed(2)
    }));
  }, [dish, values, subtotal.quantity, getPizzaBaseSizePrice, getPizzaCrustPrice, isDiscounted]);

  const handleSubmit = (formValues: IPizzaData) => {
    let toppingsOrderLineItems: OrderPizzaToppingInput[] = [];

    let saucesOrderLineItems: OrderPizzaToppingInput[] = [];

    let cheeseOrderLineItems: OrderPizzaToppingInput[] = [];

    if (formValues.halfAndHalf) {
      if (formValues.leftPizza && formValues.rightPizza) {
        const { pizzaSaucesOrderLineItems: leftSaucesLineItems, pizzaCheeseOrderLineItems: leftCheesesLineItems, pizzaToppingGroupsOrderLineItems: leftToppingsLineItems } = getOrderLineItems(
          formValues.leftPizza,
          PizzaToppingSide.Left,
          formValues.selectedSizeId
        );

        const { pizzaSaucesOrderLineItems: rightSaucesLineItems, pizzaCheeseOrderLineItems: rightCheesesLineItems, pizzaToppingGroupsOrderLineItems: rightToppingsLineItems } = getOrderLineItems(
          formValues.rightPizza,
          PizzaToppingSide.Right,
          formValues.selectedSizeId
        );

        const groupedSauces = groupBy([...leftSaucesLineItems, ...rightSaucesLineItems], 'toppingId');
        const groupedCheeses = groupBy([...leftCheesesLineItems, ...rightCheesesLineItems], 'toppingId');
        const groupedToppings = groupBy([...leftToppingsLineItems, ...rightToppingsLineItems], 'toppingId');

        for (const key in groupedSauces) {
          if (groupedSauces[key].length === 2) {
            if (groupedSauces[key][0].toppingDensity === groupedSauces[key][1].toppingDensity) {
              saucesOrderLineItems.push({ ...groupedSauces[key][0], side: PizzaToppingSide.Whole });
            } else {
              saucesOrderLineItems.push(groupedSauces[key][0]);
              saucesOrderLineItems.push(groupedSauces[key][1]);
            }
          } else {
            saucesOrderLineItems.push(groupedSauces[key][0]);
          }
        }
        for (const key in groupedCheeses) {
          if (groupedCheeses[key].length === 2) {
            if (groupedCheeses[key][0].toppingDensity === groupedCheeses[key][1].toppingDensity) {
              cheeseOrderLineItems.push({ ...groupedCheeses[key][0], side: PizzaToppingSide.Whole });
            } else {
              cheeseOrderLineItems.push(groupedCheeses[key][0]);
              cheeseOrderLineItems.push(groupedCheeses[key][1]);
            }
          } else {
            cheeseOrderLineItems.push(groupedCheeses[key][0]);
          }
        }

        for (const key in groupedToppings) {
          if (groupedToppings[key].length === 2) {
            if (groupedToppings[key][0].toppingDensity === groupedToppings[key][1].toppingDensity) {
              toppingsOrderLineItems.push({ ...groupedToppings[key][0], side: PizzaToppingSide.Whole });
            } else {
              toppingsOrderLineItems.push(groupedToppings[key][0]);
              toppingsOrderLineItems.push(groupedToppings[key][1]);
            }
          } else {
            toppingsOrderLineItems.push(groupedToppings[key][0]);
          }
        }
      }
    } else {
      if (formValues.wholePizza) {
        const { pizzaSaucesOrderLineItems, pizzaCheeseOrderLineItems, pizzaToppingGroupsOrderLineItems } = getOrderLineItems(formValues.wholePizza, PizzaToppingSide.Whole, formValues.selectedSizeId);
        saucesOrderLineItems.push(...pizzaSaucesOrderLineItems);
        cheeseOrderLineItems.push(...pizzaCheeseOrderLineItems);
        toppingsOrderLineItems.push(...pizzaToppingGroupsOrderLineItems);
      }
    }

    const cart = plainToClass(CartItem, {
      ...dish,
      quantity: subtotal.quantity,
      pizzaToppingGroupsOrderLineItems: toppingsOrderLineItems,
      pizzaCheeseOrderLineItems: cheeseOrderLineItems,
      pizzaSaucesOrderLineItems: saucesOrderLineItems,
      pizzaActiveSizeId: formValues.selectedSizeId,
      pizzaActiveCrustId: formValues.selectedCrustId,
      dishTypePizzaTotalPrice: subtotal.totalPriceWithoutQuantity,
      dishTypePizzaTotalPriceStriked: subtotal.totalPriceStrikedWithoutQuantity,
      dishTypePizzaPriceToDiscount: subtotal.subtotalToDiscount,
      note: dishNote,
      isPreDiscounted: dish?.isDiscounted && !!baseDiscountPercent ? true : false,
      discountPercent: !!baseDiscountPercent ? baseDiscountPercent : null
    });

    if (cartItems.length === 0 && cartRewardItems.length === 0) {
      dispatch({
        type: 'CART_ACTION_ADD',
        payload: cart
      });
    } else {
      const firstItem = cartItems[0] || cartRewardItems[0];

      const firstCartItemRestaurantId = firstItem?.restaurant?.id;

      const newItemRestaurantId = cart.restaurant?.id;

      if (!isEqual(firstCartItemRestaurantId, newItemRestaurantId)) {
        dispatch({
          type: 'CART_ACTION_CLEAR_ALL'
        });

        dispatch({
          type: 'SET_RESTAURANT_USED_POINTS',
          payload: 0
        });

        dispatch({
          type: 'CART_ACTION_ADD',
          payload: cart
        });
      } else {
        dispatch({
          type: 'CART_ACTION_ADD',
          payload: cart
        });
      }
    }

    handleClose();
  };

  const basePrice = getPizzaBaseSizePrice(values.selectedSizeId).price;

  const basePriceStriked = getPizzaBaseSizePrice(values.selectedSizeId).priceStriked;

  const baseDiscountPercent = getPizzaBaseSizePrice(values.selectedSizeId).discountPercent;

  return (
    <>
      <Box>
        <Box paddingX={2} display="flex">
          <Box flex={1}>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="bolder">
                ${subtotal.subtotal}
              </Box>
            </Typography>
          </Box>
          <Box>
            <Stepper onChange={onDishQuantityChange} value={subtotal.quantity} />
          </Box>
        </Box>
        <Box paddingX={2} display="flex" justifyItems="center" alignItems="center" marginY={1}>
          <Box flex={1}>
            <Typography variant="body1">Size</Typography>
          </Box>
          <Box flex={2}>
            <TextField value={values.selectedSizeId} name="selectedSizeId" select={true} size="small" variant="outlined" fullWidth={true} onChange={handleChange}>
              {map(dish?.pizzaBasePrice, ({ size }) => {
                return (
                  <MenuItem key={size.id} value={size.id}>
                    <Typography variant="body2">{size.title}</Typography>
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          <Box flex={2} textAlign="right">
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {!!baseDiscountPercent && (
                <Box className={classes.priceItem}>
                  <Typography className={classes.priceDiscount} variant="body1" align="right">
                    {baseDiscountPercent}%&nbsp;OFF
                  </Typography>
                </Box>
              )}
              <Box className={classes.priceItem}>
                {!!basePriceStriked && (
                  <Box>
                    <Typography className={classes.priceStriked} variant="body2">
                      ${basePriceStriked}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography variant="body1">${basePrice}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {error && (
          <Box paddingX={2}>
            <Typography variant="caption" color="error">
              This crust is only supported for {errorMessage}
            </Typography>
          </Box>
        )}
        <Box paddingX={2} display="flex" justifyItems="center" alignItems="center" marginY={1}>
          <Box flex={1}>
            <Typography variant="body1">Crust</Typography>
          </Box>
          <Box flex={2}>
            <TextField value={values.selectedCrustId} name="selectedCrustId" select={true} size="small" variant="outlined" fullWidth={true} onChange={handleChange}>
              {map(dish?.pizzaCrust, ({ id, title }) => {
                return (
                  <MenuItem key={id} value={id}>
                    <Typography variant="body2">{title}</Typography>
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          <Box flex={2} textAlign="right">
            {!['0.00', '0', '.0'].includes(getPizzaCrustPrice(values.selectedSizeId, values.selectedCrustId)) && (
              <Typography variant="body1">${getPizzaCrustPrice(values.selectedSizeId, values.selectedCrustId)}</Typography>
            )}
          </Box>
        </Box>
        <Box paddingX={2}>
          {activeTab === 'wholePizza' ? (
            <Button variant="contained" fullWidth={true} color="secondary" onClick={setHalfAndHalf} disabled={!isHalfAndHalfAllowed(values.selectedSizeId)}>
              Make half and half
            </Button>
          ) : (
            <Box display="flex" alignItems="center">
              <Box flex={1} display="flex" alignItems="center" className={activeTab === 'leftPizza' ? classes.selectedHalf : classes.notSelectedHalf} onClick={() => setActiveTab('leftPizza')}>
                {activeTab === 'leftPizza' && (
                  <Box display="flex" paddingRight={1}>
                    <CancelIcon fontSize="small" color="error" onClick={removeHalfAndHalf} />
                  </Box>
                )}
                <Box flex={1}>
                  <Typography variant="body1">Left half</Typography>
                </Box>
              </Box>
              <Box flex={1} display="flex" alignItems="center" className={activeTab === 'rightPizza' ? classes.selectedHalf : classes.notSelectedHalf} onClick={() => setActiveTab('rightPizza')}>
                {activeTab === 'rightPizza' && (
                  <Box display="flex" paddingRight={1}>
                    <CancelIcon fontSize="small" color="error" onClick={removeHalfAndHalf} />
                  </Box>
                )}
                <Box flex={1}>
                  <Typography variant="body1">Right half</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box flex={1} id="scrollerBox" overflow="scroll" className={classes.toppingsContainer}>
        {values[activeTab].pizzaSauceGroup && !!values[activeTab].pizzaSauceGroup?.items.length && (
          <Toppings
            groupTitle="Sauce"
            items={values[activeTab].pizzaSauceGroup?.items || []}
            selectedSize={values.selectedSizeId}
            handleChange={handleChange}
            itemName={`${activeTab}.pizzaSauceGroup.items`}
          />
        )}
        {values[activeTab].pizzaCheeseGroup && !!values[activeTab].pizzaCheeseGroup?.items.length && (
          <Toppings
            groupTitle="Cheese"
            items={values[activeTab].pizzaCheeseGroup?.items || []}
            selectedSize={values.selectedSizeId}
            handleChange={handleChange}
            itemName={`${activeTab}.pizzaCheeseGroup.items`}
          />
        )}
        {map(values[activeTab].pizzaToppingsGroup, (group, groupIndex) => {
          const { items, title } = group;

          return (
            <Toppings
              key={groupIndex}
              groupTitle={title}
              items={items}
              selectedSize={values.selectedSizeId}
              handleChange={handleChange}
              itemName={`${activeTab}.pizzaToppingsGroup[${groupIndex}].items`}
            />
          );
        })}
      </Box>
      {restaurant?.enableDishNote && displayDishNoteField && (
        <Box paddingX={2}>
          <TextField
            label="Add any special instructions ..."
            placeholder="Example, extra spicy, no spearmint etc."
            name="specialNote"
            value={dishNote ? dishNote : ''}
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setDishNote(e.target.value)}
            variant="outlined"
            rows={1}
            fullWidth={true}
            multiline={true}
          />
        </Box>
      )}
      <Box paddingX={2} marginTop={1}>
        <ButtonGroup fullWidth={true}>
          {restaurant?.enableDishNote && (
            <Button
              color="secondary"
              variant="contained"
              disableElevation={true}
              onClick={() => {
                setDisplayDishNoteField((oldVal) => !oldVal);
              }}
              endIcon={dishNote ? <AssignmentIcon /> : null}>
              Special note
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            disableElevation={true}
            disabled={!orderEnabled || error}
            onClick={() => {
              if (emptyCheese || emptySauce || leftEmptySauce || leftEmptyCheese || rightEmptySauce || rightEmptyCheese) {
                setOpenEmptyItemsWarningDialog(true);
              } else {
                formik.submitForm();
              }
            }}>
            Add to Cart
          </Button>
        </ButtonGroup>
      </Box>
      <Dialog open={openEmptyItemsWarningDialog}>
        <Box padding={2}>
          {values.halfAndHalf ? (
            <Box paddingY={0.5}>
              {leftEmptySauce && <Typography variant="body1">Left Half: No Sauce</Typography>}
              {rightEmptySauce && <Typography variant="body1">Right Half: No Sauce</Typography>}
              {leftEmptyCheese && <Typography variant="body1">Left Half: No Cheese</Typography>}
              {rightEmptyCheese && <Typography variant="body1">Right Half: No Cheese</Typography>}
            </Box>
          ) : (
            <Box paddingY={0.5}>
              {emptySauce && <Typography variant="body1">No Sauce selected</Typography>}
              {emptyCheese && <Typography variant="body1">No Cheese selected</Typography>}
            </Box>
          )}
          <Typography variant="body1">
            Not having either Sauce or Cheese may result in Pizza with not so desirable taste. We recommend you to press “CANCEL” and review these options again.However if you want to still proceed to
            ordering with these options press “OK”.
          </Typography>
        </Box>
        <Box paddingY={1} paddingBottom={2} display="flex" justifyContent="center" alignItems="center">
          <Box paddingX={1}>
            <Button variant="outlined" size="small" onClick={() => setOpenEmptyItemsWarningDialog(false)}>
              Cancel
            </Button>
          </Box>
          <Box paddingX={1}>
            <Button variant="contained" size="small" disableElevation={true} color="primary" onClick={() => formik.submitForm()}>
              OK
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DishTypePizza;
