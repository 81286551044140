import React, { useState, useContext } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useStore } from 'store';
import { createOrderInput } from '../createOrderInput';
import { OrderType, PaymentType } from 'generated/types';
import OrderProcessingScreen from '../OrderProcessingScreen';
import { useStyles } from './style';
import { AuthContext } from 'fbase/authContext';
import SecurePayments from 'assets/img/secure-payments.svg';
import ContactDetails from '../ContactDetails';
import CheckoutSummary from 'pages/Payment/CheckoutSummary';
import Big from 'big.js';
import Logger from 'util/logger';
import { useMutation } from '@apollo/react-hooks';
import { createOrderMutation } from 'graphql/mutations/order.mutation';
import { ApolloError } from 'apollo-client';
import { CreditCard } from '@material-ui/icons';
import RestaurantPause from 'components/RestaurantPause';
import { useRestaurantPause } from 'hooks/restaurant';
import { isFutureDate } from 'util/date';
import { useAlert } from 'hooks';
import { Redirect } from 'react-router-dom';
import { setLocalStorage } from 'util/storage';
import { isRestaurantClosed } from 'pages/MyCart/util';
import { ServiceType } from 'generated/types';
import { filter } from 'lodash';
import moment from 'moment';

const PayByStripeCheckout = () => {
  const classes = useStyles();

  const {
    state: { cartRestaurant, orderType, deliveryAddress, deliveryTime, numberOfPeople, tableNumber, boonsQuoteId, deliveryProvider, deliveryMode, bizPlan, couponData, cartSessionId }
  } = useStore();

  const { state } = useStore();
  const [loading, setLoading] = useState(false);

  const { lokobeeUser } = useContext(AuthContext);

  const stripeAccount = state.cartItems[0]?.restaurant?.hasStripeAccount;

  const [isSubmitBlocked, setSubmitBlocked] = useState(false);

  const { lokoAlert } = useAlert();
  const earnedCC = couponData ? { consumerReward: couponData?.consumerReward, cryptoUnit: couponData?.cryptoUnit } : null;

  const { deliveryPause, diningPause, takeOutPause } = useRestaurantPause({ restaurant: cartRestaurant });

  const [createOrder] = useMutation<any, any>(createOrderMutation, {
    onError: (err: ApolloError) => {
      if (err.graphQLErrors && err.graphQLErrors.length) {
        let errMsg = err.graphQLErrors[0].message || 'Failed to create order';

        let errMsgLower = errMsg.toLowerCase();

        if (errMsgLower.startsWith('incorrect total') || errMsgLower.startsWith('invalid input') || errMsgLower.startsWith('invalid sellerbizid') || errMsgLower.startsWith('incorrect redeempoints')) {
          errMsg = 'Failed to create order';
        }

        lokoAlert(errMsg);

        Logger.log('ORDER ERROR', {
          errorShownToUser: errMsg,
          error: err
        });
      } else {
        lokoAlert('Failed to create order');

        Logger.log('ORDER ERROR', {
          errorShownToUser: 'Failed to create order',
          error: err
        });
      }
    }
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    localStorage.setItem('isDialogClosed', 'true');
    if (!isSubmitBlocked) {
      setSubmitBlocked(true);

      const currentTime = moment();
      if (currentTime.isAfter(state.deliveryTime) && orderType === 'TAKE_OUT') {
        lokoAlert('Invalid pickup time!');
        return;
      }
      if (!lokobeeUser) {
        lokoAlert('Please login to continue.');
        return;
      }

      let email = null;

      if (state.user && state.user.email) {
        email = state.user.email;
      }

      if (!email) {
        lokoAlert('Email does not exists');
        return;
      }

      let orderPhoneNumber = null;

      if (state.user && state.user.orderPhoneNumber) {
        orderPhoneNumber = state.user.orderPhoneNumber;
      }

      if (!orderPhoneNumber) {
        lokoAlert('Phone number does not exists');
        return;
      }
      try {
        if ((state.cartItems && state.cartItems.length) || (state.cartRewardItems && state.cartRewardItems.length)) {
          const { usedPoints, tip, totalPriceAfterTax, deliveryTime, orderNote, deliveryAddress, deliveryNote, orderType, serviceFee, subtotal, discount, isDiscountApplied } = state;

          if (!totalPriceAfterTax) {
            lokoAlert('Total price cannot be null');
            return;
          }

          setLoading(true);

          let totalAmount: any = totalPriceAfterTax.toFixed(2);

          const subtotalAmount =
            isDiscountApplied && discount
              ? Big(subtotal)
                  .minus(discount)
                  .toFixed(2)
              : Big(subtotal).toFixed(2);

          if ((state.cartItems && state.cartItems.length) || (state.cartRewardItems && state.cartRewardItems.length)) {
            const restaurantId = state.cartRestaurant?.id;

            const googleReviewLink = state.cartRestaurant?.googleReviewLink;

            const issueRewardPoint = state.cartRestaurant?.issueRewardPoint;

            const { tableNumber, numberOfPeople } = state;

            let type = OrderType.Takeout;

            if (orderType === 'DINE_OUT') type = OrderType.DineOut;

            if (orderType === 'DELIVERY') type = OrderType.Delivery;

            const intParsedNumberOfPeople = typeof numberOfPeople === 'number' ? numberOfPeople : parseInt(numberOfPeople, 10);

            const lokobeeServiceFee = serviceFee ? serviceFee?.toFixed(2) : '0';

            const phoneOrder = state.phoneOrder;

            const bizHours = cartRestaurant?.getBizHours;

            const dineOutBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === ServiceType.RestaurantDineOut) : [];

            const takeoutBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === ServiceType.RestaurantPickUp) : [];

            const deliveryBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === ServiceType.RestaurantDelivery) : [];
            let restaurnatClosed = false;
            if (orderType === 'DINE_OUT') {
              restaurnatClosed = isRestaurantClosed(dineOutBizHours, orderType, deliveryProvider);
            }
            if (orderType === 'TAKE_OUT') {
              restaurnatClosed = isRestaurantClosed(takeoutBizHours, orderType, deliveryProvider);
            }
            if (orderType === 'DELIVERY') {
              restaurnatClosed = isRestaurantClosed(deliveryBizHours, orderType, deliveryProvider);
            }

            const input = createOrderInput({
              cartItems: state.cartItems,
              cartRewardItems: state.cartRewardItems,
              expectTime: deliveryTime,
              note: orderNote,
              tipAmount: tip.toFixed(2),
              totalAmount,
              type,
              paymentType: PaymentType.OnlineStripe,
              email,
              orderPhoneNumber,
              tableName: tableNumber,
              partySize: intParsedNumberOfPeople,
              deliveryAddress,
              deliveryNote,
              couponToken: state.couponToken,
              serviceFee: lokobeeServiceFee,
              redeemPoints: usedPoints,
              isPrediscountEnabled: state.isPrediscountEnabled,
              deliveryFee: state.deliveryCharge.gt(Big(0)) ? state.deliveryCharge.toFixed(2) : undefined,
              dropOffInstruction: state.dropOffType,
              apartmentNumber: state.apartmentNumber,
              boonsQuoteId,
              deliveryProvider,
              deliveryMode,
              doordashClassicQuoteId: state.doordashQuoteId,
              phoneOrder: phoneOrder,
              sessionId: cartSessionId
            });

            const response = await createOrder({
              variables: {
                input
              }
            });

            if (response && response.data && response.data.createOrder) {
              if (response.data.createOrder.redirectUrl) {
                setLocalStorage(
                  'orderState',
                  JSON.stringify({
                    show: true,
                    restaurantId,
                    subtotalAmount,
                    googleReviewLink,
                    issueRewardPoint,
                    isRestaurnatClosed: restaurnatClosed,
                    earnedCC,
                    orderType
                  })
                );

                setLocalStorage(
                  'cartState',
                  JSON.stringify({
                    cartItems: state.cartItems,
                    cartRewardItems: state.cartRewardItems,
                    deliveryProvider,
                    deliveryMode,
                    bizPlan,
                    timezone: cartRestaurant?.getTimezone
                  })
                );

                window.open(response.data.createOrder.redirectUrl, '_self');
              }
            } else {
              Logger.log('ORDER ERROR', {
                createOrderInput: input,
                createOrderResponse: response
              });
              throw new Error('');
            }
          } else {
            Logger.log('CART ERROR', state);
            throw new Error('Cart error');
          }
        } else {
          Logger.log('CART ERROR', state);
          throw new Error('Cart error');
        }
      } catch (e) {
        setSubmitBlocked(false);

        setLoading(false);

        if ((e as any).message !== '') {
          lokoAlert((e as any).message);
        }
      }
    }
  };

  if (state.cartItems.length === 0) {
    return <Redirect to="/" />;
  }

  const isPaused = (orderType === 'TAKE_OUT' && takeOutPause) || (orderType === 'DINE_OUT' && diningPause) || (orderType === 'DELIVERY' && deliveryPause);

  const futureOrder = (orderType === 'TAKE_OUT' || orderType === 'DELIVERY') && isFutureDate(deliveryTime);

  /**
   * @type {Boolean} - Boolean flag to determine whether add button should be disabled or not.
   */
  const isDisabled = isSubmitBlocked || (isPaused && !futureOrder);

  if (stripeAccount) {
    return (
      <>
        {loading && <OrderProcessingScreen />}
        <Box paddingX={1} flex={1} overflow="scroll" display={'flex'} flexDirection={'column'} justifyContent={'space-between'} className={classes.payment}>
          <Box>
            {cartRestaurant && <RestaurantPause restaurant={cartRestaurant} />}

            <Box paddingY={1}>
              <ContactDetails />
              <CheckoutSummary
                restaurantName={cartRestaurant?.getName}
                restaurantAddress={cartRestaurant?.getAddress}
                orderType={orderType}
                partySize={numberOfPeople}
                tableNumber={tableNumber}
                deliveryAddress={deliveryAddress}
                eta={deliveryTime}
              />
            </Box>
          </Box>
          <Box>
            <Box paddingY={2} display="flex" justifyContent="center">
              <a target="_blank" rel="noopener noreferrer" href="https://stripe.com/docs/security/stripe">
                <img src={SecurePayments} alt="Secure Payments" />
              </a>
            </Box>
            <Button type="submit" variant="contained" color="primary" fullWidth={true} startIcon={<CreditCard />} disabled={isDisabled} onClick={handleSubmit}>
              Checkout
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Box textAlign="center">
      <Typography variant="body1" color="error">
        Online Payment Not Available
      </Typography>
    </Box>
  );
};

export default PayByStripeCheckout;
