import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import WalletPages from './WalletPages';
import { Auth } from 'aws-amplify/';
import WalletLogin from './WalletLogin';
import { AuthContext } from 'fbase/authContext';
import { useStore } from 'store';
import { useStyles } from './style';
import { useSnackbar } from 'notistack';
import AskLokobeeUser from './AskLokobeeUser';
import { adChainCognitoConfig } from 'util/configureAmplifyCognito';

const Wallet = () => {
  const { dispatch } = useStore();
  const { currentUser, isAuthStateChanged } = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const snackbar = useSnackbar();
  const classes = useStyles();

  const setOpenDialog = useCallback(
    (openDialog: boolean) => {
      dispatch({
        type: 'UI_ACTION_WALLET_POPUP',
        payload: openDialog
      });
    },
    [dispatch]
  );
  useEffect(() => {
    const getAuth = async () => {
      try {
        adChainCognitoConfig();

        const currentSession = await Auth.currentSession();
        if (currentSession && currentSession.getIdToken().getJwtToken()) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (e) {}
    };
    if (!!!currentUser && isAuthStateChanged) {
      Auth.signOut(); // clear cognito session if there's not any lokobee session
    } else {
      getAuth();
    }
  }, [currentUser, dispatch, isAuthStateChanged, snackbar]);
  return (
    <Box height={'100%'}>
      {!!!currentUser && isAuthStateChanged ? (
        <Box className={classes.walletWrapper} maxWidth={500} width={'100%'} height="100%" margin={'0 auto'}>
          <AskLokobeeUser />
        </Box>
      ) : isLoggedIn && currentUser ? (
        <WalletPages />
      ) : (
        <Box className={classes.walletWrapper} maxWidth={500} width={'100%'} margin={'0 auto'} height="inherit">
          <WalletLogin setIsLoggedIn={setIsLoggedIn} />
        </Box>
      )}

      <Dialog open={false} fullWidth={false} maxWidth="md" onClose={() => setOpenDialog(false)}>
        <Box className={classes.walletHead} display="flex" flexDirection={'column'} flex={1} width={'100%'} padding={2}>
          <Typography variant="h5">
            Welcome to <br />
            <strong>CashBack Promotions</strong>
          </Typography>
        </Box>
        <DialogContent>
          <Box paddingY={2}>
            <Box marginBottom={2}>
              <Typography variant="subtitle1">
                We've partnered with{' '}
                <a href="https://biz.maxcashback.ai/" target="_blank" rel="noopener noreferrer">
                  maxCashback
                </a>{' '}
                to run CashBack Promotions on <strong>Lokobee!</strong>
                <br />
                You can earn CashBack for simply redeeming deals or ordering food on <strong>Lokobee!</strong>
                <br />
                <br />
                You can view your CashBack from this page
              </Typography>
            </Box>
            <Box>
              <Button onClick={() => setOpenDialog(false)} variant="contained" color="primary" size="small">
                Got it
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Wallet;
