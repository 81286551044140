import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import DialogHeader from 'components/DialogHeader';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../style';
import { FirebaseContext } from 'fbase';
import { AuthContext } from 'fbase/authContext';
import { useApolloClient } from '@apollo/react-hooks';
import LoginFormInner from './LoginFormInner';
import ForgotPassword from 'components/AccountModal/Login/ForgotPassword';

const AskLokobeeUser = () => {
  const classes = useStyles();
  const history = useHistory();

  const { firebase } = useContext(FirebaseContext);
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const auth = firebase!.getAuth();

  const client = useApolloClient();

  const guestLogin = async () => {
    try {
      setLoading(true);
      if (!currentUser) {
        setLoading(true);
        let persistence = firebase?.getAuth().Auth.Persistence.NONE || 'NONE';
        await auth().setPersistence(persistence);
        await auth().signInAnonymously();
        setLoading(false);
        client.resetStore();
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const openLogin = () => {
    setStep(1);
  };
  return (
    <Box className={classes.walletWrapper} height="100%" position={'relative'}>
      <DialogHeader text={'Wallet'} onBack={() => history.goBack()} />
      <Box>
        <Box display="flex" flexDirection={'column'} flex={1} paddingX={2} paddingY={4} paddingBottom={4}>
          <Typography variant="h6" align="center">
            Do you have a Lokobee account ?
          </Typography>
        </Box>
        <Box className={classes.walletInfo} paddingY={3}>
          <Box paddingX={2}>
            {step === 1 && <LoginFormInner setStep={setStep} />}
            {step === 2 && <ForgotPassword setStep={setStep} />}
          </Box>

          <Box padding={2} display="flex" justifyContent={'center'} flexDirection="column" width={'80%'} margin={'0 auto'} flex={1}>
            {step === 0 && (
              <Box marginBottom={2} display="flex" flexDirection="column" flex={1}>
                <Button variant="contained" color="primary" size="medium" onClick={openLogin}>
                  Yes
                </Button>
              </Box>
            )}
            <Button variant="contained" disabled={loading} style={{ whiteSpace: 'pre' }} color="secondary" size="medium" onClick={guestLogin}>
              Continue as a guest {loading && <CircularProgress size={18} />}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AskLokobeeUser;
