import { Box, Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useStyle } from './style';
import InfoIcon from '@material-ui/icons/Info';
import Moment from 'moment';
import { CryptoCoupon } from 'generated/types';
import Maybe from 'graphql/tsutils/Maybe';
import { convertToCCTokens } from 'util/number';
import DialogHeader from 'components/DialogHeader';
import Transition from 'components/Transition';
import { useHistory } from 'react-router-dom';

interface IProps {
  appliedCode?: string;
  couponData: CryptoCoupon;
  isApplicable?: boolean;
  isSelectionCard?: boolean;
}

const CryptoCouponCard: React.FC<IProps> = ({ couponData, isApplicable, appliedCode = '', isSelectionCard = false }) => {
  const classes = useStyle();
  const history = useHistory();
  const name = couponData?.name;
  const code = couponData?.code;
  const orderType = couponData?.orderType ? [...couponData?.orderType] : [];
  if (orderType.includes('DINE_IN') || orderType.includes('DINE_OUT')) {
    orderType.splice(orderType.indexOf('DINE_IN'), 1);
    orderType.splice(orderType.indexOf('DINE_OUT'), 1);
    orderType.push('DINE IN');
  }
  const description = couponData?.description;
  const endDate = couponData?.endDate;
  const consumerReward = couponData.consumerReward;
  const cryptoUnit = couponData.cryptoUnit;

  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Box className={isSelectionCard ? `${isApplicable ? classes.CryptoCouponCard : classes.disabledCard}` : classes.CryptoCouponCard} display="flex" flexDirection={'column'} alignItems="flex-start">
        <Box display={'flex'}>
          <Typography variant="h5" style={{ textDecoration: appliedCode === code ? 'underline' : 'none' }}>
            <strong>{code}</strong>
          </Typography>
        </Box>
        <Box display={'flex'} marginBottom={1}>
          <Typography variant="h6">{name}</Typography>
        </Box>
        <Box display={'flex'} marginBottom={1}>
          <Typography variant="button">{description}</Typography>
        </Box>
        <Box display={'flex'} flexWrap="wrap">
          {orderType.map((type: Maybe<string>, index: number) => {
            return (
              <Box marginBottom={1} key={index}>
                <Typography variant="caption" className={classes.borderedText}>
                  {type}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box display={'flex'} marginBottom={1}>
          {endDate && endDate !== '' && (
            <Typography variant="caption" className={classes.borderedText}>
              Valid till: {Moment(couponData.endDate).format('MMM/DD/YYYY')}
            </Typography>
          )}
        </Box>
        <Box display={'flex'} marginBottom={1}>
          <Typography variant="caption" className={isSelectionCard ? `${isApplicable ? classes.darkBorderedText : classes.disabledItemText}` : classes.darkBorderedText}>
            Earn: {convertToCCTokens(consumerReward, cryptoUnit)} {!isSelectionCard && <InfoIcon style={{ cursor: 'pointer' }} onClick={handleOpen} />}
          </Typography>
        </Box>
      </Box>

      <Dialog className={classes.dialog} open={isOpen} fullScreen={false} fullWidth={true} maxWidth="xs" TransitionComponent={Transition} onClose={handleClose}>
        <DialogHeader text="Info" onBack={handleClose} />
        <DialogContent className={classes.dialogContent}>
          <Box display={'flex'} padding={2} flexDirection="column" alignItems={'flex-start'}>
            <Typography variant="subtitle1">
              You will earn {convertToCCTokens(consumerReward, cryptoUnit)} (CashBack) for using this promotion!
              <br />
              <a href="https://biz.maxcashback.ai/cashbacktoken" style={{ color: '#000', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
                Learn about CashBack Promotions and see how you can get rich from CashBack
              </a>
            </Typography>
            <br />
            <br />
            <Typography variant={'body1'}>
              <strong>View your Tokens</strong>
            </Typography>

            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                history.push('/wallet');
                handleClose();
              }}
              size="small">
              Open Wallet
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CryptoCouponCard;
