import Big from 'big.js';
import { DeliveryProvider, IBizPlan } from 'generated/custom';
import { CouponDiscountType, CryptoCoupon, GroceryBizPlan, GroceryBizPlanModel, GroceryCoupon, GroceryDeliveryProviders, GroceryOrderType, GroceryStoreCartItem } from 'generated/types';
import { isEmpty, reduce } from 'lodash';
import GroceryStoreModel from 'models/GroceryStore';

const mapType = {
  TAKE_OUT: 'TAKEOUT',
  DELIVERY: 'DELIVERY',
  DINE_OUT: 'DINE_OUT'
};

type TOrderType = 'TAKEOUT' | 'DINE_OUT' | 'DELIVERY';

interface IArgs {
  sizePrice: number | string;
  quantity?: number;
  extrasPrice: Array<number | string>;
}

export const calculateExtraPrice = ({ extrasPrice, quantity = 1 }: { extrasPrice: Array<number | string>; quantity: number }) => {
  const extraTotalPrice = reduce(
    extrasPrice,
    (sum, value) => {
      return sum.add(Big(value));
    },
    Big(0)
  );

  return Big(extraTotalPrice)
    .times(quantity)
    .toFixed(2);
};

export const calculateTotalPrice = ({ sizePrice, extrasPrice, quantity = 1 }: IArgs): string => {
  const extraTotalPrice = reduce(
    extrasPrice,
    (sum, value) => {
      return sum.add(Big(value));
    },
    Big(0)
  );

  return Big(sizePrice)
    .add(extraTotalPrice)
    .times(quantity)
    .toFixed(2);
};

export const calculateDistanceCharge = ({
  fees,
  freeDeliveryAmount,
  deliveryMiles,
  subtotal
}: {
  fees: Array<{ fee: number; mile: number }>;
  freeDeliveryAmount: number;
  deliveryMiles: Big | null;
  subtotal: Big;
}) => {
  if (deliveryMiles) {
    let newDeliveryCharge = 0;

    const miles = parseFloat(deliveryMiles.valueOf());

    if (fees) {
      const len = fees.length;
      let chargeSet = false;

      for (let i = 0; i < len; i++) {
        if (miles <= fees[i].mile) {
          newDeliveryCharge = fees[i].fee;

          chargeSet = true;
          break;
        }
      }

      if (!chargeSet) {
        newDeliveryCharge = fees[len - 1].fee;
      }
    }

    if (freeDeliveryAmount && subtotal.gt(freeDeliveryAmount)) {
      return Big(0);
    } else {
      return Big(newDeliveryCharge);
    }
  }

  return Big(0);
};

interface IGetDiscountGrocery {
  couponData: Partial<GroceryCoupon> | null;
  subtotalBeforeDiscount: Big;
  subtotalToDiscount: Big;
}

export const getGroceryDiscountPriceAndSubtotalAfterDiscount = ({ couponData, subtotalBeforeDiscount, subtotalToDiscount }: IGetDiscountGrocery) => {
  let discountedPrice: Big | null = null;

  let subtotalAfterDiscount = subtotalBeforeDiscount;

  // If coupon data is preset add to subtotal
  if (couponData) {
    const { discountType, discountValue, subtotalThreshold, maxDiscount } = couponData;

    /**
     * If maxDiscount is undefined maxDiscountInDollar will be Big(0).
     * @type {Big} - Maximum discount price in dollars.
     */
    let maxDiscountInDollar = maxDiscount
      ? Big(maxDiscount)
          .div(100)
          .round(2)
      : Big(0);

    /**
     * If subtotal is greater than threshold value then only discount is applied.
     */
    if (
      subtotalThreshold &&
      Big(subtotalThreshold)
        .div(100)
        .gt(subtotalBeforeDiscount)
    ) {
      discountedPrice = null;
    } else {
      if (discountType === CouponDiscountType.Dollar) {
        if (discountValue) {
          discountedPrice = Big(discountValue)
            .div(100)
            .round(2);

          /**
           * If discount price is greater than subtotalToDiscount then discount amount will be
           * subtotalToDiscount
           */
          if (discountedPrice.gt(subtotalToDiscount)) {
            discountedPrice = subtotalToDiscount;
          }
        }
      }

      if (discountType === CouponDiscountType.Percent) {
        if (discountValue) {
          // discountedPrice = subtotalBeforeDiscount.mul(Big(discountValue).div(100)).round(2);
          discountedPrice = subtotalToDiscount.mul(Big(discountValue).div(100)).round(2);

          /**
           * If Discount price is greater than maximum discount then discount price will be maximum discount price.
           */
          if (maxDiscount && discountedPrice.gt(maxDiscountInDollar)) {
            discountedPrice = maxDiscountInDollar.round(2);
          }
        }
      }

      if (discountedPrice) {
        subtotalAfterDiscount = subtotalBeforeDiscount.minus(discountedPrice);
      }
    }
  }

  return {
    discountedPrice,
    subtotalAfterDiscount
  };
};

interface IGetDiscountRestaurant {
  couponData: Partial<CryptoCoupon> | null;
  subtotalBeforeDiscount: Big;
  subtotalToDiscount: Big;
}
export const getRestaurantDiscountPriceAndSubtotalAfterDiscount = ({ couponData, subtotalBeforeDiscount, subtotalToDiscount }: IGetDiscountRestaurant) => {
  let discountedPrice: Big | null = null;

  let subtotalAfterDiscount = subtotalBeforeDiscount;

  // If coupon data is preset add to subtotal
  if (couponData) {
    const { discountType, discountValue, minSpending, maxDiscount } = couponData;

    /**
     * If maxDiscount is undefined maxDiscountInDollar will be Big(0).
     * @type {Big} - Maximum discount price in dollars.
     */
    let maxDiscountInDollar = maxDiscount
      ? Big(maxDiscount)
          .div(100)
          .round(2)
      : Big(0);

    /**
     * If subtotal is greater than threshold value then only discount is applied.
     */
    if (
      minSpending &&
      Big(minSpending)
        .div(100)
        .gt(subtotalBeforeDiscount)
    ) {
      discountedPrice = null;
    } else {
      if (discountType === CouponDiscountType.Dollar) {
        if (discountValue) {
          discountedPrice = Big(discountValue)
            .div(100)
            .round(2);

          /**
           * If discount price is greater than subtotalToDiscount then discount amount will be
           * subtotalToDiscount
           */
          if (discountedPrice.gt(subtotalToDiscount)) {
            discountedPrice = subtotalToDiscount;
          }
        }
      }

      if (discountType === CouponDiscountType.Percent) {
        if (discountValue) {
          // discountedPrice = subtotalBeforeDiscount.mul(Big(discountValue).div(100)).round(2);
          discountedPrice = subtotalToDiscount.mul(Big(discountValue).div(100)).round(2);

          /**
           * If Discount price is greater than maximum discount then discount price will be maximum discount price.
           */
          if (maxDiscount && discountedPrice.gt(maxDiscountInDollar)) {
            discountedPrice = maxDiscountInDollar.round(2);
          }
        }
      }

      if (discountedPrice) {
        subtotalAfterDiscount = subtotalBeforeDiscount.minus(discountedPrice);
      }
    }
  }

  return {
    discountedPrice,
    subtotalAfterDiscount
  };
};

export const calculateTaxRate = ({
  taxRate,
  couponData,
  subtotal,
  discountPrice,
  orderType,
  deliveryCharge
}: {
  taxRate: string;
  couponData: Partial<CryptoCoupon> | null;
  subtotal: Big;
  discountPrice: Big | null;
  orderType: 'TAKE_OUT' | 'DINE_OUT' | 'DELIVERY' | 'DINING' | '';
  deliveryCharge: Big | null;
}) => {
  let tax1 = Big(0);

  // if (couponData) {
  //   if (discountPrice) {
  //     // Calculate tax rate. Here tax1 is tax on totaPrice.
  //     tax1 = Big(taxRate)
  //       .times(subtotal.add(discountPrice))
  //       .round(2, 3);
  //   }
  // } else {
  //   // Calculate tax rate. Here tax1 is tax on totaPrice.
  //   tax1 = Big(taxRate)
  //     .times(subtotal)
  //     .round(2, 3);
  // }
  tax1 = Big(taxRate)
    .times(subtotal)
    .round(2, 3);

  // Added delivery charge as tax also applies on delivery charge
  let totalAfterTax = Big(subtotal).add(tax1);

  if (orderType === 'DELIVERY' && deliveryCharge) {
    totalAfterTax = totalAfterTax.add(deliveryCharge);
  }

  // Set total price after adding tax to subtotal.
  // Note - subtotal is subtotal price calculated after adding all dish prices.
  // totalPriceAfterTax represents calculated price after adding tax in subtotal value.
  return {
    tax1,
    totalAfterTax
  };
};

interface ICalculateLokobeeFeeArgs {
  subtotal: Big;
  lokobeeFeePercent: number;
  lokobeeFeeLimit: number;
  lokobeeFee: boolean | undefined;
}

// Calculate service fee
export const calculateLokobeeFee = ({ subtotal, lokobeeFeePercent, lokobeeFeeLimit, lokobeeFee }: ICalculateLokobeeFeeArgs): Big => {
  if (lokobeeFee) {
    const subtotalInCent = Number.parseFloat(subtotal.toFixed(2)) * 100;

    if (subtotalInCent < 1000) {
      return Big(25 / 100);
    }

    let lokobeeFeeInCents = Math.trunc(subtotalInCent * (lokobeeFeePercent / 100));

    let fee = Number.parseFloat(
      Big(lokobeeFeeInCents)
        .round(-1, 3)
        .minus(1)
        .toString()
    );

    if (fee > lokobeeFeeLimit) {
      fee = lokobeeFeeLimit;
    }

    return Big(fee / 100);
  }

  return Big(0);
};

interface ICalculateServiceFee {
  bizPlan: IBizPlan;
  orderType: '' | 'TAKE_OUT' | 'DINE_OUT' | 'DELIVERY';
  deliveryProvider: DeliveryProvider;
  subtotal: Big;
}

export const calculateServiceFee = ({ bizPlan, orderType, deliveryProvider, subtotal }: ICalculateServiceFee) => {
  if (orderType && !isEmpty(bizPlan)) {
    const { bizPlanDetails } = bizPlan;

    const type: TOrderType = mapType[orderType] as TOrderType;

    const { DELIVERY, DINE_OUT, TAKEOUT } = bizPlanDetails;

    let serviceFeeObject = {
      bizAbsVal: 0,
      bizPctVal: 0,
      bizPctValLower: 0,
      custAbsVal: 0,
      custPctVal: 0,
      custPctValLower: 0
    };

    if (type === 'TAKEOUT') {
      serviceFeeObject = { ...TAKEOUT };
    } else if (type === 'DINE_OUT') {
      serviceFeeObject = { ...DINE_OUT };
    } else if (type === 'DELIVERY') {
      const { DOORDASH_CLASSIC, SELF } = DELIVERY;
      if (deliveryProvider === 'DOORDASH_CLASSIC') {
        serviceFeeObject = { ...DOORDASH_CLASSIC };
      } else if (deliveryProvider === 'SELF') {
        serviceFeeObject = { ...SELF };
      }
    }

    let absFee = serviceFeeObject.custAbsVal;
    let pctFee = 0;

    if (serviceFeeObject.custPctVal) {
      const subtotalInCent = Number.parseFloat(subtotal.toFixed(2)) * 100;

      let serviceChargeCents = Math.trunc(subtotalInCent * (serviceFeeObject.custPctVal / 100));

      pctFee = Number.parseFloat(
        Big(serviceChargeCents)
          .round(-1, 3)
          .minus(1)
          .toString()
      ); //Rounding up nearest 10 cents minus 1 cent

      if (pctFee < serviceFeeObject.custPctValLower) {
        pctFee = serviceFeeObject.custPctValLower;
      }
    }

    return Big((absFee + pctFee) / 100);
  }

  return Big(0);
};

// Calculate reward points
export const calculateRewardPoints = (totalPrice: string) => {
  return Big(totalPrice)
    .round()
    .toString();
};

interface ICalculateSubTotalAndTaxForGrocery {
  store: GroceryStoreModel | null;
  items: GroceryStoreCartItem[];
}
export const calculateSubTotalAndTaxForGrocery = ({ store, items }: ICalculateSubTotalAndTaxForGrocery): { subtotal: Big; totalTax: Big } => {
  let subtotal = Big(0);

  let totalTax = Big(0);

  const taxModes = store?.getTaxModes || {};

  items.forEach((item) => {
    if (item.item) {
      const {
        item: { price = 0, taxMode },
        quantity = 1
      } = item;

      subtotal = subtotal.add(Big(price * quantity));

      const tax = taxMode && taxModes[taxMode] ? taxModes[taxMode] : 0;

      totalTax = totalTax.add(Big((price * quantity * tax).toFixed(2)));
    }
  });

  subtotal = subtotal.round();
  totalTax = totalTax.round();

  return {
    subtotal,
    totalTax
  };
};

interface ICalculateGroceryServiceFee {
  bizPlan: GroceryBizPlan;
  orderType: GroceryOrderType | null;
  deliveryProvider: GroceryDeliveryProviders | null | undefined;
  subtotal: Big;
}

export const calculateGroceryServiceFee = ({ bizPlan, orderType, deliveryProvider, subtotal }: ICalculateGroceryServiceFee) => {
  if (orderType && !isEmpty(bizPlan)) {
    const { bizPlanDetails } = bizPlan;

    const { DELIVERY, TAKEOUT } = bizPlanDetails;

    let serviceFeeObject: GroceryBizPlanModel = {
      bizAbsVal: 0,
      bizPctVal: 0,
      bizPctValLower: 0,
      custAbsVal: 0,
      custPctVal: 0,
      custPctValLower: 0
    };

    if (orderType === GroceryOrderType.Takeout) {
      if (TAKEOUT) {
        serviceFeeObject = { ...TAKEOUT };
      }
    } else if (orderType === GroceryOrderType.Delivery) {
      if (DELIVERY) {
        const { DOORDASH_CLASSIC, SELF } = DELIVERY;
        if (deliveryProvider === 'DOORDASH_CLASSIC') {
          if (DOORDASH_CLASSIC) {
            serviceFeeObject = { ...DOORDASH_CLASSIC };
          }
        } else if (deliveryProvider === 'SELF') {
          if (SELF) {
            serviceFeeObject = { ...SELF };
          }
        } else if (!deliveryProvider) {
          if (SELF) {
            serviceFeeObject = { ...SELF };
          }
        }
      }
    }

    let absFee = serviceFeeObject.custAbsVal;
    let pctFee = 0;

    if (serviceFeeObject.custPctVal) {
      const subtotalVal = Number.parseFloat(subtotal.toFixed(2));

      let serviceChargeCents = Math.trunc(subtotalVal * (serviceFeeObject.custPctVal / 100));

      pctFee = Number.parseFloat(
        Big(serviceChargeCents)
          .round(-1, 3)
          .minus(1)
          .toString()
      ); //Rounding up nearest 10 cents minus 1 cent

      if (pctFee < serviceFeeObject.custPctValLower) {
        pctFee = serviceFeeObject.custPctValLower;
      }
    }

    return Big(absFee + pctFee);
  }

  return Big(0);
};
